import { Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { Observable, ReplaySubject, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-warranty-claims-card',
  templateUrl: './warranty-claims-card.component.html',
})
export class WarrantyClaimsCardComponent {
  @PropertyListener('warranty') warranty$: Observable<Warranty.Model> = new ReplaySubject();
  @Input() warranty?: Warranty.Model;

  canOpenClaim$ = this.warranty$.pipe(
    switchMap(
      async warranty =>
        warranty.status === Warranty.Status.ACTIVATED && warranty.policy?.customerPortalConfig.selfServeClaims
    )
  );

  claimsPlaceholder$ = this.warranty$.pipe(
    map(warranty => {
      if (warranty.policy?.customerPortalConfig.claimsPlaceholder) {
        return warranty.policy.customerPortalConfig.claimsPlaceholder
          .replace(/{{customerName}}/g, warranty.customer?.name || '')
          .replace(/{{customerEmail}}/g, warranty.customer?.email || '')
          .replace(/{{customerPhone}}/g, warranty.customer?.phone || '')
          .replace(/{{warrantyContract}}/g, warranty.contractNumber || '');
      } else {
        return '';
      }
    })
  );

  period = '';
  remainingPeriod = 0;
  isPastWaitingPeriod$ = this.warranty$.pipe(
    map(warranty => {
      const waitingPeriodEndDate = moment(warranty?.activationDate)
        .add(warranty?.policy?.claimWaitingPeriod, 'days')
        .toDate();
      if (moment().isBefore(waitingPeriodEndDate)) {
        if (moment(waitingPeriodEndDate).diff(new Date(), 'hours') > 24) {
          this.remainingPeriod = moment(waitingPeriodEndDate).diff(new Date(), 'days');
          this.period = 'days';
        } else {
          this.remainingPeriod = moment(waitingPeriodEndDate).diff(new Date(), 'hours');
          this.period = 'hours';
        }
        return false;
      } else {
        return true;
      }
    })
  );
}
