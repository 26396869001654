<vs-generic-modal
  [showCloseButton]="true"
  [config]="{ title: 'Add Banking Information', subtitle: 'Provide your bank account information to us to transfer you the funds', size: 'md' }"
>
  <ng-container *vsLet="fundingSources$ | async as fundingSources">
    <ng-container *ngIf="state === 'manage'">
      <div *ngIf="fundingSources" class="m-4 max-w-lg">
        <vs-manage-bank-details
          (closeDialog)="close()"
          (bankDetails)="confirmBankDetails($event); close();"
          (changeState)="changeState($event)"
          [fundingSources]="fundingSources"
          [addDirective]="false"
        ></vs-manage-bank-details>
      </div>
    </ng-container>
    <ng-container *ngIf="state === 'add'">
      <ng-container *ngIf="customer">
        <ng-container *vsLet="customerId$ | async as customerId">
          <vs-add-bank-details
            [customer]="customer"
            [customerId]="customerId?.id ?? null"
            [isCustomerRequest]="true"
            (bankDetails)="confirmBankDetails($event); close();"
            (closeDialog)="fundingSources ? changeState('manage') : close()"
          ></vs-add-bank-details>
          <br />
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div *ngIf="state==='add'" class="border-t py-2 px-5 text-center">
    <p class="text-xs text-gray-400">
      Your banking details are securely collected by our financial partner and never stored in our systems.
    </p>
  </div>
</vs-generic-modal>
