import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';

@Component({
  selector: 'ws-portal-info-card',
  templateUrl: './portal-info-card.component.html',
})
export class PortalInfoCardComponent {
  constructor(public portalSvc: PortalService, private route: ActivatedRoute, private router: Router) {}

  contractNumber$ = new BehaviorSubject<string | null>(null);

  changeDetection$ = this.router.events.pipe(
    tap(() => {
      this.contractNumber$.next(this.route.snapshot.children[0].paramMap.get('contractNumber'));
    })
  );

  backLink$: Observable<string | null> = combineLatest([this.changeDetection$, this.contractNumber$]).pipe(
    map(([_, contractNumber]) => {
      return contractNumber ? `/warranty/${contractNumber}` : `/warranty`;
    })
  );
}
