import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { StepperStepConfig } from '@vsolv/vectors-ui/stepper';
import { CustomersCustomerPortalService, WarrantyCustomerPortalService } from '@wsphere/customer-portal/web';
import { Customer } from '@wsphere/customers/domain';
import { Provisioning } from '@wsphere/warranties/domain';
import { CheckoutSteps, InitialData, OrderSummaryComponent, ProvisioningSessionService } from '@wsphere/warranties/web';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  templateUrl: './provision.page.html',
})
export class ProvisionPage implements OnInit, OnDestroy {
  @ViewChild(OrderSummaryComponent) summary?: OrderSummaryComponent;
  @ViewChild(DialogComponent) dialog?: DialogComponent;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionSvc: ProvisioningSessionService,
    private toastSvc: ToastService,
    private breakpointObserver: BreakpointObserver,
    private customerSvc: CustomersCustomerPortalService,
    private firebaseSvc: FirebaseService,
    private warrantySvc: WarrantyCustomerPortalService
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe(() => this.setPage());
  }

  protected customer?: Customer.Model;
  @PropertyListener('customer') protected readonly customer$ = new BehaviorSubject<Customer.Model | undefined>(
    this.customer
  );

  queryParams!: ParamMap;
  @PropertyListener('queryParams') queryParams$ = new BehaviorSubject<ParamMap | undefined>(this.queryParams);

  protected emailControl = new FormControl('', [Validators.required, Validators.email]);

  protected session$ = this.sessionSvc.getCurrentSession();
  protected addons$ = this.sessionSvc.addons$.asObservable();
  protected sessionComplete$ = this.sessionSvc.sessionComplete$.asObservable();
  protected close$ = this.sessionSvc.close$.asObservable().pipe(tap(() => this.dialog?.close()));

  protected step$ = new BehaviorSubject<CheckoutSteps>(CheckoutSteps.Customer);

  private steps: StepperStepConfig[] = [
    {
      title: 'My Email',
      allowJumpToStep: false,
      hideStep: true,
    },
    {
      title: 'Personal Info',
      allowJumpToStep: true,
      routerLink: '',
    },
    {
      title: 'Policy',
      routerLink: 'policy',
    },
    {
      title: 'Asset',
      routerLink: 'asset',
    },
    {
      title: 'Plan',
      routerLink: 'plan',
    },
    {
      title: 'Addon',
      hideStep: true,
      routerLink: 'addon',
    },
    {
      title: 'Checkout',
      routerLink: 'checkout',
    },
  ];

  protected steps$: Observable<StepperStepConfig[]> = combineLatest([this.step$, this.session$, this.addons$]).pipe(
    map(([step, session, addons]) => {
      if (![CheckoutSteps.Distributor, CheckoutSteps.Customer].includes(step))
        this.steps[step - 1].allowJumpToStep = true;
      if (step === CheckoutSteps.Distributor) {
        this.steps = this.steps.map((step, index) =>
          ![CheckoutSteps.Distributor, CheckoutSteps.Customer].includes(index + 1)
            ? {
                ...step,
                allowJumpToStep: false,
              }
            : step
        );
      }
      const hasAddons = !!addons?.find(addon => addon.planId === session?.planId)?.addons.length;
      this.steps[CheckoutSteps.Addon - 1].hideStep = !hasAddons;
      return this.steps;
    })
  );

  protected checkoutSteps = CheckoutSteps;

  protected loadingPreview$ = new BehaviorSubject<boolean>(false);

  protected preview$ = this.session$.pipe(
    switchMap(async session => {
      if (!session || !session.plan) {
        if (session && session.plan && this.step$.value > CheckoutSteps.Checkout) {
          this.step$.next(CheckoutSteps.Checkout);
          this.sessionSvc.refreshSession(session);
        } else return null;
      }
      this.loadingPreview$.next(true);
      return await this.sessionSvc.preview(session.id, session.hash);
    }),
    tap(preview => this.sessionSvc.preview$.next(preview)),
    tap(() => this.loadingPreview$.next(false))
  );

  protected coverages$ = this.preview$.pipe(
    map(preview => (preview ? preview.warranty.coverages.filter(coverage => coverage.requirement === 'BASE') : null))
  );

  protected selectedAddons$ = this.preview$.pipe(map(preview => (preview ? preview.addons : null)));

  data: InitialData = {};

  protected isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  protected async navigateTo(path?: string) {
    if (!path) {
      const warranties = (await this.warrantySvc.list({ page: 1, limit: 1 }))?.items;
      if (!warranties.length) {
        await this.firebaseSvc.signOut();
      }
    }
    this.router.navigate([path ?? `..`], { relativeTo: this.route });
  }

  protected async updatePreview(preview: { sessionId: string; data: Provisioning.UpdateProvisioningSessionDto }) {
    try {
      const session = await this.sessionSvc.update(preview.sessionId, preview.data);
      this.sessionSvc.refreshSession(session);
    } catch (err: unknown) {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong!',
      });
      console.error(err);
      throw new Error();
    }
  }

  async redirectIfExists(email: string) {
    if (!this.customer) {
      const exists = await this.customerSvc.checkIfCustomerExists(email);
      if (exists) {
        let redirect = 'checkout';
        const keys = this.queryParams.keys;
        for (let index = 0; index < keys.length; index++) {
          if (index === 0) redirect += '?';
          const key = keys[index];
          const params = this.queryParams.getAll(key).join(`&${key}=`);
          redirect += `${key}=${params}`;
          if (index < keys.length - 1) redirect += '&';
        }
        await this.router.navigate(['login'], { queryParams: { email, autosend: true, redirect } });
        return true;
      }
    }
    return false;
  }

  async ngOnInit() {
    this.customer = await this.customerSvc.retrieveSelf();

    this.queryParams = await firstValueFrom(this.route.queryParamMap);
    const email = this.queryParams.get('email');

    const distributorId = this.queryParams.get('distributorId');
    const distributorReferenceId = this.queryParams.get('distributorReferenceId');
    const distributorData = distributorReferenceId
      ? { referenceId: distributorReferenceId, parentId: this.queryParams.get('distributorParentId') ?? undefined }
      : undefined;

    const viewablePlanIds = this.queryParams.getAll('planId');
    let viewablePlansAndTerms: Provisioning.ViewablePlansAndTerms | undefined = undefined;

    if (viewablePlanIds.length) {
      viewablePlansAndTerms = {};
      for (const planId of viewablePlanIds) {
        const termIds = this.queryParams.getAll(planId);
        viewablePlansAndTerms[planId] = termIds.length ? termIds : null;
      }
    }

    const expiryDateParam = this.queryParams.get('expires');
    const expiryDate = expiryDateParam ? new Date(expiryDateParam) : undefined;

    if (this.customer && email && this.customer.email !== email) {
      this.customer = undefined;
      await this.firebaseSvc.signOut();
    }

    this.emailControl.setValue(this.customer?.email || email);

    if (!this.customer && email) {
      const exists = await this.redirectIfExists(email);
      if (exists) return;
    }

    if (this.customer?.email || email) {
      //retrieve session from local storage
      let localSession = await this.getLocalStorageSession((this.customer?.email || email) as string);

      if (localSession && viewablePlanIds.length) {
        //our url contains plan ids, compare the sessions plan filters with received plan ids.

        if (
          !localSession.viewablePlansAndTerms ||
          Object.keys(localSession.viewablePlansAndTerms).length !== viewablePlanIds.length
        ) {
          //lengths do not match, we must create a new session
          localSession = undefined;
        } else {
          //lengths match, compare the values to make sure the array is the same (may be out of order)
          const uniquePlanIds = new Set([...Object.keys(localSession.viewablePlansAndTerms), ...viewablePlanIds]);
          for (const planId of uniquePlanIds) {
            const aCount = Object.keys(localSession.viewablePlansAndTerms).filter(e => e === planId).length;
            const bCount = viewablePlanIds.filter(e => e === planId).length;
            if (aCount !== bCount) {
              //array is not the same.
              localSession = undefined;
              break;
            }

            const queryTermsLength = viewablePlansAndTerms ? viewablePlansAndTerms[planId]?.length ?? 0 : 0;
            const sessionTermsLength = localSession.viewablePlansAndTerms
              ? localSession.viewablePlansAndTerms[planId]?.length ?? 0
              : 0;
            if (queryTermsLength !== sessionTermsLength) {
              //array is not the same.
              localSession = undefined;
              break;
            }
          }
        }
      }

      this.sessionSvc.refreshSession(localSession);
    }

    const data: InitialData = {
      customer: this.customer,
      customerInfo: { name: this.customer?.name, email: this.customer?.email || email, phone: this.customer?.phone },
      distributor: distributorData,
      distributorId,
      viewablePlansAndTerms,
      expiryDate,
    };

    this.sessionSvc.data$.next(data);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async getLocalStorageSession(_email: string): Promise<Provisioning.ProvisioningSession | undefined> {
    return undefined;
    // FIXME: Provisioning session local storage
    // const sessionId = localStorage.getItem('provisioning-session');
    // if (sessionId) {
    //   try {
    //     const session = (await this.sessionSvc.get(sessionId)) || undefined;
    //     if (session?.customer?.email !== email) {
    //       localStorage.removeItem('provisioning-session');
    //       return undefined;
    //     }
    //     return session;
    //   } catch (err) {
    //     localStorage.removeItem('provisioning-session');
    //   }
    // }
    // return undefined;
  }

  private setPage() {
    switch (this.route.snapshot.children?.[0].routeConfig?.path) {
      case '':
        this.step$.next(CheckoutSteps.Customer);
        break;
      case 'policy':
        this.step$.next(CheckoutSteps.Policy);
        break;
      case 'asset':
        this.step$.next(CheckoutSteps.Asset);
        break;
      case 'plan':
        this.step$.next(CheckoutSteps.Plan);
        break;
      case 'addon':
        this.step$.next(CheckoutSteps.Addon);
        break;
      case 'checkout':
        this.step$.next(CheckoutSteps.Checkout);
        break;
      default:
        this.step$.next(CheckoutSteps.Distributor);
    }
  }
}
