import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    const phoneRegex = /^(\d{3})(\d{3})(\d{4})$/;

    const cleaned = phoneNumber.replace(/\D/g, '');

    const formatted = cleaned.replace(phoneRegex, '($1) $2-$3');

    return formatted;
  }
}
