import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { PaymentsCustomerPortalService } from '@wsphere/customer-portal/web';
import { PaymentResolver } from '../resolvers';

@Injectable()
export class PaymentGuard implements CanActivate {
  constructor(private resolver: PaymentResolver, private paymentSvc: PaymentsCustomerPortalService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.payment = null;

    const paymentId = this.findPaymentId(route);
    if (!paymentId) return false;

    this.resolver.payment = await this.paymentSvc.getOne(paymentId);
    if (!this.resolver.payment) return false;

    return true;
  }

  private findPaymentId(route: ActivatedRouteSnapshot): string {
    return route.params['paymentId'] || (route.parent ? this.findPaymentId(route.parent) : null);
  }
}
