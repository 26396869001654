import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TenantInfoService, TenantService } from '@vsolv/core/multi-tenant/web';
import { UserService } from '@vsolv/core/users/web';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { Config } from '@vsolv/packages/portal-config/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { PaymentsCustomerPortalService } from '@wsphere/customer-portal/web';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService, WarrantyWebService } from '@wsphere/warranties/web';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({ templateUrl: './layout.component.html' })
export class CustomerPortalLayout implements OnDestroy, OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private titleSvc: Title,
    private route: ActivatedRoute,
    public portalSvc: PortalService,
    private tenantSvc: TenantService,
    private tenantInfoSvc: TenantInfoService,
    private firebaseSvc: FirebaseService,
    private paymentSvc: PaymentsCustomerPortalService,
    private policySvc: PolicyService,
    private warrantySvc: WarrantyWebService,
    private userSvc: UserService
  ) {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe(e => {
        this.drawerOpen = (e as NavigationEnd).url.split('/').length === 2;
      });
  }

  drawerOpen = true;

  readonly config$ = this.route.data.pipe(map(data => data['config'] as Config.PortalConfig));

  isSignedIn$ = this.firebaseSvc.getAuthId$().pipe(
    distinctUntilChanged(),
    map(id => !!id)
  );

  hasPayments$ = this.isSignedIn$.pipe(
    switchMap(async isSignedIn => {
      if (!isSignedIn) return false;

      const payments = (await this.paymentSvc.getList({ limit: 1 })).items;

      return !!payments.length;
    })
  );

  policiesAvailable$ = this.isSignedIn$.pipe(
    switchMap(async isSignedIn => {
      if (!isSignedIn) return false;

      const policies = (await this.policySvc.listForSales({ limit: 100 }, undefined, Policy.Status.PUBLISHED)).items;
      return !!policies.filter(
        policy =>
          policy.terms.some(term => term.paymentSchedules.length > 0) && policy.plans?.some(plan => plan.visible)
      ).length;
    })
  );

  hasWarranties$ = this.firebaseSvc.authUser$.pipe(
    switchMap(async isSignedIn => {
      if (isSignedIn) {
        const user = await this.userSvc.getSelf();
        if (user && user.email) {
          return (await this.warrantySvc.getOneByCustomerEmail(user.email)) ? true : false;
        } else return false;
      } else return false;
    })
  );

  private onDestroy$ = new Subject<void>();

  open = false;

  tenantId: string | null = null;

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  ngAfterViewInit() {
    this.config$.pipe(takeUntil(this.onDestroy$)).subscribe(config => {
      this.titleSvc.setTitle(config.brandName + ' Portal');

      const favicon = document.querySelector<HTMLLinkElement>('#favicon');
      if (favicon && config.avatar) favicon.href = config.avatar;
    });
  }

  async ngOnInit() {
    this.tenantId = this.tenantSvc.tenantId || this.tenantInfoSvc.getLocalTenantId();
  }

  async signedOut() {
    await this.router.navigate(['login']);
  }

  checkLink(link: string) {
    return this.router.url.indexOf(link) > 0;
  }
}
