import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Warranty } from '@wsphere/warranties/domain';
import { filter, map } from 'rxjs/operators';

@Component({ templateUrl: './warranty.page.html' })
export class WarrantyPage {
  constructor(private route: ActivatedRoute) {}

  readonly warranty$ = this.route.data.pipe(
    map(data => data['warranty'] as Warranty.Model),
    filter(w => !!w)
  );
}
