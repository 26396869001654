<div class="flex-grow px-4 py-3 lg:px-14 lg:py-10 bg-gray-50 overflow-auto max-h-screen">
  <div class="mb-6">
    <h1 class="mr-2 inline-block text-3xl text-base-dark font-bold">Terms of Use</h1>
    <p>Last Updated › Mar 25th, 2024</p>
  </div>

  <div>
    <ol class="ml-6 list-decimal font-bold [&>li]:mb-8">
      <li>
        <h2 class="uppercase mb-2">Acceptance of Terms</h2>
        <div class="font-normal">
          <p class="mb-2">
            The services that VectorSolv LLC (“VectorSolv” or “We”) provides to User is subject to the following Terms
            of Use ("TOU"). VectorSolv reserves the right to update the TOU at any time without notice to User. The most
            current version of the TOU can be reviewed by clicking on the "Terms of Use" hypertext link located on our
            Web pages.
          </p>

          <ol class="ml-6 list-['A.__']">
            <li class="mb-2">
              This Agreement, which incorporates by reference other provisions applicable to use of
              https://www.vectorsolv.com, including, but not limited to, supplemental terms and conditions set forth
              hereof ("Supplemental Terms") governing the use of certain specific material contained in
              https://www.vectorsolv.com, sets forth the terms and conditions that apply to use of
              https://www.vectorsolv.com by User. By using VectorSolv (other than to read this Agreement for the first
              time), User agrees to comply with all of the terms and conditions hereof. The right to use
              https://www.vectorsolv.com is personal to User and is not transferable to any other person or entity. User
              is responsible for all use of User's Account (under any screen name or password) and for ensuring that all
              use of User's Account complies fully with the provisions of this Agreement. User shall be responsible for
              protecting the confidentiality of User's password(s), if any.
            </li>

            <li class="mb-2 list-['B.__']">
              VectorSolv shall have the right at any time to change or discontinue any aspect or feature of
              https://www.vectorsolv.com, including, but not limited to, content, hours of availability, and equipment
              needed for access or use.
            </li>
          </ol>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">CHANGED TERMS</h2>
        <div class="font-normal">
          VectorSolv shall have the right at any time to change or modify the terms and conditions applicable to User's
          use of https://www.vectorsolv.com, or any part thereof, or to impose new conditions, including, but not
          limited to, adding fees and charges for use. Such changes, modifications, additions, or deletions shall be
          effective immediately upon notice thereof, which may be given by means including, but not limited to, posting
          on https://www.vectorsolv.com, or by electronic or conventional mail, or by any other means by which User
          obtains notice thereof. Any use of https://www.vectorsolv.com by User after such notice shall be deemed to
          constitute acceptance by User of such changes, modifications, or additions.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">DESCRIPTION OF SERVICES</h2>
        <div class="font-normal">
          Through its Web property, VectorSolv provides User with access to a variety of resources, including download
          areas, communication forums and product information (collectively "Services"). The Services, including any
          updates, enhancements, new features, and/or the addition of any new Web properties, are subject to the TOU.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">EQUIPMENT</h2>
        <div class="font-normal">
          User shall be responsible for obtaining and maintaining all telephone, computer hardware, software and other
          equipment needed for access to and use of https://www.vectorsolv.com and all charges related thereto.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">USER CONDUCT</h2>
        <div class="font-normal">
          <ol class="ml-6">
            <li class="mb-2 list-['A.__']">
              User shall use https://www.vectorsolv.com for lawful purposes only. User shall not post or transmit
              through https://www.vectorsolv.com any material which violates or infringes in any way upon the rights of
              others, which is unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights,
              vulgar, obscene, profane or otherwise objectionable, which encourages conduct that would constitute a
              criminal offense, give rise to civil liability or otherwise violate any law, or which, without
              VectorSolv's express prior approval, contains advertising or any solicitation with respect to products or
              services. Any conduct by a User that in VectorSolv's discretion restricts or inhibits any other User from
              using or enjoying https://www.vectorsolv.com will not be permitted. User shall not use
              https://www.vectorsolv.com to advertise or perform any commercial solicitation, including, but not limited
              to, the solicitation of users to become subscribers of other on-line information services competitive with
              VectorSolv.
            </li>

            <li class="mb-2 list-['B.__']">
              Https://www.vectorsolv.com contains copyrighted material, trademarks and other proprietary information,
              including, but not limited to, text, software, photos, video, graphics, music and sound, and the entire
              contents of https://www.vectorsolv.com are copyrighted as a collective work under the United States of
              America copyright laws. VectorSolv owns a copyright in the selection, coordination, arrangement, and
              enhancement of such content, as well as in the content original to it. User may not modify, publish,
              transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the
              content, in whole or in part. User may download copyrighted material for User's personal use only. Except
              as otherwise expressly permitted under copyright law, no copying, redistribution, retransmission,
              publication, or commercial exploitation of downloaded material will be permitted without the express
              permission VectorSolv and the copyright owner. In the event of any permitted copying, redistribution or
              publication of copyrighted material, no changes in or deletion of author attribution, trademark legend or
              copyright notice shall be made. User acknowledges that it does not acquire any ownership rights by
              downloading copyrighted material.
            </li>

            <li class="mb-2 list-['C.__']">
              User shall not upload, post or otherwise make available on https://www.vectorsolv.com any material
              protected by copyright, trademark or other proprietary right without the express permission of the owner
              of the copyright, trademark or other proprietary right and the burden of determining that any material is
              not protected by copyright rests with User. User shall be solely liable for any damage resulting from any
              infringement of copyrights, proprietary rights, or any other harm resulting from such a submission. By
              submitting material to any public area of https://www.vectorsolv.com, User automatically grants, or
              warrants that the owner of such material has expressly granted VectorSolv the royalty-free, perpetual,
              irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate and
              distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in any
              form, media or technology now known or hereafter developed for the full term of any copyright that may
              exist in such material. User also permits any other User to access, view, store or reproduce the material
              for that User's personal use. User hereby grants VectorSolv the right to edit, copy, publish and
              distribute any material made available on https://www.vectorsolv.com by User.
            </li>

            <li class="mb-2 list-['D.__']">
              The foregoing provisions of Section 5 are for the benefit of VectorSolv, its subsidiaries, affiliates and
              its third-party content providers and licensors and each shall have the right to assert and enforce such
              provisions directly or on its own behalf.
            </li>
          </ol>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">USE OF SERVICES</h2>
        <div class="font-normal">
          <p class="mb-2">
            The Services may contain email services, bulletin board services, chat areas, news groups, forums,
            communities, personal web pages, calendars, photo albums, file cabinets and/or other message or
            communication facilities designed to enable User to communicate with others (each a "Communication Service"
            and collectively "Communication Services"). User agrees to use the Communication Services only to post, send
            and receive messages and material that are proper and, when applicable, related to the particular
            Communication Service. By way of example, and not as a limitation, User agrees that when using the
            Communication Services, User will not:
          </p>
          <ul class="ml-6 list-disc">
            <li class="mb-2">
              Use the Communication Services in connection with surveys, contests, pyramid schemes, chain letters, junk
              email, spamming or any duplicative or unsolicited messages (commercial or otherwise).
            </li>
            <li class="mb-2">
              Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy
              and publicity) of others.
            </li>
            <li class="mb-2">
              Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, obscene,
              indecent, or unlawful topic, name, material or information.
            </li>
            <li class="mb-2">
              Upload, or otherwise make available, files that contain images, photographs, software or other material
              protected by intellectual property laws, including, by way of example, and not as limitation, copyright or
              trademark laws (or by rights of privacy or publicity) unless User own or control the rights thereto or
              have received all necessary consent to do the same.
            </li>
            <li class="mb-2">
              Use any material or information, including images or photographs, which are made available through the
              Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary
              right of any party.
            </li>
            <li class="mb-2">
              Upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any
              other similar software or programs that may damage the operation of another's computer or property of
              another.
            </li>
            <li class="mb-2">
              Advertise or offer to sell or buy any goods or services for any business purpose, unless such
              Communication Services specifically allows such messages.
            </li>
            <li class="mb-2">
              Download any file posted by another user of a Communication Service that User know, or reasonably should
              know, cannot be legally reproduced, displayed, performed, and/or distributed in such manner.
            </li>
            <li class="mb-2">
              Falsify or delete any copyright management information, such as author attributions, legal or other proper
              notices or proprietary designations or labels of the origin or source of software or other material
              contained in a file that is uploaded.
            </li>
            <li class="mb-2">Restrict or inhibit any other user from using and enjoying the Communication Services.</li>
            <li class="mb-2">
              Violate any code of conduct or other guidelines which may be applicable for any particular Communication
              Service.
            </li>
            <li class="mb-2">Harvest or otherwise collect information about others, including email addresses.</li>
            <li class="mb-2">Violate any applicable laws or regulations.</li>
            <li class="mb-2">Create a false identity for the purpose of misleading others.</li>
            <li class="mb-2">
              Use, download or otherwise copy, or provide (whether or not for a fee) to a person or entity any directory
              of users of the Services or other user or usage information or any portion thereof.
            </li>
          </ul>
          <p class="mb-2">
            VectorSolv has no obligation to monitor the Communication Services. However, VectorSolv reserves the right
            to review materials posted to the Communication Services and to remove any materials in its sole discretion.
            VectorSolv reserves the right to terminate User's access to any or all of the Communication Services at any
            time, without notice, for any reason whatsoever. VectorSolv reserves the right at all times to disclose any
            information as it deems necessary to satisfy any applicable law, regulation, legal process, or governmental
            request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in
            VectorSolv's sole discretion.
          </p>
          <p class="mb-2">
            Materials uploaded to the Communication Services may be subject to posted limitations on usage, reproduction
            and/or dissemination; User is responsible for adhering to such limitations if User downloads the materials.
          </p>
          <p class="mb-2">
            Always use caution when giving out any personally identifiable information in any Communication Services.
            VectorSolv does not control or endorse the content, messages or information found in any Communication
            Services and, therefore, VectorSolv specifically disclaims any liability with regard to the Communication
            Services and any actions resulting from User's participation in any Communication Services. Managers and
            hosts are not authorized VectorSolv spokespersons, and their views do not necessarily reflect those of
            VectorSolv.
          </p>
          <p class="mb-2">
            In order to use the payment functionality of WarrantySphere's application, you must open a "Dwolla Account"
            provided by Dwolla, Inc. and you must accept the Dwolla
            <a
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              href="https://www.dwolla.com/legal/tos"
              target="_blank"
              >Terms of Service</a
            >
            and
            <a
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              href="https://www.dwolla.com/legal/privacy"
              target="_blank"
              >Privacy Policy</a
            >. Any funds held in or transferred through your Dwolla Account are held or transferred by Dwolla's
            <a
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
              href="https://www.dwolla.com/legal/about-our-financial-institution-partners"
              target="_blank"
              >financial institution partners</a
            >. You must be at least 18 years old to create a Dwolla Account. You authorize us to collect and share with
            Dwolla your personal information including full name, email address and financial information, and you are
            responsible for the accuracy and completeness of that data. You understand that you will access and manage
            your Dwolla Account through our application, and Dwolla account notifications will be sent by us, not
            Dwolla. We will provide customer support for your Dwolla Account activity, and can be reached at
            <a class="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="mailto:{{contactEmail}}"
              >{{contactEmail}}</a
            >
            and/or
            <a class="font-medium text-blue-600 dark:text-blue-500 hover:underline" href="tel:{{contactPhone}}"
              >{{contactPhone}}</a
            >.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">MEMBER ACCOUNT, PASSWORD, AND SECURITY</h2>
        <div class="font-normal">
          If any of the Services requires User to open an account, User must complete the registration process by
          providing VectorSolv with current, complete, and accurate information as prompted by the applicable
          registration form. User also will choose a password and a username. User is entirely responsible for
          maintaining the confidentiality of User's password and account. Furthermore, User is entirely responsible for
          any and all activities that occur under User's account. User agrees to notify VectorSolv immediately of any
          unauthorized use of User's account or any other breach of security. VectorSolv will not be liable for any loss
          that User may incur as a result of someone else using User's password or account, either with or without
          User's knowledge. However, User could be held liable for losses incurred by VectorSolv or another party due to
          someone else using User's account or password. User may not use anyone else's account at any time, without the
          permission of the account holder.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">NOTICE SPECIFIC TO SOFTWARE AVAILABLE ON THIS VECTORSOLV'S WEBSITE</h2>
        <div class="font-normal">
          <p class="mb-2">
            Any software that is made available to download from the Services ("Software") is the copyrighted work of
            VectorSolv and/or its suppliers. Use of the Software is governed by the terms of the end user license
            agreement, if any, which accompanies or is included with the Software ("License Agreement"). An end user
            will be unable to install any Software that is accompanied by or includes a License Agreement, unless he or
            she first agrees to the License Agreement terms.
          </p>
          <p class="mb-2">
            The Software is made available for download solely for use by end users according to the License Agreement.
            Any reproduction or redistribution of the Software not in accordance with the License Agreement is expressly
            prohibited by law and may result in severe civil and criminal penalties. Violators will be prosecuted to the
            maximum extent possible.
          </p>
          <p class="mb-2">
            WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR
            FURTHER REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED, UNLESS SUCH REPRODUCTION OR REDISTRIBUTION
            IS EXPRESSLY PERMITTED BY THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE. THE SOFTWARE IS WARRANTED, IF AT
            ALL, ONLY ACCORDING TO THE TERMS OF THE LICENSE AGREEMENT. EXCEPT AS WARRANTED IN THE LICENSE AGREEMENT,
            VECTORSOLV HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SOFTWARE, INCLUDING ALL
            WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
          </p>
          <p class="mb-2">
            FOR YOUR CONVENIENCE, VECTORSOLV MAY MAKE AVAILABLE AS PART OF THE SERVICES OR IN ITS SOFTWARE PRODUCTS,
            TOOLS, AND UTILITIES FOR USE AND/OR DOWNLOAD. VECTORSOLV DOES NOT MAKE ANY ASSURANCES WITH REGARD TO THE
            ACCURACY OF THE RESULTS OR OUTPUT THAT DERIVES FROM SUCH USE OF ANY SUCH TOOLS AND UTILITIES. PLEASE RESPECT
            THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS WHEN USING THE TOOLS AND UTILITIES MADE AVAILABLE ON THE
            SERVICES.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">NOTICE SPECIFIC TO DOCUMENTS AVAILABLE ON THIS VECTORSOLV'S WEBSITE</h2>
        <div class="font-normal">
          <p class="mb-2">
            Permission to use Documents (such as white papers, press releases, datasheets and FAQs) from the Services is
            granted, provided that (1) the below copyright notice appears in all copies and that both the copyright
            notice and this permission notice appear, (2) use of such Documents from the Services is for informational
            and non-commercial or personal use only and will not be copied or posted on any network computer or
            broadcast in any media, and (3) no modifications of any Documents are made. Accredited educational
            institutions, such as universities, private/public colleges, and state community colleges, may download and
            reproduce the Documents for distribution in the classroom. Distribution outside the classroom requires
            express written permission. Use for any other purpose is expressly prohibited by law and may result in
            severe civil and criminal penalties. Violators will be prosecuted to the maximum extent possible.
          </p>
          <p class="mb-2">
            VECTORSOLV AND/OR ITS RESPECTIVE SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION
            CONTAINED IN THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED AS PART OF THE SERVICES FOR ANY PURPOSE. ALL SUCH
            DOCUMENTS AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. VECTORSOLV AND/OR ITS
            RESPECTIVE SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
            INCLUDING ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS, IMPLIED OR STATUTORY, FITNESS
            FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN NO EVENT VECTORSOLV AND/OR ITS RESPECTIVE
            SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING
            FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION,
            ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF INFORMATION AVAILABLE FROM THE SERVICES.
          </p>
          <p class="mb-2">
            THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE SERVICES COULD INCLUDE TECHNICAL INACCURACIES OR
            TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. VECTORSOLV AND/OR ITS
            RESPECTIVE SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT(S) AND/OR THE PROGRAM(S) DESCRIBED
            HEREIN AT ANY TIME.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">
          NOTICES REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS VECTORSOLV SITE
        </h2>
        <div class="font-normal">
          IN NO EVENT SHALL VECTORSOLV AND/OR ITS RESPECTIVE SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT OR
          CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN
          ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE USE OR
          PERFORMANCE OF SOFTWARE, DOCUMENTS, PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR INFORMATION AVAILABLE FROM
          THE SERVICES.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">MATERIALS PROVIDED TO VECTORSOLV OR POSTED AT ANY OF ITS VECTORSOLV WEBSITE</h2>
        <div class="font-normal">
          <p class="mb-2">
            VectorSolv does not claim ownership of the materials User provide to VectorSolv (including feedback and
            suggestions) or post, upload, input or submit to any Services or its associated services for review by the
            general public, or by the members of any public or private community, (each a "Submission" and collectively
            "Submissions"). However, by posting, uploading, inputting, providing or submitting ("Posting") User's
            Submission User is granting VectorSolv, its affiliated companies and necessary sublicensees permission to
            use User's Submission in connection with the operation of their Internet businesses (including, without
            limitation, all VectorSolv Services), including, without limitation, the license rights to: copy,
            distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat User's
            Submission; to publish User's name in connection with User's Submission; and the right to sublicense such
            rights to any supplier of the Services.
          </p>
          <p class="mb-2">
            No compensation will be paid with respect to the use of User's Submission, as provided herein VectorSolv is
            under no obligation to post or use any Submission User may provide and VectorSolv may remove any Submission
            at any time in its sole discretion. By Posting a Submission User warrants and represents to own or otherwise
            control all of the rights to User's Submission as described in these Terms of Use including, without
            limitation, all the rights necessary for User to provide, post, upload, input or submit the Submissions.
          </p>
          <p class="mb-2">
            In addition to the warranty and representation set forth above, by Posting a Submission that contain images,
            photographs, pictures or that are otherwise graphical in whole or in part ("Images"), User warrant and
            represent that (a) User is the copyright owner of such Images, or that the copyright owner of such Images
            has granted User permission to use such Images or any content and/or images contained in such Images
            consistent with the manner and purpose of User's use and as otherwise permitted by these Terms of Use and
            the Services, (b) User have the rights necessary to grant the licenses and sublicenses described in these
            Terms of Use, and (c) that each person depicted in such Images, if any, has provided consent to the use of
            the Images as set forth in these Terms of Use, including, by way of example, and not as a limitation, the
            distribution, public display and reproduction of such Images. By Posting Images, User is granting (a) to all
            members of User's private community (for each such Images available to members of such private community),
            and/or (b) to the general public (for each such Images available anywhere on the Services, other than a
            private community), permission to use User's Images in connection with the use, as permitted by these Terms
            of Use, of any of the Services, (including, by way of example, and not as a limitation, making prints and
            gift items which include such Images), and including, without limitation, a non-exclusive, world-wide,
            royalty-free license to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit,
            translate and reformat User's Images without having User's name attached to such Images, and the right to
            sublicense such rights to any supplier of the Services. The licenses granted in the preceding sentences for
            a Images will terminate at the time User completely remove such Images from the Services, provided that,
            such termination shall not affect any licenses granted in connection with such Images prior to the time User
            completely remove such Images. No compensation will be paid with respect to the use of User's Images.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY</h2>
        <div class="font-normal">
          <ol class="ml-6">
            <li class="mb-2 list-['A.__']">
              USER EXPRESSLY AGREES THAT USE OF HTTPS://WWW.VECTORSOLV.COM IS AT USER'S SOLE RISK. NEITHER VECTORSOLV,
              ITS AFFILIATES NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS
              WARRANT THAT HTTPS://WWW.VECTORSOLV.COM WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY
              AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF HTTPS://WWW.VECTORSOLV.COM, OR AS TO THE ACCURACY,
              RELIABILITY OR CONTENT OF ANY INFORMATION, SERVICE, OR MERCHANDISE PROVIDED THROUGH
              HTTPS://WWW.VECTORSOLV.COM.
            </li>
            <li class="mb-2 list-['B.__']">
              HTTPS://WWW.VECTORSOLV.COM IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS
              OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR
              FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF
              EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
            </li>
            <li class="mb-2 list-['C.__']">
              THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR,
              OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS,
              COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF
              RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.
              USER SPECIFICALLY ACKNOWLEDGES THAT VECTORSOLV IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL
              CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH
              USER.
            </li>
            <li class="mb-2 list-['D.__']">
              IN NO EVENT WILL VECTORSOLV, OR ANY PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR DISTRIBUTING
              HTTPS://WWW.VECTORSOLV.COM OR THE VECTORSOLV SOFTWARE, BE LIABLE FOR ANY DAMAGES, INCLUDING, WITHOUT
              LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF THE
              USE OF OR INABILITY TO USE HTTPS://WWW.VECTORSOLV.COM. USER HEREBY ACKNOWLEDGES THAT THE PROVISIONS OF
              THIS SECTION SHALL APPLY TO ALL CONTENT ON THE SITE.
            </li>
            <li class="mb-2 list-['E.__']">
              IN ADDITION TO THE TERMS SET FORTH ABOVE NEITHER, VECTORSOLV, NOR ITS AFFILIATES, INFORMATION PROVIDERS OR
              CONTENT PARTNERS SHALL BE LIABLE REGARDLESS OF THE CAUSE OR DURATION, FOR ANY ERRORS, INACCURACIES,
              OMISSIONS, OR OTHER DEFECTS IN, OR UNTIMELINESS OR UNAUTHENTICITY OF, THE INFORMATION CONTAINED WITHIN
              HTTPS://WWW.VECTORSOLV.COM, OR FOR ANY DELAY OR INTERRUPTION IN THE TRANSMISSION THEREOF TO THE USER, OR
              FOR ANY CLAIMS OR LOSSES ARISING THEREFROM OR OCCASIONED THEREBY. NONE OF THE FOREGOING PARTIES SHALL BE
              LIABLE FOR ANY THIRD-PARTY CLAIMS OR LOSSES OF ANY NATURE, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS,
              PUNITIVE OR CONSEQUENTIAL DAMAGES.
            </li>
            <li class="mb-2 list-['F.__']">
              PRIOR TO THE EXECUTION OF A STOCK TRADE, USERS ARE ADVISED TO CONSULT WITH YOUR BROKER OR OTHER FINANCIAL
              REPRESENTATIVE TO VERIFY PRICING OR OTHER INFORMATION. VECTORSOLV, ITS AFFILIATES, INFORMATION PROVIDERS
              OR CONTENT PARTNERS SHALL HAVE NO LIABILITY FOR INVESTMENT DECISIONS BASED ON THE INFORMATION PROVIDED.
              NEITHER VECTORSOLV, NOR ITS AFFILIATES, INFORMATION PROVIDERS OR CONTENT PARTNERS WARRANT OR GUARANTEE THE
              TIMELINESS, SEQUENCE, ACCURACY OR COMPLETENESS OF THIS INFORMATION. ADDITIONALLY, THERE ARE NO WARRANTIES
              AS TO THE RESULTS OBTAINED FROM THE USE OF THE INFORMATION.
            </li>
            <li class="mb-2 list-['G.__']">
              FORCE MAJEURE - NEITHER PARTY WILL BE RESPONSIBLE FOR ANY FAILURE OR DELAY IN PERFORMANCE DUE TO
              CIRCUMSTANCES BEYOND ITS REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, ACTS OF GOD, WAR, RIOT,
              EMBARGOES, ACTS OF CIVIL OR MILITARY AUTHORITIES, FIRE, FLOODS, ACCIDENTS, SERVICE OUTAGES RESULTING FROM
              EQUIPMENT AND/OR SOFTWARE FAILURE AND/OR TELECOMMUNICATIONS FAILURES, POWER FAILURES, NETWORK FAILURES,
              FAILURES OF THIRD PARTY SERVICE PROVIDERS (INCLUDING PROVIDERS OF INTERNET SERVICES AND
              TELECOMMUNICATIONS). THE PARTY AFFECTED BY ANY SUCH EVENT SHALL NOTIFY THE OTHER PARTY WITHIN A MAXIMUM OF
              FIFTEEN (15) DAYS FROM ITS OCCURENCE. THE PERFORMANCE OF THS AGREEMENT SHALL THEN BE SUSPENDED FOR AS LONG
              AS ANY SUCH EVENT SHALL PREVENT THE AFFECTED PARTY FROM PERFORMING ITS OBLIGATIONS UNDER THIS AGREEMENT.
            </li>
          </ol>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">LINKS TO THIRD PARTY SITES</h2>
        <div class="font-normal">
          <p class="mb-2">
            THE LINKS IN THIS AREA WILL LET YOU LEAVE VECTORSOLV'S SITE. THE LINKED SITES ARE NOT UNDER THE CONTROL OF
            VECTORSOLV AND VECTORSOLV IS NOT RESPONSIBLE FOR THE CONTENTS OF ANY LINKED SITE OR ANY LINK CONTAINED IN A
            LINKED SITE, OR ANY CHANGES OR UPDATES TO SUCH SITES. VECTORSOLVS NOT RESPONSIBLE FOR WEBCASTING OR ANY
            OTHER FORM OF TRANSMISSION RECEIVED FROM ANY LINKED SITE. VECTORSOLV IS PROVIDING THESE LINKS TO YOU ONLY AS
            A CONVENIENCE, AND THE INCLUSION OF ANY LINK DOES NOT IMPLY ENDORSEMENT VECTORSOLV OF THE SITE.
          </p>
          <p class="mb-2">
            VectorSolv is a distributor (and not a publisher) of content supplied by third parties and Users.
            Accordingly, VectorSolv has no more editorial control over such content than does a public library,
            bookstore, or newsstand. Any opinions, advice, statements, services, offers, or other information or content
            expressed or made available by third parties, including information providers, Users or any other user of
            https://www.vectorsolv.com, are those of the respective author(s) or distributor(s) and not of VectorSolv.
            Neither VectorSolv nor any third-party provider of information guarantees the accuracy, completeness, or
            usefulness of any content, nor its merchantability or fitness for any particular purpose.
          </p>
          <p class="mb-2">
            In many instances, the content available through https://www.vectorsolv.com represents the opinions and
            judgments of the respective information provider, User, or other user not under contract VectorSolv.
            VectorSolv neither endorses nor is responsible for the accuracy or reliability of any opinion, advice or
            statement made on https://www.vectorsolv.com by anyone other than authorized VectorSolv employee
            spokespersons while acting in their official capacities. Under no circumstances will VectorSolv be liable
            for any loss or damage caused by a User's reliance on information obtained through
            https://www.vectorsolv.com. It is the responsibility of User to evaluate the accuracy, completeness or
            usefulness of any information, opinion, advice, or other content available through VectorSolv. Please seek
            the advice of professionals, as appropriate, regarding the evaluation of any specific information, opinion,
            advice or other content.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">UNSOLICITED IDEA SUBMISSION POLICY</h2>
        <div class="font-normal">
          VECTORSOLV OR ANY OF ITS EMPLOYEES DO NOT ACCEPT OR CONSIDER UNSOLICITED IDEAS, INCLUDING IDEAS FOR NEW
          ADVERTISING CAMPAIGNS, NEW PROMOTIONS, NEW PRODUCTS OR TECHNOLOGIES, PROCESSES, MATERIALS, MARKETING PLANS OR
          NEW PRODUCT NAMES. PLEASE DO NOT SEND ANY ORIGINAL CREATIVE ARTWORK, SAMPLES, DEMOS, OR OTHER WORKS. THE SOLE
          PURPOSE OF THIS POLICY IS TO AVOID POTENTIAL MISUNDERSTANDINGS OR DISPUTES WHEN VECTORSOLV'S PRODUCTS OR
          MARKETING STRATEGIES MIGHT SEEM SIMILAR TO IDEAS SUBMITTED TO VECTORSOLV. SO, PLEASE DO NOT SEND YOUR
          UNSOLICITED IDEAS TO VECTORSOLV OR ANYONE AT VECTORSOLV. IF, DESPITE OUR REQUEST THAT YOU NOT SEND US YOUR
          IDEAS AND MATERIALS, YOU STILL SEND THEM, PLEASE UNDERSTAND THAT VECTORSOLV MAKES NO ASSURANCES THAT YOUR
          IDEAS AND MATERIALS WILL BE TREATED AS CONFIDENTIAL OR PROPRIETARY.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">MONITORING</h2>
        <div class="font-normal">
          VectorSolv shall have the right, but not the obligation, to monitor the content https://www.vectorsolv.com,
          including chat rooms and forums, to determine compliance with this Agreement and any operating rules
          established by VectorSolv and to satisfy any law, regulation, or authorized government request. VectorSolv
          shall have the right in its sole discretion to edit, refuse to post or remove any material submitted to or
          posted on https://www.vectorsolv.com. Without limiting the foregoing, VectorSolv shall have the right to
          remove any material that VectorSolv, in its sole discretion, finds to be in violation of the provisions hereof
          or otherwise objectionable.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">INDEMNIFICATION</h2>
        <div class="font-normal">
          User agrees to defend, indemnify, and hold harmless VectorSolv, its affiliates and their respective directors,
          officers, employees and agents from and against all claims and expenses, including attorneys' fees, arising
          out of the use of VectorSolv by User or User's Account.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">TERMINATION</h2>
        <div class="font-normal">
          Either VectorSolv or User may terminate this Agreement at any time. Without limiting the foregoing, VectorSolv
          shall have the right to immediately terminate User's Account in the event of any conduct by User which
          VectorSolv, in its sole discretion, considers to be unacceptable, or in the event of any breach by User of
          this Agreement.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">MISCELLANEOUS</h2>
        <div class="font-normal">
          This Agreement and any operating rules for https://www.vectorsolv.com established by VectorSolv constitute the
          entire agreement of the parties with respect to the subject matter hereof and supersede all previous written
          or oral agreements between the parties with respect to such subject matter. This Agreement shall be construed
          in accordance with the laws of the Virgina, Canada], without regard to its conflict of laws rules. No waiver
          by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or
          subsequent breach or default. The section headings used herein are for convenience only and shall not be given
          any legal import.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">COPYRIGHT NOTICE</h2>
        <div class="font-normal">
          VectorSolv its logos are trademarks of VectorSolv, All rights reserved. All other trademarks appearing on
          VectorSolv are the property of their respective owners.
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">TRADEMARKS</h2>
        <div class="font-normal">
          <p class="mb-2">
            The names of actual companies and products mentioned herein may be the trademarks of their respective
            owners. The example companies, organizations, products, domain names, email addresses, logos, people and
            events depicted herein are fictitious. No association with any real company, organization, product, domain
            name, email address, logo, person, or event is intended or should be inferred.
          </p>
          <p class="mb-2">Any rights not expressly granted herein are reserved.</p>
        </div>
      </li>
    </ol>
  </div>
</div>
