import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { TenantService } from '@vsolv/core/multi-tenant/web';

/**This guard is used to check if the route contains a tenantId and if so, route you to the url without it. */
@Injectable()
export class TenantIdGuard implements CanActivate {
  constructor(private router: Router, private tenantSvc: TenantService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const tenantId = this.tenantSvc.tenantId;
    const url = route.url.join('/');

    if (tenantId && url.includes(tenantId)) {
      const newUrl = url.replace(tenantId, '');
      return this.router.createUrlTree([newUrl], {
        queryParams: route.queryParams,
      });
    }

    return this.router.createUrlTree([''], { queryParams: route.queryParams });
  }
}
