<ng-container *ngIf="isSignedIn$ | async">
  <button
    type="button"
    class="whitespace-nowrap vs-btn !text-primary-200"
    color="primary"
    size="small"
    (click)="signOut()"
  >
    <svg
      class="w-5 h-5 scale-[-1]"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
      ></path>
    </svg>
    Log out
  </button>
</ng-container>
