<div class="vs-card w-full min-w-[320px]" appearance="raised">
  <div
    *ngIf="!header.hide"
    class="vs-card-content flex items-center justify-between gap-4 flex-wrap border-b border-gray-200"
  >
    <div class="flex items-center gap-4 flex-1">
      <ng-content select="[headerStart]"></ng-content>

      <div class="flex-1">
        <ng-container *ngIf="header.title">
          <h2 class="vs-card-header">{{ header.title }}</h2>
          <p class="text-sm font-normal">{{ header.subtitle }}</p>
        </ng-container>
      </div>

      <ng-content select="[headerAfter]"></ng-content>
    </div>

    <ng-content select="[headerEnd]"></ng-content>
  </div>

  <!-- add default body content -->
  <ng-content></ng-content>
</div>
