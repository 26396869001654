import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationQueryRequest } from '@vsolv/dev-kit/ngx';
import { Payment } from '@vsolv/packages/payments/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ThemeColorEnum } from '@vsolv/vectors-ui/theming';
import { BadgeColumn, TableColumn, TextColumn, TextSubtitleColumn } from '@vsolv/vectors/table';
import { CustomersCustomerPortalService, PaymentsCustomerPortalService } from '@wsphere/customer-portal/web';
import { Customer } from '@wsphere/customers/domain';
import moment from 'moment';
import { UcFirstPipe } from 'ngx-pipes';
import { BehaviorSubject, combineLatest, from, of, shareReplay, switchMap } from 'rxjs';

@Component({ templateUrl: './finance.page.html' })
export class CustomerFinancesPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private portalSvc: PortalService,
    private paymentsSvc: PaymentsCustomerPortalService,
    private customerSvc: CustomersCustomerPortalService
  ) {}

  private ucFirst = new UcFirstPipe();

  customer?: Customer.Model;

  failedPayment$ = from(this.paymentsSvc.getFailed());

  payments$ = of(null).pipe(
    switchMap(async () => {
      const response = await this.paymentsSvc.getList({ page: 1, limit: 100 });

      const grouped = response.items
        .sort((a, b) => a.created.getTime() - b.created.getTime())
        .reduce(
          (acc, payment) => ({
            ...acc,
            [payment.referenceId || 'null']: [...(acc[payment.referenceId || 'null'] || []), payment],
          }),
          {} as { [key: string]: Payment.Model[] }
        );

      const result: Payment.Model[] = [];

      for (const payments of Object.values(grouped)) {
        const firstPendingIndex = payments.findIndex(payment => payment.status === Payment.Status.PENDING);
        if (firstPendingIndex === -1) {
          result.push(...payments);
        } else {
          result.push(...payments.slice(0, firstPendingIndex + 1));
        }
      }

      return result.sort((a, b) => b.created.getTime() - a.created.getTime());
    })
  );

  columns: TableColumn<unknown>[] = [
    new TextSubtitleColumn<Payment.Model>({ header: 'Warranty' }, payment => ({
      title: payment.priceBreakdown ? payment.priceBreakdown[0]?.title : 'Warranty',
      titleClasses: 'truncate max-w-[15vw]',

      subtitle: payment.referenceId,
      subtitleClasses: 'truncate max-w-[15vw]',
    })),

    new TextColumn<Payment.Model>({ header: 'Transaction' }, payment => ({
      text: payment.description ?? '',
      classes: 'truncate max-w-[15vw]',
    })),

    new BadgeColumn<Payment.Model>({ header: 'Status', fitContent: true, align: 'center' }, payment => ({
      text: this.ucFirst.transform(payment.status.replace(/_/g, ' ').toLowerCase()),
      displayStatusIcon: true,
      theme: {
        [Payment.Status.DRAFT]: ThemeColorEnum.default,

        [Payment.Status.VOIDED]: ThemeColorEnum.default,
        [Payment.Status.PENDING]: ThemeColorEnum.warn,

        [Payment.Status.PAID]: ThemeColorEnum.success,
        [Payment.Status.FAILED]: ThemeColorEnum.danger,
        [Payment.Status.PROCESSING]: ThemeColorEnum.info,

        [Payment.Status.REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PARTIALLY_REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PROCESSING_REFUND]: ThemeColorEnum.info,
      }[payment.status],
    })),

    new TextColumn<Payment.Model>({ header: 'Total', fitContent: true, align: 'right' }, payment => ({
      text: payment.amount ? '$' + (payment.amount / 100).toFixed(2) : '-',
      classes: 'truncate max-w-[15vw]',
    })),

    new TextSubtitleColumn<Payment.Model>({ header: 'Payment Method' }, payment => ({
      title: payment.paymentMethod?.creditCard
        ? `${payment.paymentMethod.creditCard.cardType} (••••${payment.paymentMethod.creditCard.cardNumber.match(
            /[\d]{4}$/
          )})`
        : '',
      titleClasses: 'truncate max-w-[15vw]',

      subtitle: payment.paymentMethod?.creditCard ? `Exp. ${payment.paymentMethod.creditCard.expirationDate}` : '',
      subtitleClasses: 'truncate max-w-[15vw]',
    })),

    new TextColumn<Payment.Model>({ header: 'Transaction Date', fitContent: true, align: 'center' }, payment => ({
      text: payment.paymentDate ? moment(payment.paymentDate).fromNow() : '-',
      classes: 'truncate max-w-[15vw]',
    })),
  ];

  protected pagination$ = new BehaviorSubject<PaginationQueryRequest>({ page: 1, limit: 10 });
  readonly page$ = combineLatest([this.pagination$]).pipe(
    switchMap(([pagination]) => this.paymentsSvc.getList({ ...pagination })),
    shareReplay(1)
  );

  rowClicked(paymentId?: string) {
    if (paymentId) this.router.navigate([`${paymentId}`], { relativeTo: this.route });
  }

  displayPaymentMethod(payment: Payment.Model) {
    if (payment.paymentMethod == undefined) return;
    else if (payment.paymentMethod.bankAccount !== null) return payment.paymentMethod.bankAccount.bankName;
    else if (payment.paymentMethod.creditCard !== null) return 'Credit Card';
    else return '';
  }
  displayPaymentMethodDetails(payment: Payment.Model) {
    if (payment.paymentMethod == undefined) return;
    else if (payment.paymentMethod.bankAccount !== null)
      return (
        payment.paymentMethod.bankAccount.accountType +
        ' account (' +
        payment.paymentMethod.bankAccount.accountNumber +
        ')'
      );
    else if (payment.paymentMethod.creditCard !== null)
      return payment.paymentMethod.creditCard.cardType + ' (' + payment.paymentMethod.creditCard.cardNumber + ')';
    else return '';
  }

  async ngOnInit(): Promise<void> {
    this.customer = await this.customerSvc.retrieveSelf();
  }
}
