import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { ToastService } from '@vsolv/ui-kit/toast';
import { Warranty } from '@wsphere/warranties/domain';
import { firstValueFrom } from 'rxjs';
import { WarrantyCustomerPortalService } from '../services';
import { WarrantyResolver } from './warranty.resolver';

@Injectable()
export class WarrantyGuard implements CanActivate {
  constructor(
    private warrantySvc: WarrantyCustomerPortalService,
    private router: Router,
    private resolver: WarrantyResolver,
    private currentRoute: ActivatedRoute,
    private toastSvc: ToastService,
    private firebaseSvc: FirebaseService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.resolver.warranty = undefined;
    const signedIn = await firstValueFrom(this.firebaseSvc.authUser$);
    if (!signedIn?.email) {
      this.router.navigate([], {});
    }
    const warrantyId = this.extractWarrantyId(route);
    if (!warrantyId) return this.notFound(state);

    const warranty = await this.warrantySvc.getOneByContractNumber(warrantyId).catch(() => null);
    if (!warranty) return this.notFound(state);
    if (warranty.customer?.email !== signedIn?.email) {
      this.router.navigate([], {});
    }
    this.resolver.warranty = warranty;

    if (warranty.status === Warranty.Status.REGISTERED && warranty.activationDeadline.getTime() > Date.now()) {
      this.router.navigate([`warranty/${warrantyId}/activate`], {
        relativeTo: this.currentRoute,
      });
    }

    return true;
  }

  private notFound(state: RouterStateSnapshot) {
    this.toastSvc.displayToast('Invalid Contract Number', 'error', 4000);
    return this.router.createUrlTree(state.url.split('/').slice(0, -1));
  }

  private extractWarrantyId(route: ActivatedRouteSnapshot) {
    return route.paramMap.get('contractNumber');
  }

  private findTenantId(route: ActivatedRouteSnapshot): string {
    return route.params['tenantId'] || (route.parent ? this.findTenantId(route.parent) : null);
  }
}
