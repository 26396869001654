import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimService } from '@wsphere/warranties/web';
import { BehaviorSubject, firstValueFrom, map, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'ws-claim',
  templateUrl: './claim.page.html',
})
export class ClaimPage implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private breakpointObserver: BreakpointObserver
  ) {}

  claim = new BehaviorSubject<Claim.Model | null>(null);

  @ViewChild('cancelClaimDialog') cancelClaimDialog?: DialogComponent;

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  protected status = {
    [Claim.Status.OPEN]: 'info',
    [Claim.Status.CANCELLED]: 'warn',
    [Claim.Status.CLOSED]: 'default',
    [Claim.Status.EXPIRED]: 'danger',
  };

  protected claim$: Observable<Claim.Model | null> = this.claimSvc.getClaim();

  protected payments$: Observable<Claim.ClaimPayment.ListClaimPaymentQueryResponse | null> = this.claim$.pipe(
    switchMap(async claim => (claim ? await this.claimSvc.listPayments(claim.id, { page: 1, limit: 5 }) : null))
  );

  openCancelDialog() {
    this.cancelClaimDialog?.open();
  }

  async refreshClaim() {
    await this.claimSvc.refreshClaim();
  }

  async cancel() {
    if (!this.claim.value) return;

    const cancelledClaimId = await this.claimSvc.cancel(this.claim.value.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.cancelClaimDialog?.close();
    });

    if (cancelledClaimId) {
      this.toastSvc.show({
        type: 'success',
        title: 'Cancelled Claim',
        text: `Claim has successfully been cancelled.`,
      });

      this.cancelClaimDialog?.close();
    }
  }
  async ngOnInit() {
    const claim = (await firstValueFrom(this.route.data))['claim'] as Claim.Model;
    await this.claimSvc.refreshClaim(claim);
    this.claim.next(claim);
  }
}
