<div class="flex-grow px-4 py-3 lg:px-14 lg:py-10 bg-gray-50 overflow-auto max-h-screen">
  <div class="mb-6">
    <h1 class="mr-2 inline-block text-3xl text-base-dark font-bold">Privacy Policy</h1>
    <p>Last Updated › Mar 25th, 2024</p>
  </div>

  <div>
    <p class="mb-2">
      It is important to VectorSolv LLC (“We”, “VectorSolv”, or “VS”) to help our customers retain their privacy when
      they take advantage of all the information contained that its website has to offer.
    </p>
    <p class="mb-2">
      We believe your business is no one else's. Your privacy is important to you and to us. So we'll protect the
      information you share with us. To protect your privacy, VectorSolv follows different principles in accordance with
      worldwide practices for customer privacy and data protection.
    </p>
    <ul class="mb-8 list-disc ml-6 [&>li]:mb-2">
      <li>
        We will not sell or give away your name, mail address, phone number, email address or any other information to
        anyone.
      </li>
      <li>We will use state-of-the-art security measures to protect your information from unauthorized users.</li>
      <li>We will abide by all laws and regulations governing protecting you information.</li>
    </ul>

    <ol class="list-none font-bold [&>li]:mb-8">
      <li>
        <h2 class="uppercase mb-2">NOTICE</h2>
        <div class="font-normal">
          <p class="mb-2">
            We will ask you when we need information that personally identifies you (personal information) or allows us
            to contact you. Generally, this information is requested when you create or use an account, purchase a
            product or service, or want additional information on the site or when you download free software, enter a
            contest, order email newsletters, or join a limited-access premium site. We use your Personal Information
            for four primary purposes:
          </p>
          <ul class="list-disc ml-6 [&>li]:mb-2">
            <li>To make the site easier for you to use by not having to enter information more than once;</li>
            <li>To help you quickly find software, services, or information;</li>
            <li>To help us create content most relevant to you; and/or</li>
            <li>
              To alert you to product upgrades, special offers, updated information, and other new services from
              VectorSolv.
            </li>
          </ul>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">CONSENT</h2>
        <div class="font-normal">
          <p class="mb-2">
            If you choose not to register or provide personal information, you can still use most of
            https://www.vectorsolv.com. But you will not be able to access areas that require registration.
          </p>
          <p class="mb-2">
            If you decide to register, you will be able to select the kinds of information you want to receive from us
            by subscribing to various services, like our electronic newsletters. If you do not want us to communicate
            with you about other offers regarding VectorSolv products, programs, events, or services by email, postal
            mail, or telephone, you may select the option stating that you do not wish to receive marketing messages
            from VectorSolv.
          </p>
          <p class="mb-2">
            VectorSolv occasionally allows other companies to offer our registered customers information about their
            products and services, using postal or electronic mail. If you do not want to receive these offers, you may
            select the option stating that you do not wish to receive marketing materials from third parties.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">ACCESS</h2>
        <div class="font-normal">
          <p class="mb-2">
            We will provide you with the means to ensure that your personal information is correct and current. You may
            review and update this information at any time by accessing your account. There, you can:
          </p>
          <ul class="list-disc ml-6 [&>li]:mb-2">
            <li>View and edit personal information you have already given us;</li>
            <li>
              Tell us whether you want us to send you additional or marketing information, or whether you want third
              parties to send you their offers by postal mail;
            </li>
            <li>Sign up for electronic newsletters about our services and products; and/or</li>
            <li>
              Register. Once you register, you won't need to do it again. Wherever you go on https://www.vectorsolv.com,
              your information stays with you.
            </li>
          </ul>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">SECURITY</h2>
        <div class="font-normal">
          <p class="mb-2">
            VectorSolv has taken strong measures to protect the security of your personal information and to ensure that
            your choices for its intended use are honored. We take strong precautions to protect your data from loss,
            misuse, unauthorized access or disclosure, alteration, or destruction.
          </p>
          <p class="mb-2">
            We guarantee your e-commerce transactions to be 100% safe and secure. When you place orders or access your
            personal account information, you're utilizing secure server software SSL, which encrypts your personal
            information before it's sent over the Internet. SSL is one of the safest encryption technologies available.
          </p>
          <p class="mb-2">
            In addition, your transactions are guaranteed under applicable Laws and Regulations governing the
            transaction and/or jurisdiction in which the said transaction is governed.
          </p>
          <p class="mb-2">
            VectorSolv strictly protects the security of your personal information and honors your choices for its
            intended use. We carefully protect your data from loss, misuse, unauthorized access or disclosure,
            alteration, or destruction.
          </p>
          <p class="mb-2">
            Your personal information is never shared outside the company without your permission, except under
            conditions explained above or in the terms and conditions governing the product or service you purchased..
            Inside the company, data is stored in password-controlled servers with limited access. Your information may
            be stored and processed in the United States, Canada or any other country where VectorSolv, its
            subsidiaries, affiliates or agents are located.
          </p>
          <p class="mb-2">
            You also have a significant role in protecting your information. No one can see or edit your personal
            information without knowing your username and password, so do not share these with others.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">ENFORCEMENT</h2>
        <div class="font-normal">
          <p class="mb-2">
            If for some reason you believe VectorSolv has not adhered to these principles, please notify us by email at
            privacy@vectorsolv.com, and we will do our best to determine and correct the problem promptly. Be certain
            the words Privacy Policy are in the Subject line.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">ELECTRONIC PRODUCT REGISTRATION</h2>
        <div class="font-normal">
          <p class="mb-2">
            When you buy and install a new product, we may ask you to register your purchase electronically. When you
            do, we merge your registration information with any information you've already left with us (we call that
            information your personal profile). If you haven't previously registered with us, we create a personal
            profile for you from your product registration information. If you ever want to review or update that
            information, you can visit the customer portal, click on Update Profile, and edit any of the Personal
            Information in your profile. If you haven't already created or activated an account, we will ask you to do
            so. This ensures that only you can access your information.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">CUSTOMER PROFILES</h2>
        <div class="font-normal">
          <p class="mb-2">
            As mentioned above, every registered customer has a unique personal profile. Each profile is assigned a
            unique personal identification number, which helps us ensure that only you can access your profile.
          </p>
          <p class="mb-2">
            When you register or activate, we create your profile, assign a personal identification number or agreement
            identification number, then send this identification number back to your hard drive in the form of a cookie,
            which is a very small bit of code. This code is uniquely yours. It is your passport to seamless travel
            across https://www.vectorsolv.com, allowing you to download free software, order free newsletters, and visit
            premium sites without having to fill out registration forms with information you've already provided. Even
            if you switch computers, you won't have to re-register – just use your identification number to identify
            yourself.
          </p>
        </div>
      </li>

      <li>
        <h2 class="uppercase mb-2">WHAT WE DO WITH THE INFORMATION YOU SHARE</h2>
        <div class="font-normal">
          <p class="mb-2">
            When you join us, you provide us with your contact information, including your email address. We use this
            information to send you updates about your order, questionnaires to measure your satisfaction with our
            service and announcements about new and exciting services that we offer. When you order from us, we ask for
            your credit card number and billing address. We use this information only to bill you for the product(s) you
            order at that time. For your convenience, we do save billing information in case you want to order from us
            again, but we don't use this information again without your permission.
          </p>
          <p class="mb-2">
            We occasionally hire other companies to provide limited services on our behalf, including packaging, mailing
            and delivering purchases, answering customer questions about products or services, sending postal mail and
            processing event registration and fulfilling our obligations under applicable terms and conditions of a
            service agreement. We will only provide those companies the information they need to deliver the service,
            and they are prohibited from using that information for any other purpose.
          </p>
          <p class="mb-2">
            VectorSolv will disclose your personal information, without notice, only if required to do so by law or in
            the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with
            legal process served on VectorSolv or the site; (b) protect and defend the rights or property of VectorSolv
            and its family of Websites, and, (c) act in urgent circumstances to protect the personal safety of users of
            VectorSolv, its Websites, or the public.
          </p>
        </div>
      </li>
    </ol>
  </div>
</div>
