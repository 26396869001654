import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class SignInGuard implements CanActivate {
  constructor(private router: Router, private firebaseSvc: FirebaseService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const currentUser = await firstValueFrom(this.firebaseSvc.authUser$);
    const tenantId = (await this.firebaseSvc.getCurrentFirebaseTenant()).firebaseTenantId;
    if (!currentUser || currentUser.tenantId !== tenantId) {
      await this.firebaseSvc.signOut();
      return this.router.createUrlTree(['../login'], {
        queryParams: {
          ...route.queryParams,
          ...(location.pathname !== '/' && location.pathname !== '/login' && { redirect: location.pathname }),
        },
      });
    }
    return true;
  }
}
