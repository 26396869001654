/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Note } from '@vsolv/packages/notes/domain';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimService, ViewClaimDocumentDialog } from '@wsphere/warranties/web';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-claim-notes-viewer',
  templateUrl: './claim-notes-viewer.component.html',
})
export class ClaimNotesViewerComponent implements OnInit {
  constructor(private claimSvc: ClaimService, private linkSvc: LinksWebService) {}

  @ViewChild('documentDialog') documentDialog?: ViewClaimDocumentDialog;

  @PropertyListener('claim') claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim?: Claim.Model | null = null;

  notes: Note.Model[] = [];

  noteAndLinks$ = combineLatest([this.claim$]).pipe(
    switchMap(async ([claim]) => (claim ? { claim, notes: await this.claimSvc.listNotes(claim.id) } : null)),
    map(data => (data ? { ...data, notes: data.notes.items } : null)),
    switchMap(async data => {
      const notes = data?.notes;
      const claim = data?.claim;
      if (!notes || !claim) return [];
      this.notes = notes;
      const noteAndLinks: { note: Note.Model; links: { link: Link.Model; config: InfoCardConfig }[] }[] = [];

      await Promise.all(
        notes.map(async note => {
          const links = await this.linkSvc.list(
            { id: claim.id, objectType: Link.ObjectType.CLAIM },
            { owner: { id: note.id, objectType: Link.ObjectType.NOTE } }
          );

          const linksAndConfigs = links.items.reduce((acc, link) => {
            const type = Link.getLinkType(link, Link.ObjectType.NOTE);
            if (type === Link.ObjectType.ACTIVITY) return acc;

            const object = Link.getLinkObject(link, type, note.id);
            if ((object as any)?.deleted) return acc;

            const config = Link.getLinkInfoCardConfig(link, type, note.id);
            acc.push({ link, config });

            return acc;
          }, [] as { link: Link.Model; config: InfoCardConfig }[]);

          noteAndLinks.push({ note, links: linksAndConfigs });
        })
      );

      return noteAndLinks.sort((a, b) => b.note.modified.getTime() - a.note.modified.getTime());
    })
  );

  openDocumentDialog(claim: Claim.Model, document: StorageItem.Model) {
    this.documentDialog?.open({ claim, document });
  }

  getItemTheme(item?: Claim.ClaimItem.Model) {
    return item ? Claim.ClaimItem.getTheme(item.status) : 'default';
  }

  ngOnInit(): void {
    this.linkSvc.refreshActivity$.next(null);
  }
}
