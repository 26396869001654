import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { LinksWebService } from '@wsphere/links/web';
import { Coverage, Warranty } from '@wsphere/warranties/domain';
import { ClaimService } from '@wsphere/warranties/web';
import { map } from 'rxjs';

@Component({
  selector: 'ws-new-claim-page',
  templateUrl: './new-claim.page.html',
})
export class NewClaimPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configSvc: PortalService,
    private claimSvc: ClaimService,
    private formBuilder: FormBuilder,
    private toastSvc: ToastService,
    private linkSvc: LinksWebService
  ) {}

  protected claimFormGroup = this.formBuilder.group({
    complaint: [null as string | null, Validators.required],
    coverages: [[] as (Coverage.Model | undefined)[]],
    description: [null as string | null, Validators.required],
    evidence: [[] as File[]],
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  protected readonly warranty$ = this.route.parent!.data.pipe(map(data => data['warranty'] as Warranty.Model));
  protected readonly coverageId$ = this.route.queryParams.pipe(map(data => data['coverage'] as string));

  protected readonly config = this.configSvc.config;

  protected step = 0;

  protected lastStep = 5;

  protected nextPage(warrantyId?: string) {
    if (this.step >= this.lastStep) return;

    this.step++;

    if (this.step === this.lastStep && warrantyId) this.openClaim(warrantyId);

    if (this.step > this.lastStep) this.step = this.lastStep;
  }

  protected async previousPage() {
    if (this.step <= 0) {
      this.clear();
      return;
    }

    this.step--;

    if (this.step < 0) this.step = 0;
  }

  protected clear() {
    this.step = 0;
  }

  checkForOther() {
    const coverages = this.claimFormGroup.value.coverages;
    if (coverages && coverages.length) return coverages.includes(undefined);
    return false;
  }

  protected async openClaim(warrantyId: string) {
    if (this.claimFormGroup.value.complaint) {
      const claim = await this.claimSvc
        .open({
          warrantyId: warrantyId,
          complaint: this.claimFormGroup.value.complaint,
          items: this.claimFormGroup.value.coverages?.map(coverage =>
            coverage?.id
              ? { coverageId: coverage.id }
              : { coverageId: null, description: this.claimFormGroup.value.description }
          ),
        })
        .catch(err => {
          this.toastSvc.show({
            type: 'error',
            title: 'Failure',
            text: `The claim could not be created. Reason: ${err?.error?.message}! `,
          });
        });

      if (claim) {
        this.toastSvc.show({
          type: 'success',
          title: 'Success',
          text: 'A new claim has been created.',
        });
        if (this.claimFormGroup.controls.evidence.value?.length) await this.uploadFiles(claim.id);
        await this.router.navigate(['../', claim?.id], { relativeTo: this.route });
      }
    }
  }

  async uploadFiles(_claimId: string) {
    const success = [];
    const error = [];

    if (this.claimFormGroup.controls.evidence.value) {
      for (let i = 0; i < this.claimFormGroup.controls.evidence.value.length; i++) {
        const file = this.claimFormGroup.controls.evidence.value[i];

        const claimEvidence = await this.claimSvc
          .createDocument(_claimId, {
            file,
            title: file.name ?? `file ${i}`,
            feed: ['staff', 'customer'],
          })
          .catch(err => {
            error.push(err);
            return null;
          });

        if (claimEvidence) {
          success.push(claimEvidence);
        }
      }
    }

    if (success.length && error.length) {
      this.toastSvc.show({
        type: 'warning',
        title: 'Files upload',
        text: 'Some of the files failed to upload, please review your claim attachments',
      });
    } else {
      if (success.length) {
        this.toastSvc.show({
          type: 'success',
          title: 'Files upload',
          text: 'All files have been successfully submitted for upload',
        });
      }

      if (error.length) {
        this.toastSvc.show({
          type: 'error',
          title: 'Files upload',
          text: 'Some of the files have failed to upload please review your claim attachments',
        });
      }
    }
  }
}
