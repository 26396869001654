import { AfterViewInit, Component, inject } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { environment } from '../environments/environment';

declare const tf: { reload(): unknown };

@Component({ selector: 'wsphere-root', template: '<router-outlet></router-outlet>' })
export class AppComponent implements AfterViewInit {
  private portalSvc = inject(PortalService);

  ngAfterViewInit() {
    if (environment.displayHelpForm && this.portalSvc.config?.chatBotTypeFormId) {
      document
        .querySelector('#customer-support-tf')
        ?.setAttribute('data-tf-popover', this.portalSvc.config.chatBotTypeFormId);

      tf?.reload();
    }
  }
}
