import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { ClaimService } from '@wsphere/warranties/web';
import { ClaimResolver } from '../../resolver';

@Injectable()
export class ClaimGuard implements CanActivate {
  constructor(private resolver: ClaimResolver, private claimSvc: ClaimService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.claim = null;

    const claimId = this.findClaimId(route);
    if (!claimId) return false;

    this.resolver.claim = await this.claimSvc.get(claimId);
    if (!this.resolver.claim) return false;

    return true;
  }

  private findClaimId(route: ActivatedRouteSnapshot): string {
    return route.params['claimId'] || (route.parent ? this.findClaimId(route.parent) : null);
  }
}
