<ng-container *vsLet="coverageUpdate$ | async"></ng-container>

<div *vsLet="groupsAndCoverages$ | async as groups" class="flex flex-col gap-2 lg:w-[50%]">
  <ng-container *vsLet="other$ | async as other">
    <ng-container *ngIf="!selectedGroup && !onOther">
      <div
        *ngFor="let group of groups"
        class="flex justify-between items-center p-4 bg-base hover:bg-primary-50 border hover:border-primary-300 rounded-lg transition-all cursor-pointer"
        (click)="selectedGroup = group.coverages"
      >
        <div class="flex gap-2">
          <p class="font-medium text-gray-900 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {{ group.group || 'No Group' }}
          </p>

          <vs-badge *ngIf="selectedGroupCount(group.group) as count"> {{ count }} selected </vs-badge>
        </div>

        <vs-icon name="chevron-right"></vs-icon>
      </div>

      <div
        class="flex justify-between items-center p-4 bg-base hover:bg-primary-50 border hover:border-primary-300 rounded-lg transition-all cursor-pointer"
        (click)="onOther = true"
      >
        <p
          class="flex font-medium text-gray-900 gap-2 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap"
        >
          Other
          <vs-badge *ngIf="other" class="self-center" theme="success">
            <vs-icon name="check" class="text-themed-500"></vs-icon> selected
          </vs-badge>
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedGroup">
      <div class="flex flex-col gap-2">
        <div class="flex gap-2 items-center px-4 py-2 transition-all cursor-pointer" (click)="selectedGroup = null">
          <vs-icon name="chevron-left" (click)="selectedGroup = null"></vs-icon>
          <p class="text-sm font-semibold text-gray-700">
            {{ selectedGroup[0].coverage.group }}
          </p>
        </div>

        <div
          *ngFor="let coverage of selectedGroup"
          class="flex gap-2 items-center p-4 bg-base hover:bg-primary-50 border hover:border-primary-300 rounded-lg transition-all cursor-pointer"
          (click)="selectValueChanged(coverage.coverage, coverage.included)"
        >
          <input vsInput type="checkbox" [checked]="checkSelected(coverage.coverage.id)" />
          <p
            class="text-sm font-medium text-gray-700 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            {{ coverage.coverage.title }}
          </p>

          <vs-badge [theme]="coverage.included ? 'success' : 'danger'">
            {{ coverage.included ? 'Included' : 'Excluded' }}
          </vs-badge>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="onOther">
      <div class="flex gap-2 items-center px-4 py-2 transition-all cursor-pointer" (click)="onOther = false">
        <vs-icon name="chevron-left"></vs-icon>
        <p class="text-sm font-semibold text-gray-700">Other</p>
      </div>
    </ng-container>
  </ng-container>
</div>
