<div *vsLet="isPastWaitingPeriod$ | async as isPastWaitingPeriod">
  <vs-content-card
    *vsLet="warranty$ | async as warranty"
    [header]="{ title: 'Claims', subtitle: 'Filed claims, their status, and next steps' }"
  >
    <div
      *ngIf="canOpenClaim$ | async"
      headerEnd
      matTooltipPosition="right"
      [vsTooltip]="
        remainingPeriod > 0 && isPastWaitingPeriod === false
          ? 'Remaining ' + remainingPeriod + ' ' + period + ' of the waiting period.'
          : ''
      "
      tooltipStyle="light"
    >
      <button
        vs-button
        theme="primary"
        slot="header-end"
        routerLink="claim/open"
        [disabled]="isPastWaitingPeriod === false"
      >
        <vs-icon name="file-plus-02"></vs-icon> Open a new claim
      </button>
    </div>

    <ws-claims-table
      [hideBorder]="true"
      [hideFilters]="true"
      [hideCustomer]="true"
      [isCustomerPortal]="true"
      [warranty]="warranty ?? null"
      [customerId]="warranty?.customerId"
      [isPastWaitingPeriod]="isPastWaitingPeriod"
      [remainingPeriod]="remainingPeriod"
      [period]="period"
    >
      <div
        *ngIf="warranty?.policy?.customerPortalConfig?.claimsPlaceholder"
        slot="placeholder"
        class="py-8 px-4 sm:px-6"
        [innerHTML]="(claimsPlaceholder$ | async) ?? '' | safe : 'html'"
      ></div>
    </ws-claims-table>
  </vs-content-card>
</div>
