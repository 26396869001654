import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { StatusTypes } from '@wsphere/customer-portal/web';
import { CustomerService } from '@wsphere/customers/web';
import { firstValueFrom } from 'rxjs';

@Component({ templateUrl: './login.page.html' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LoginPage implements OnInit {
  readonly statusTypes = StatusTypes;
  constructor(private firebaseSvc: FirebaseService, private customerSvc: CustomerService, private router: Router) {}
  private portalSvc = inject(PortalService);
  errors = false;
  redirect: string | null = null;

  async ngOnInit() {
    const signedIn = await firstValueFrom(this.firebaseSvc.isSignedIn$());
    if (signedIn) {
      await this.router.navigateByUrl('../');
    }
  }

  handleSignInError(error: string) {
    this.errors = !!error;
  }
}
