<vs-card *ngIf="payment" class="h-full">
  <vs-card-content class="flex flex-col gap-1">
    <div class="flex gap-2 lg:flex-nowrap flex-wrap">
      <p class="text-4xl font-medium text-gray-600">{{ payment.amount / 100 | currency }}</p>
      <vs-badge *ngIf="destinations?.length" class="max-h-[20px] self-center" [theme]="getBadgeTheme()">
        {{ payment.destination === 'PENDING' ? 'Pending bank details' : (payment.status | titlecase) }}
      </vs-badge>

      <vs-badge *ngIf="!destinations?.length" class="max-h-[20px] self-center" theme="warn">
        Pending bank details
      </vs-badge>
    </div>

    <div class="flex flex-col">
      <p class="text-lg font-medium text-gray-600 truncate">{{ payment.title }}</p>
      <p
        class="text-sm font-normal text-gray-600 min-h-[40px] line-clamp-2 overflow-ellipsis"
        tooltipStyle="light"
        [vsTooltip]="{ title: payment.title, subtitle: payment.description || '' }"
      >
        {{ payment.description }}
      </p>
    </div>

    <div *ngIf="!destinations?.length" class="flex flex-end justify-end w-full pt-2">
      <button vs-button theme="primary" class="flex-grow lg:flex-grow-0" (click)="navigateTo()">
        <vs-icon name="plus"></vs-icon>
        Add bank details
      </button>
    </div>
  </vs-card-content>
</vs-card>
