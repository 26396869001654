<ng-container *vsLet="close$ | async">
  <vs-dialog
    *vsLet="isMobile$ | async as isMobile"
    #dialog
    [config]="{ dialogContainerClass: 'w-screen h-screen !rounded-none !p-0 bg-base-light !max-h-full', startOpen: true, contentContainerClass: 'h-full' }"
    (closed)="navigateTo()"
  >
    <ng-container *vsLet="session$ | async as session">
      <ng-container *vsLet="sessionComplete$ | async as sessionComplete">
        <div *ngIf="step$ | async as step" class="h-full" [class.flex]="!isMobile">
          <div class="flex flex-col flex-grow items-center gap-14 w-full h-full pb-16 overflow-y-auto no-scrollbar">
            <div *ngIf="!isMobile" class="sticky top-0 z-50 pt-14 bg-base-light w-full px-6">
              <div class="flex gap-6 items-start">
                <button
                  *ngIf="step === checkoutSteps.Plan"
                  vs-button
                  appearance="clear"
                  theme="primary"
                  (click)="navigateTo('/checkout/asset')"
                >
                  <vs-icon name="arrow-left"></vs-icon>
                  Back
                </button>

                <vs-stepper
                  *ngIf="steps$ | async as steps"
                  [config]="{ steps: steps, mode: 'horizontal_navigation', activeStep: step }"
                  theme="primary"
                  class="flex-grow"
                  (stepChanged)="step$.next($event);"
                ></vs-stepper>

                <button
                  *ngIf="step === checkoutSteps.Plan"
                  vs-button
                  theme="danger"
                  (click)="summary?.confirmCancellationDialog?.open()"
                >
                  <vs-icon name="x-close"></vs-icon>
                  Cancel purchase
                </button>
              </div>
            </div>

            <router-outlet></router-outlet>
          </div>

          <div
            *ngIf="!sessionComplete"
            [class.!w-0]="!isMobile && step === checkoutSteps.Plan || sessionComplete"
            [class]="!isMobile ? 'w-[360px] transition-all duration-500' : 'w-full'"
          >
            <ws-order-summary
              [class.!hidden]="!isMobile && step === checkoutSteps.Plan || sessionComplete"
              [session]="session"
              [_preview]="preview$ | async"
              [coverages]="coverages$ | async"
              [addons]="selectedAddons$ | async"
              [currentStep]="step"
              [loading]="loadingPreview$ | async"
              [state]="isMobile ? 'MOBILE' : 'DESKTOP'"
              (closeDialog)="dialog.close()"
              (updatePreview)="updatePreview($event)"
            ></ws-order-summary>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </vs-dialog>
</ng-container>
