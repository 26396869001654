/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationQueryRequest } from '@vsolv/dev-kit/ngx';
import { Payment } from '@vsolv/packages/payments/domain';
import { catchError, firstValueFrom, of } from 'rxjs';

const api = `/api/payments`;
@Injectable()
export class PaymentsCustomerPortalService {
  constructor(private http: HttpClient) {}

  getOne(paymentId: string) {
    return firstValueFrom(
      this.http.get<Payment.Model>(`${api}/${paymentId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  getFailed() {
    return firstValueFrom(this.http.get<Payment.Model | null>(`${api}/failed`));
  }

  getList(pagination?: PaginationQueryRequest) {
    return firstValueFrom(
      this.http.get<Payment.ListResponse>(`${api}`, {
        params: {
          page: pagination?.page || 1,
          limit: pagination?.limit || 20,
        },
      })
    );
  }

  getListRelated(dto: Payment.ListRelatedRequest) {
    return firstValueFrom(
      this.http.get<Payment.ListResponse>(`${api}/related`, {
        params: {
          page: dto.page || 1,
          limit: dto.limit || 20,

          referenceId: dto.referenceId,
          ...(dto.excludeId && { excludeId: dto.excludeId }),
          ...(dto.ascendingOrder && { ascendingOrder: dto.ascendingOrder }),
        },
      })
    );
  }
}
