<div class="flex flex-col gap-4">
  <div>
    <h1 class="mr-2 inline-block text-3xl text-base-dark font-bold">Hi {{customer?.name}},</h1>
    <h3 class="inline-block text-xl italic text-gray-600 font-bold">how can we help you today?</h3>
  </div>

  <div
    *ngIf="failedPayment$ | async as failedPayment"
    class="rounded-xl border border-danger-300 relative overflow-hidden px-3 py-2 bg-danger-50"
  >
    <div class="grid grid-cols-[auto_1fr] gap-3">
      <div class="w-10 min-w-[40px] h-10 flex items-center justify-center bg-base rounded-lg border border-danger-200">
        <vs-icon name="alert-triangle" size="20" class="text-danger-700"></vs-icon>
      </div>

      <div>
        <p class="font-semibold text-danger-700">Action required: payment failed.</p>
        <p class="text-sm font-normal text-danger-700">
          There has been an issue processing your payment for warranty {{failedPayment.referenceId}}.
        </p>

        <div class="mt-3 flex flex-wrap items-center gap-2">
          <vs-input-group>
            <a vs-button appearance="outline" [routerLink]="[failedPayment.id]">Manage payment</a>
            <div
              vsTooltip="The payment method we have on file may not be correct. Please update your billing information. Failure to provide the required information will result in cancellation of your warranty."
            >
              <button vs-icon-button appearance="outline" class="pointer-events-none rounded-tl-none rounded-bl-none">
                <vs-icon name="message-question-circle" size="20"></vs-icon>
              </button>
            </div>
          </vs-input-group>
        </div>
      </div>
    </div>
  </div>

  <div class="rounded-xl overflow-auto border border-gray-200 shadow-sm">
    <ng-container *vsLet="page$ | async as page">
      <vs-table [columns]="columns" [data]="page?.items" (rowClicked)="rowClicked($event.id)"></vs-table>
      <vs-pagination
        [config]="page?.meta"
        (pagination)="pagination$.next({ page: $event.currentPage, limit: $event.itemsPerPage })"
      ></vs-pagination>
    </ng-container>
  </div>
</div>
