import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { WarrantyCustomerPortalService } from '../../services';

@Injectable()
export class OpenClaimGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private warrantySvc: WarrantyCustomerPortalService,
    private toastSvc: ToastService
  ) {}
  async canActivate(route: ActivatedRouteSnapshot) {
    const contractNumber = this.extractContractNumber(route);
    if (!contractNumber) return false;

    try {
      const warranty = await this.warrantySvc.getOneByContractNumber(contractNumber);
      if (!warranty) {
        throw new Error('Not Found');
      }

      if (
        (warranty.status === Warranty.Status.REGISTERED && warranty.activationDeadline.getTime() < Date.now()) ||
        warranty.status !== Warranty.Status.ACTIVATED
      ) {
        throw new Error('This warranty is not active');
      }

      if (warranty && !this.isPastWaitingPeriod(warranty)) {
        throw new Error('Unable to open a claim during the waiting period');
      }

      return true;
    } catch (error) {
      console.log(error);
      this.toastSvc.show({
        type: 'error',
        title: 'Failure',
        text: `The claim could not be created. Reason: ${(error as Error)?.message}! `,
      });
      this.router.navigate([`warranty/${this.extractContractNumber(route)}`], {
        relativeTo: this.route,
      });
    }
    return false;
  }

  private extractTenantId(route: ActivatedRouteSnapshot): string {
    return route.params['tenantId'] || (route.parent ? this.extractTenantId(route.parent) : null);
  }

  private extractContractNumber(route: ActivatedRouteSnapshot): string {
    return route.params['contractNumber'] || (route.parent ? this.extractContractNumber(route.parent) : null);
  }

  isPastWaitingPeriod(warranty: Warranty.Model) {
    const waitingPeriodEndDate = moment(warranty?.activationDate)
      .add(warranty.policy?.claimWaitingPeriod, 'days')
      .toDate();
    if (moment().isBefore(waitingPeriodEndDate)) {
      return false;
    } else {
      return true;
    }
  }
}
