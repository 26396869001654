<div *vsLet="isMobile$ | async as isMobile" class="flex flex-col gap-4 -mr-1 w-full overflow-hidden">
  <ng-container *vsLet="items$ | async as items">
    <div *vsLet="isMobile ? 100 : 50 as cardSize" class="flex gap-4 w-full mr-1 pb-1" [class.flex-wrap]="!isMobile">
      <ng-container *ngIf="items?.length; else noItems">
        <ws-claim-coverage-card
          *ngFor="let item of items; index as idx"
          class="transition-all duration-300 ease-in-out"
          [class.min-w-full]="isMobile"
          [class.max-w-full]="isMobile"
          [style]="
            (items?.length || 0) === 1
              ? 'min-width: 100%'
              : !isMobile
              ? 'min-width: calc(50% - 8px); max-width: calc(50% - 8px)'
              : ''
          "
          [item]="item"
          [style.margin-left]="idx === 0 ? 'calc(-' + (cardSize || 0) * index + '% - ' + 16 * index + 'px)' : ''"
        ></ws-claim-coverage-card>
      </ng-container>
    </div>

    <ng-template #noItems>
      <vs-icon-empty-state
        name="file-plus-03"
        [config]="{
          title: 'No claim items have been added yet.',
          subtitle: 'Your administrator will add them shortly.'
        }"
      ></vs-icon-empty-state>
    </ng-template>

    <div *ngIf="items?.length && isMobile" class="flex justify-between px-1 pb-1">
      <button
        vs-icon-button
        theme="primary"
        appearance="clear"
        class="min-w-[40px] !rounded-full !bg-primary-50"
        [disabled]="index === 0"
        (click)="index = index - 1"
      >
        <vs-icon name="chevron-left"></vs-icon>
      </button>

      <div class="flex gap-1 self-center">
        <vs-icon
          *ngFor="let item of items; index as idx"
          size="12"
          type="solid"
          name="circle"
          class="cursor-pointer"
          [class.text-primary-500]="index === idx"
          [class.text-primary-200]="index !== idx"
          (click)="index = idx"
        >
        </vs-icon>
      </div>

      <button
        vs-icon-button
        theme="primary"
        appearance="clear"
        class="min-w-[40px] !rounded-full !bg-primary-50"
        [disabled]="index === (items?.length || 0) - 1"
        (click)="index = index + 1"
      >
        <vs-icon name="chevron-right"></vs-icon>
      </button>
    </div>
  </ng-container>
</div>
