<vs-card *vsLet="attachments$ | async as attachments">
  <vs-card-header
    [config]="{
      title: 'Attachments',
      subtitle: 'Review ' + (claim?.status === open ? 'and upload' : '') + ' your attachments for your claim.',
      mobileWrap: true
    }"
  >
    <button
      *ngIf="attachments?.length && claim?.status === open"
      vs-button
      slot="end"
      appearance="outline"
      class="flex-grow lg:flex-grow-0"
      (click)="openUploadDialog()"
    >
      <vs-icon name="plus"></vs-icon>
      Add Attachment
    </button>
  </vs-card-header>

  <vs-card-content *ngIf="attachments?.length">
    <div *vsLet="isMobile$ | async as isMobile" class="flex flex-col gap-4 w-full overflow-hidden">
      <div class="flex gap-4 w-full px-1 pb-1 overflow-hidden" [class.flex-wrap]="!isMobile">
        <ws-claim-document-preview
          *ngFor="let document of attachments; index as idx"
          size="lg"
          class="cursor-pointer shadow rounded-lg transition-all duration-300 ease-in-out"
          [document]="document"
          [style.margin-left]="idx === 0 ? '-' + 160 * index + 'px' : ''"
          (click)="openViewDialog(document)"
        >
        </ws-claim-document-preview>

        <div
          *ngIf="claim?.status === open"
          class="flex flex-col justify-center gap-2 h-36 w-36 text-center p-4 border rounded-lg shadow-sm cursor-pointer hover:text-primary-500 hover:border-primary-500 hover:border-2 hover:shadow-md transition-all"
          (click)="openUploadDialog()"
        >
          <vs-icon size="32" name="plus" class="self-center"></vs-icon>
          <p class="text-sm font-semibold text-gray-700 whitespace-nowrap">Add attachment</p>
        </div>
      </div>

      <div *ngIf="isMobile" class="flex justify-between">
        <button
          vs-icon-button
          theme="primary"
          appearance="clear"
          class="min-w-[40px] !rounded-full !bg-primary-50"
          [disabled]="index === 0"
          (click)="index = index - 1"
        >
          <vs-icon name="chevron-left"></vs-icon>
        </button>

        <div class="flex gap-1 self-center">
          <vs-icon
            *ngFor="let item of attachments; index as idx"
            size="12"
            type="solid"
            name="circle"
            class="cursor-pointer"
            [class.text-primary-500]="index === idx"
            [class.text-primary-200]="index !== idx"
            (click)="index = idx"
          >
          </vs-icon>
        </div>

        <button
          vs-icon-button
          theme="primary"
          appearance="clear"
          class="min-w-[40px] !rounded-full !bg-primary-50"
          [disabled]="index === (attachments?.length || 0) - 1"
          (click)="index = index + 1"
        >
          <vs-icon name="chevron-right"></vs-icon>
        </button>
      </div>
    </div>
  </vs-card-content>

  <vs-card-content *ngIf="!attachments?.length" class="!pt-0 overflow-hidden">
    <vs-icon-empty-state
      name="image-up"
      [config]="{
        title: 'No attachments have been added yet.',
        subtitle:
          claim?.status === open
            ? 'Please upload any attachments that would help us adjudicate your claim.'
            : 'Any documents that were uploaded for this claim will be displayed here.'
      }"
    >
      <button
        *ngIf="claim?.status === open"
        vs-button
        slot="button"
        theme="primary"
        class="self-center"
        (click)="openUploadDialog()"
      >
        <vs-icon name="plus"></vs-icon> Add Attachment
      </button>
    </vs-icon-empty-state>
  </vs-card-content>
</vs-card>

<ws-view-claim-document-dialog
  #viewDialog
  [isCustomerPortal]="true"
  (updated)="refresh()"
></ws-view-claim-document-dialog>

<ws-upload-claim-document-dialog
  #uploadDialog
  [isCustomerPortal]="true"
  (uploaded)="refresh()"
></ws-upload-claim-document-dialog>
