import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { CreateCustomerOptions, FundingSource } from '@vsolv/dwolla/domain';
import { DwollaService, ManageBankingDetailsDialogState } from '@vsolv/packages/reimbursement/web';
import { GenericModalComponent } from '@vsolv/ui-kit/modal';
import { Observable, ReplaySubject, switchMap, tap } from 'rxjs';

@Component({
  selector: 'ws-add-banking-details-modal',
  templateUrl: './add-banking-details.modal.html',
})
export class AddBankingDetailsModal {
  @ViewChild(GenericModalComponent) modal?: GenericModalComponent;

  private dwollaSvc = inject(DwollaService);

  @Output() failed = new EventEmitter<unknown>();
  @Output() succeeded = new EventEmitter<{ fundingSourceId: string }>();

  @PropertyListener('customer')
  private customer$ = new ReplaySubject<CreateCustomerOptions>(1);
  @Input() customer?: CreateCustomerOptions | null;

  customerId$ = this.customer$.pipe(
    switchMap(async customer => (customer ? await this.dwollaSvc.findCustomer() : null))
  );

  state!: ManageBankingDetailsDialogState;

  fundingSources$: Observable<FundingSource[] | null> = this.customer$.pipe(
    switchMap(async customer => (customer ? this.dwollaSvc.listCustomerFundingSource(customer.email) : null)),
    tap(fundingSources => this.changeState(fundingSources && fundingSources.length ? 'manage' : 'add'))
  );

  changeState(state: ManageBankingDetailsDialogState) {
    this.state = state;
  }

  open() {
    this.modal?.open();
  }

  confirmBankDetails({ fundingSourceId }: { fundingSourceId: string }) {
    this.succeeded.emit({ fundingSourceId });
    setTimeout(() => {
      this.close();
      window.location.reload();
    }, 3000);
  }

  close() {
    this.modal?.close();
  }

  toggle() {
    this.modal?.toggle();
  }
}
