import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ONE_TIME_TOKEN } from '@vsolv/core/users/domain';
import { UserStorageService } from '@vsolv/core/users/web';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { firstValueFrom } from 'rxjs';
import { CustomersCustomerPortalService } from '../../../services';
import { SignInErrorTypes } from '../../welcome-header/welcome-header.component';

@Component({
  selector: 'ws-enter-email-card',
  templateUrl: './enter-email-card.component.html',
})
export class EnterEmailCardComponent implements OnInit {
  constructor(
    public portalSvc: PortalService,
    private route: ActivatedRoute,
    private router: Router,
    private userStorageSvc: UserStorageService,
    private firebaseSvc: FirebaseService,
    private customerSvc: CustomersCustomerPortalService,
    private toastSvc: ToastService
  ) {}

  formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  sent = false;
  oneTimeToken?: string;
  emailLinkSignInTenantId?: string;
  redirect: string | null = null;

  signedIn = new EventEmitter();
  loading = true;
  canSendTimeOut = 0;

  @Output()
  signInError = new EventEmitter<string>();

  inputIsValid() {
    return !this.formGroup.invalid;
  }

  async submit(resend = false) {
    if (this.canSendTimeOut <= 0) {
      const email: string | null | undefined = this.formGroup.get('email')?.value;
      if (!email) return;

      if (this.oneTimeToken || this.emailLinkSignInTenantId) {
        let result = undefined;

        if (this.oneTimeToken) {
          result = await this.oneTimeTokenSignIn(email, this.oneTimeToken);
        } else if (this.emailLinkSignInTenantId) {
          result = await this.passwordlessSignIn(this.emailLinkSignInTenantId, email);
        }

        if (result) {
          await this.signIn(result);
          return;
        } else {
          this.oneTimeToken = undefined;
          this.emailLinkSignInTenantId = undefined;
          await this.router.navigate([], { relativeTo: this.route });
        }
      } else {
        const customerExists = await this.customerSvc.checkIfCustomerExists(email);

        if (customerExists) {
          this.sent = true;

          if (resend) {
            this.canSendTimeOut = 60;
            const sendTimeout = setInterval(() => {
              this.canSendTimeOut--;
              if (this.canSendTimeOut <= 0) {
                this.canSendTimeOut = 0;
                clearInterval(sendTimeout);
              }
            }, 1000);
          }
        } else {
          await this.router.navigate(['checkout'], { queryParams: { email } });
          return;
        }

        await this.firebaseSvc.generateSignInLink(email, this.redirect || undefined);
      }
    }
  }

  reset() {
    this.sent = false;
    this.formGroup.reset();
  }

  async ngOnInit() {
    const signedInUser = await firstValueFrom(this.firebaseSvc.authUser$);
    const params = await firstValueFrom(this.route.queryParamMap);
    this.redirect = params.get('redirect');

    const email = params.get('email');
    if (email) {
      this.formGroup.setValue({ email });
    }

    if (signedInUser?.email) {
      const currentFirebaseTenantId = (await this.firebaseSvc.getCurrentFirebaseTenant())?.firebaseTenantId;
      if (signedInUser.tenantId !== currentFirebaseTenantId) {
        await this.firebaseSvc.signOut();
      } else {
        await this.signIn(signedInUser.email);
        return;
      }
    }

    const apiKey = params.get('apiKey');
    const oobCode = params.get('oobCode');
    const mode = params.get('mode');
    const tenantId = params.get('tenantId');
    if (apiKey && oobCode && mode && tenantId) {
      //these parameters signify that it is an email link sign in
      const result = await this.passwordlessSignIn(tenantId);
      if (result) {
        await this.signIn(result);
        return;
      }
    } else {
      this.oneTimeToken = params.get(ONE_TIME_TOKEN) || undefined;
      const email = params.get('email');
      const autoSend = params.get('autosend');
      if (email && this.oneTimeToken) {
        try {
          const result = await this.oneTimeTokenSignIn(email, this.oneTimeToken);
          if (result) {
            await this.signIn(result);
            return;
          }
        } catch (err) {
          this.showInvalidLinkMessage();
          this.formGroup.setValue({ email });
          this.oneTimeToken = undefined;
          console.error(err);
        }
      } else if (email && (autoSend === 'true' || autoSend === '1')) {
        this.formGroup.setValue({ email });
        this.sent = true;
        await this.firebaseSvc.generateSignInLink(email, this.redirect || undefined);
      }
    }
    this.loading = false;
  }

  private showInvalidLinkMessage() {
    this.toastSvc.show({
      type: 'error',
      title: 'Invalid sign in link',
      text: `Looks like that sign in link has already been used!\nPlease try again by entering your email`,
    });
  }

  private async passwordlessSignIn(tenantId: string, email?: string) {
    if (!email) email = this.userStorageSvc.getEmail() || undefined;
    if (!email) {
      this.emailLinkSignInTenantId = tenantId;
      return;
    }

    try {
      const user = await this.firebaseSvc.passwordlessSignIn(tenantId, email);
      if (user) {
        this.signInError.emit('');
        return user.user.email;
      }
    } /* eslint-disable  @typescript-eslint/no-explicit-any */ catch (err: any) {
      if (err?.code && Object.values(SignInErrorTypes).includes(err.code)) {
        this.signInError.emit(err.code);
      }
    }
    return null;
  }

  private async oneTimeTokenSignIn(email: string, token: string): Promise<string | undefined> {
    const signInToken = await this.firebaseSvc.exchangeToken(email, token);
    if (!signInToken) {
      await this.router.navigate([], { relativeTo: this.route, queryParams: [] });
      return undefined;
    } else {
      const user = await this.firebaseSvc.customTokenSignIn(signInToken.signInToken, signInToken.tenantId);
      return user?.user.email || undefined;
    }
  }

  private async signIn(email: string) {
    this.signedIn.emit(email);
    if (this.redirect) {
      await this.router.navigateByUrl(this.redirect || '');
    } else {
      await this.router.navigateByUrl('../');
    }
  }
}
