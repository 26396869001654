import { ApiMode } from '@vsolv/core/multi-tenant/domain';
import { apiMode } from './api-mode';

export const environment = {
  apiMode,
  dwollaMode: 'sandbox' as 'prod' | 'sandbox',
  displayHelpForm: false,
  production: true,
  apiBaseUrl: 'https://api.vsolv.app',
  firebase:
    apiMode === ApiMode.LIVE
      ? {
          apiKey: 'AIzaSyB8L6FGrbKqmhaF0ZMqd5hXFNjy69HlvqU',
          authDomain: 'vectorsolv-dev.firebaseapp.com',
          databaseURL: 'https://vectorsolv-dev.firebaseio.com',
          projectId: 'vectorsolv-dev',
          storageBucket: 'vectorsolv-dev.appspot.com',
          messagingSenderId: '506696867165',
          appId: '1:506696867165:web:febf2bc3ffe42aeedb9cb4',
          measurementId: 'G-46DMXSL0C2',
        }
      : {
          apiKey: 'AIzaSyB8L6FGrbKqmhaF0ZMqd5hXFNjy69HlvqU',
          authDomain: 'vectorsolv-dev.firebaseapp.com',
          databaseURL: 'https://vectorsolv-dev.firebaseio.com',
          projectId: 'vectorsolv-dev',
          storageBucket: 'vectorsolv-dev.appspot.com',
          messagingSenderId: '506696867165',
          appId: '1:506696867165:web:a1749c98d7afa909db9cb4',
          measurementId: 'G-T12GGTZ36K',
        },
  googleMapsKey: 'AIzaSyA3sERb1ZRoz8apia6GHqWmUAGXbWdYh2Q',
};
