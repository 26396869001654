<ng-container *vsLet="destinations$ | async as destinations">
  <vs-card *vsLet="payments$ | async as payments">
    <vs-card-header
      [config]="{
        mobileWrap: true,
        title: 'Reimbursements',
        subtitle:
          'Review reimbursements' +
          (claim?.status !== expired ? 'and add your billing details' : '') +
          ' for your claim.'
      }"
    >
      <button
        *ngIf="payments?.length && claim?.status !== expired"
        vs-button
        slot="end"
        appearance="outline"
        class="flex-grow lg:flex-grow-0"
        (click)="navigateTo()"
      >
        <vs-icon name="plus"></vs-icon>
        {{ destinations?.length ? 'Manage' : 'Add' }} bank details
      </button>
    </vs-card-header>

    <vs-card-content *ngIf="payments?.length">
      <div *vsLet="isMobile$ | async as isMobile" class="flex flex-col gap-4 w-full overflow-hidden">
        <div *vsLet="isMobile ? 100 : 50 as cardSize" class="flex gap-4 w-full px-1 pb-1 overflow-hidden">
          <ws-claim-reimbursement-card
            *ngFor="let payment of payments; index as idx"
            class="cursor-pointer transition-all duration-300 ease-in-out"
            [payment]="payment"
            [destinations]="destinations"
            [style]="'min-width: calc(' + cardSize + '% - 8px); max-width: calc(' + cardSize + '% - 8px)'"
            [style.margin-left]="idx === 0 ? 'calc(-' + (cardSize || 0) * index + '% - ' + 8 * index + 'px)' : ''"
          >
          </ws-claim-reimbursement-card>
        </div>

        <div *ngIf="isMobile" class="flex justify-between">
          <button
            vs-icon-button
            theme="primary"
            appearance="clear"
            class="min-w-[40px] !rounded-full !bg-primary-50"
            [disabled]="index === 0"
            (click)="index = index - 1"
          >
            <vs-icon name="chevron-left"></vs-icon>
          </button>

          <div class="flex gap-1 self-center">
            <vs-icon
              *ngFor="let item of payments; index as idx"
              size="12"
              type="solid"
              name="circle"
              class="cursor-pointer"
              [class.text-primary-500]="index === idx"
              [class.text-primary-200]="index !== idx"
              (click)="index = idx"
            >
            </vs-icon>
          </div>

          <button
            vs-icon-button
            theme="primary"
            appearance="clear"
            class="min-w-[40px] !rounded-full !bg-primary-50"
            [disabled]="index === (payments?.length || 0) - 1"
            (click)="index = index + 1"
          >
            <vs-icon name="chevron-right"></vs-icon>
          </button>
        </div>
      </div>
    </vs-card-content>

    <vs-card-content *ngIf="!payments?.length" class="!pt-0 overflow-hidden">
      <vs-icon-empty-state
        name="bank-note-02"
        [config]="{
          title: 'No reimbursements have been issued yet.',
          subtitle:
            claim?.status !== expired
              ? 'We haven\'t issued any reimbursements yet. Please ' +
                (destinations?.length ? 'manage' : 'add') +
                ' your billing details now to streamline the reimbursement process in the case that your claim is approved.'
              : 'Any reimbursements that were issued for your claim will be displayed here.'
        }"
      >
        <button
          *ngIf="claim?.status !== expired"
          slot="button"
          vs-button
          theme="primary"
          class="self-center"
          (click)="navigateTo()"
        >
          <vs-icon name="plus"></vs-icon> {{ destinations?.length ? 'Manage' : 'Add' }} bank details
        </button>
      </vs-icon-empty-state>
    </vs-card-content>
  </vs-card>
</ng-container>
