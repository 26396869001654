<ng-container *ngIf="warranty">
  <vs-content-card
    [header]="{
      title: (warranty.policy?.friendlyTitle || warranty.policy?.title) + ' - ' + warranty.plan?.title,
      subtitle: warranty.policy?.tagline
    }"
  >
    <ws-warranty-status-drop-down
      headerEnd
      [warrantyStatus]="warranty.status"
      [asset]="warranty.asset"
      [propertySetId]="warranty.policy?.propertySetId"
      [warrantyValidity]="warranty.policy?.validity"
      [transactionCloseDate]="warranty.transactionCloseDate || undefined"
      [warrantyTermDuration]="warranty.termDuration"
      [warrantyStartDate]="warranty.termStart"
      [expiryDate]="warranty.expiryDate || undefined"
    ></ws-warranty-status-drop-down>

    <ng-container *ngIf="warranty.status !== 'ACTIVATED'">
      <div class="p-5 pb-0">
        <div class="vs-card p-4 flex flex-col gap-3" color="error">
          <p class="text-gray-900 font-bold text-error-800 flex items-center gap-2">
            <vs-icon size="20" name="alert-circle"></vs-icon>
            This warranty is no longer active
          </p>
        </div>
      </div>
    </ng-container>

    <ws-coverage-carousel class="block" [warranty]="warranty" [isCustomerPortal]="true"></ws-coverage-carousel>
  </vs-content-card>
</ng-container>
