import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { TenantInfoService, TenantService } from '@vsolv/core/multi-tenant/web';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ConfigResolver } from './config.resolver';

@Injectable()
export class ConfigGuard implements CanActivate {
  constructor(
    private router: Router,
    private configSvc: PortalService,
    private resolver: ConfigResolver,
    private tenantSvc: TenantService,
    private tenantInfoSvc: TenantInfoService
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    this.tenantSvc.setTenantId();
    this.resolver.config = undefined;

    const tenantId = await this.extractTenantId();
    if (!tenantId) return this.notFound();

    const config = await this.configSvc.getConfig(tenantId);
    if (!config) return this.notFound();

    this.tenantSvc.setTenantId(tenantId);

    this.resolver.config = config;

    this.configSvc.setTheming(config);

    return true;
  }

  private notFound() {
    return this.router.createUrlTree(['404']);
  }

  private async extractTenantId() {
    return this.tenantInfoSvc.getLocalTenantId() || (await this.tenantSvc.getTenantIdFromDomain()) || null;
  }
}
