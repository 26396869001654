import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { CustomersCustomerPortalService } from '@wsphere/customer-portal/web';
import { Customer } from '@wsphere/customers/domain';

@Injectable()
export class CustomerResolver implements Resolve<Customer.Model | undefined> {
  constructor(private customerSvc: CustomersCustomerPortalService) {}

  async resolve() {
    return await this.customerSvc.retrieveSelf();
  }
}
