/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Coverage, Warranty } from '@wsphere/warranties/domain';
import { combineLatest, map, ReplaySubject, tap } from 'rxjs';

@Component({
  selector: 'ws-coverage-picker',
  templateUrl: './coverage-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CoveragePickerComponent,
    },
  ],
})
export class CoveragePickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef, private changeDetector: ChangeDetectorRef) {}

  @PropertyListener('value') value$ = new ReplaySubject<Coverage.Model[]>();
  @Input() value: (Coverage.Model | undefined)[] | null = null;

  @PropertyListener('warranty') warranty$ = new ReplaySubject<Warranty.Model>();
  @Input() warranty: Warranty.Model | null = null;

  @PropertyListener('coverageId') coverageId$ = new ReplaySubject<string>();
  @Input() coverageId: string | null = null;

  @PropertyListener('hasOther') hasOther$ = new ReplaySubject<boolean>();
  @Input() hasOther = false;

  @Input() disabled = false;

  @Output() valueChanges = new EventEmitter<(Coverage.Model | undefined)[] | null>();

  onOther = false;

  touched = false;
  overlayOpen = false;
  selectedGroup: { coverage: Coverage.Model; included: boolean }[] | null = null;
  selectedCoverages: { coverage: Coverage.Model; included: boolean }[] | null = null;

  groupsAndCoverages$ = combineLatest([this.warranty$]).pipe(
    map(([warranty]) => {
      const coverages = warranty?.policy?.coverages;
      if (!coverages?.length) return;

      const groups = coverages?.reduce((acc, coverage) => {
        if (!acc.includes(coverage.group)) acc.push(coverage.group);
        return acc;
      }, [] as string[]);

      const groupsAndCoverages = groups?.reduce((acc, group) => {
        const groupCoverages = coverages
          ?.filter(coverage => coverage.group === group)
          ?.map(coverage => ({
            coverage,
            included:
              warranty.coverages.some(cov => cov.coverageId === coverage?.id && cov.requirement !== 'NOT_APPLICABLE') ??
              false,
          }));

        if (groupCoverages && groupCoverages?.length) acc.push({ group: group, coverages: groupCoverages });
        return acc;
      }, [] as { group: string; coverages: { coverage: Coverage.Model; included: boolean }[] }[]);

      return groupsAndCoverages;
    })
  );

  coverageUpdate$ = combineLatest([this.coverageId$, this.groupsAndCoverages$]).pipe(
    tap(([coverageId, groupsAndCoverages]) => {
      if (this.value?.some(coverage => coverage?.id === coverageId)) {
        return; //already selected, probably from writeValue
      }
      groupsAndCoverages?.forEach(group => {
        group.coverages.forEach(coverage => {
          if (coverage.coverage.id === coverageId) this.selectValueChanged(coverage.coverage, coverage.included);
        });
      });
    })
  );

  other$ = this.hasOther$.pipe(
    map(other => {
      if (other) {
        if (this.value?.length) {
          if (!this.value?.includes(undefined)) this.value.push(undefined);
        } else this.value = [undefined];
      } else {
        if (this.value?.length) {
          if (this.value?.includes(undefined)) this.value = this.value.filter(coverage => coverage !== undefined);
        } else this.value = [];
      }

      this.onChange(this.value);
      this.valueChanges.next(this.value);
      this.changeDetector.detectChanges();

      return other;
    })
  );

  onChange = (_value: (Coverage.Model | undefined)[] | null) => {};
  onTouched = () => {};

  checkSelected(id: string) {
    return this.value?.map(cov => cov?.id).includes(id);
  }

  selectedGroupCount(group: string) {
    return this.value?.filter(item => item?.group === group)?.length || 0;
  }

  selectValueChanged(coverage: Coverage.Model | null, included?: boolean) {
    this.markAsTouched();

    if (!coverage) {
      if (this.value?.length) {
        if (this.value.includes(undefined)) this.value?.filter(coverage => coverage !== undefined);
        else this.value.push(undefined);
      } else this.value = [undefined];
    } else if (this.value?.map(cov => cov?.id)?.includes(coverage.id)) {
      this.value = this.value.filter(cov => cov?.id !== coverage.id);
      this.selectedCoverages = this.selectedCoverages?.filter(group => group.coverage.id !== coverage.id) ?? null;
    } else {
      if (this.value) this.value.push(coverage);
      else this.value = [coverage];

      if (this.selectedCoverages) this.selectedCoverages.push({ coverage: coverage, included: !!included });
      else this.selectedCoverages = [{ coverage: coverage, included: !!included }];
    }

    this.onChange(this.value);
    this.valueChanges.next(this.value);
    this.changeDetector.detectChanges();
  }

  writeValue(value: Coverage.Model[] | null): void {
    if (value) this.value = value as Coverage.Model[];
    else this.value = null;
    this.changeDetector.detectChanges();
  }

  registerOnChange(onChange: (_value: (Coverage.Model | undefined)[] | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
    this.selectedGroup = null;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  ngOnDestroy(): void {
    this.value$.complete();
  }
}
