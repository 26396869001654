import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimCoverageDialog } from '@wsphere/warranties/web';

@Component({
  selector: 'ws-claim-coverage-card',
  templateUrl: './claim-coverage-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimCoverageCardComponent {
  @ViewChild('learnMore') learnMore?: ClaimCoverageDialog;

  @Input() item: Claim.ClaimItem.Model | null = null;

  rejected = Claim.ClaimItem.Status.REJECTED;

  openLearnMore() {
    this.learnMore?.open();
  }

  getBadgeTheme(): ThemeColor {
    if (!this.item) return 'default';

    switch (this.item.status) {
      case Claim.ClaimItem.Status.APPROVED:
      case Claim.ClaimItem.Status.RESOLVED:
        return 'success';
      case Claim.ClaimItem.Status.REJECTED:
        return 'danger';
      default:
        return 'default';
    }
  }
}
