import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { CustomersCustomerPortalService } from '@wsphere/customer-portal/web';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

@Component({ template: `<router-outlet></router-outlet>` })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class IntercomPage implements OnInit, OnDestroy {
  constructor(private customerSvc: CustomersCustomerPortalService, private firebaseSvc: FirebaseService) {}
  private portalSvc = inject(PortalService);

  isSignedIn$ = this.firebaseSvc.isSignedIn$();

  async ngOnInit() {
    if (this.portalSvc.config?.intercomChatMessengerAppID) {
      this.isSignedIn$.subscribe(async _isSignedIn => {
        this.shutdownIntercom();
        await this.bootIntercom();
      });
    }
  }

  shutdownIntercom() {
    window.Intercom('shutdown');
  }

  // Intercom Chatbot
  async bootIntercom() {
    if (this.portalSvc.config?.intercomChatMessengerAppID) {
      window.APP_ID = this.portalSvc.config.intercomChatMessengerAppID;
      const currentCustomer = await this.customerSvc.retrieveSelf();
      window.Intercom('boot', {
        app_id: window.APP_ID,
        email: currentCustomer?.email,
        user_id: currentCustomer?.id,
        name: currentCustomer?.name,
        phone: currentCustomer?.phone,
      });
    }
  }

  ngOnDestroy() {
    this.shutdownIntercom();
  }
}
