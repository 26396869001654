<vs-generic-modal #modal [config]="{ title: 'Signing in with your email' }" (closed)="closed.emit()">
  <!-- Modal content -->
  <button
    headerEnd
    (click)="close()"
    type="button"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-base"
  >
    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clip-rule="evenodd"
      ></path>
    </svg>
    <span class="sr-only">Close modal</span>
  </button>
  <div class="p-5">
    <p class="text-sm">
      We use your email to verify your access to the portal. This may result in sending you a login link that allows you
      to access the portal without having to sign in with a password.
    </p>
  </div>
</vs-generic-modal>
