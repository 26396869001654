import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimService } from '@wsphere/warranties/web';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'ws-claim-coverage-card-carousel',
  templateUrl: './claim-coverage-card-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimCoverageCardCarouselComponent {
  constructor(public breakpointObserver: BreakpointObserver, private claimSvc: ClaimService) {}

  @PropertyListener('claim') claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim: Claim.Model | null = null;
  index = 0;

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  items$ = this.claim$.pipe(
    map(claim => {
      if (!claim) return [];
      return claim.items;
    })
  );
}
