/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { UnderMaintenanceGuard, UnderMaintenancePage } from '@vsolv/core/downtime/web';
import {
  ActivateWarrantyGuard,
  ActivateWarrantyPage,
  ClaimGuard,
  ClaimPage,
  ClaimResolver,
  NewClaimPage,
  OpenClaimGuard,
  WarrantyGuard,
  WarrantyResolver,
} from '@wsphere/customer-portal/web';
import {
  ManagePaymentMethodsDialog,
  ProvisionAddonComponent,
  ProvisionAssetComponent,
  ProvisionCustomerInfoComponent,
  ProvisionPlanComponent,
  ProvisionPolicyComponent,
  SessionAddonGuard,
  SessionAssetGuard,
  SessionCheckoutGuard,
  SessionGuard,
  SessionPlanGuard,
  SessionPolicyGuard,
  UploadClaimDocumentDialog,
  WarrantyPreviewCardComponent,
} from '@wsphere/warranties/web';
import { ConfigGuard, ConfigResolver, SignInGuard, TenantIdGuard } from './guards';
import { PaymentGuard } from './guards/payment.guard';
import { CustomerPortalLayout } from './layout/layout.component';
import {
  CustomerFinancesPage,
  IntercomPage,
  LoginPage,
  NotFoundPage,
  PrivacyPolicyPage,
  ProvisionPage,
  TermsOfServicePage,
  TransactionPage,
  WarrantyPage,
  WelcomePage,
} from './pages';
import { CustomerResolver, PaymentResolver } from './resolvers';

const signedInRoutes: Route = {
  path: '',
  canActivate: [SignInGuard],
  children: [
    {
      path: '',
      pathMatch: 'full',
      component: WelcomePage,
    },
    {
      path: 'finances',
      children: [
        {
          path: '',
          pathMatch: 'full',
          component: CustomerFinancesPage,
        },
        {
          path: ':paymentId',
          canActivate: [PaymentGuard],
          pathMatch: 'full',
          component: TransactionPage,
          resolve: { payment: PaymentResolver },
        },
      ],
    },
    {
      path: 'warranty/:contractNumber/activate',
      canActivate: [ActivateWarrantyGuard],
      pathMatch: 'full',
      component: ActivateWarrantyPage,
    },
    {
      path: 'warranty/:contractNumber',
      canActivate: [WarrantyGuard],
      resolve: { warranty: WarrantyResolver },
      children: [
        { path: '', pathMatch: 'full', component: WarrantyPage },

        { path: 'claims', pathMatch: 'full', redirectTo: '' },
        {
          path: 'claim/open',
          canActivate: [OpenClaimGuard],
          component: NewClaimPage,
        },
        {
          path: 'claim/:claimId',
          canActivate: [ClaimGuard],
          resolve: { claim: ClaimResolver },
          component: ClaimPage,
          children: [
            {
              path: 'manage-reimbursement-methods',
              component: ManagePaymentMethodsDialog,
            },
            {
              path: 'manage-attachments',
              component: UploadClaimDocumentDialog,
            },
          ],
        },

        { path: '**', redirectTo: '' },
      ],
    },
  ],
};
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: IntercomPage,
          children: [
            { path: '404', component: NotFoundPage },

            { path: '503', component: UnderMaintenancePage },

            {
              path: '',
              canActivate: [UnderMaintenanceGuard],
              children: [
                {
                  path: '',
                  canActivate: [ConfigGuard],
                  resolve: { config: ConfigResolver },
                  children: [
                    { path: 'privacy', component: PrivacyPolicyPage },
                    { path: 'terms-of-service', component: TermsOfServicePage },
                    {
                      component: CustomerPortalLayout,
                      path: '',
                      resolve: { customer: CustomerResolver },
                      children: [
                        signedInRoutes,
                        { path: 'login', pathMatch: 'full', component: LoginPage },
                        {
                          path: 'checkout',
                          component: ProvisionPage,
                          children: [
                            {
                              path: '',
                              data: { staffView: false },
                              component: ProvisionCustomerInfoComponent,
                            },
                            {
                              path: 'policy',
                              data: { staffView: false },
                              component: ProvisionPolicyComponent,
                              canActivate: [SessionGuard, SessionPolicyGuard],
                            },
                            {
                              path: 'asset',
                              data: { staffView: false },
                              component: ProvisionAssetComponent,
                              canActivate: [SessionGuard, SessionAssetGuard],
                            },
                            {
                              path: 'plan',
                              data: { staffView: false },
                              component: ProvisionPlanComponent,
                              canActivate: [SessionGuard, SessionPlanGuard],
                            },
                            {
                              path: 'addon',
                              data: { staffView: false },
                              component: ProvisionAddonComponent,
                              canActivate: [SessionGuard, SessionAddonGuard],
                            },
                            {
                              path: 'checkout',
                              data: { staffView: false },
                              component: WarrantyPreviewCardComponent,
                              canActivate: [SessionGuard, SessionCheckoutGuard],
                            },
                          ],
                        },
                        { path: 'warranty', pathMatch: 'full', redirectTo: '' },
                        { path: '**', canActivate: [TenantIdGuard], children: [] },
                      ],
                    },
                  ],
                },
              ],
            },

            { path: '**', redirectTo: '' },
          ],
        },
      ],
      { initialNavigation: 'enabledBlocking' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
