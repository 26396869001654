import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ReplaySubject, switchMap } from 'rxjs';
import { WarrantyCustomerPortalService } from '../../../services';

@Component({
  selector: 'ws-warranty-picker',
  templateUrl: './warranty-picker.component.html',
})
export class WarrantyPickerComponent {
  constructor(private warrantySvc: WarrantyCustomerPortalService, private router: Router) {}

  @Input() contractNumber?: string;

  @Input() customerId?: string;
  @PropertyListener('customerId') private customerId$ = new ReplaySubject<string | undefined>(1);

  readonly warranties$ = this.customerId$.pipe(
    switchMap(async _customerId => {
      return (await this.warrantySvc.list({ limit: 100 })).items;
    })
  );

  isOpen = false;
}
