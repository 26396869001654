import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { Warranty } from '@wsphere/warranties/domain';
import { firstValueFrom } from 'rxjs';
import { WarrantyCustomerPortalService } from '../services';
import { WarrantyResolver } from './warranty.resolver';

@Injectable()
export class ActivateWarrantyGuard implements CanActivate {
  constructor(
    private warrantySvc: WarrantyCustomerPortalService,
    private router: Router,
    private resolver: WarrantyResolver,
    private firebaseSvc: FirebaseService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const signedIn = await firstValueFrom(this.firebaseSvc.authUser$);
    if (!signedIn?.email) {
      await this.router.navigate([''], {});
      return false;
    }

    this.resolver.warranty = undefined;

    const warrantyId = this.extractWarrantyId(route);
    if (!warrantyId) return this.notFound();

    const warranty = await this.warrantySvc.getOneByContractNumber(warrantyId);
    if (!warranty) return this.notFound();

    if (warranty.customer?.email !== signedIn.email) {
      console.error('The customer email does not match the signed in email', warranty.customer?.email, signedIn.email);
      await this.firebaseSvc.signOut();
      await this.router.navigate([''], {});
      return false;
    }

    this.resolver.warranty = warranty;

    if (warranty.status !== Warranty.Status.REGISTERED || warranty.activationDeadline.getTime() < Date.now()) {
      await this.router.navigate([`warranty/${warrantyId}`]);
    }

    return true;
  }

  private notFound() {
    return this.router.createUrlTree(['/warranty']);
  }

  private extractWarrantyId(route: ActivatedRouteSnapshot) {
    return route.paramMap.get('contractNumber');
  }
}
