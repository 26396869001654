<ng-container *vsLet="warranties$ | async as warranties">
  <ng-container *ngIf="warranties">
    <button
      #button
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="isOpen = !overlay.open"
      class="vs-select min-w-[320px]"
      type="button"
      [disabled]="warranties.length <= 1"
    >
      <span style="pointer-events: none">{{ contractNumber }}</span>
      <svg
        style="pointer-events: none"
        *ngIf="!(warranties.length <= 1)"
        class="ml-auto w-4 h-5"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>

    <ng-template
      #overlay="cdkConnectedOverlay"
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      (overlayOutsideClick)="$event.target !== button ? (isOpen = false) : ''"
    >
      <div class="vs-select-card h-[465px] overflow-y-scroll">
        <h3 class="vs-select-card-header">Contract Numbers</h3>
        <ul class="vs-select-card-list" aria-labelledby="dropdownDefault">
          <li *ngFor="let warranty of warranties">
            <a
              [routerLink]="['..', warranty.contractNumber]"
              class="vs-select-card-list-item flex gap-2 justify-between"
              (click)="isOpen = false"
            >
              {{ warranty.contractNumber }}
              <div *ngIf="warranty.contractNumber === contractNumber" class="vs-badge" size="small">Selected</div>
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
