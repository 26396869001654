import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';
import moment from 'moment';

export enum StatusTypes {
  Welcome,
  EmailSent,
  SigningIn,
  Error,
  FailedToSignIn,
}

export enum SignInErrorTypes {
  badLink = 'auth/invalid-action-code',
  badEmail = 'auth/invalid-email',
}

@Component({
  selector: 'ws-portal-header',
  templateUrl: './welcome-header.component.html',
})
export class PortalHeaderComponent implements OnChanges {
  constructor(public portalSvc: PortalService) {}

  @Input() status: StatusTypes = StatusTypes.Welcome;

  titles: { title: string; subtitle: string } = {
    title: this.getWelcomeMessage(),
    subtitle: `Welcome to the ${this.portalSvc.config.brandName} ${this.portalSvc.config.productName} portal.`,
  };

  getWelcomeMessage() {
    const currentHour = parseInt(moment().format('HH'));
    if (currentHour >= 0 && currentHour < 12) {
      return 'Good Morning!';
    } else if (currentHour >= 12 && currentHour < 15) {
      return 'Good Afternoon!';
    } else {
      return 'Good Evening!';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['status'] && changes['status'].previousValue != changes['status'].currentValue) {
      switch (changes['status'].currentValue) {
        case StatusTypes.Welcome:
          this.titles = {
            title: this.getWelcomeMessage(),
            subtitle: `Welcome to the ${this.portalSvc.config.brandName} ${this.portalSvc.config.productName} portal.`,
          };
          break;
        case StatusTypes.Error:
          this.titles = {
            title: "Oops, this link doesn't work!",
            subtitle: `This can happen if the link expired, or has already been used.\nTo access the ${this.portalSvc.config.brandName} ${this.portalSvc.config.productName} portal, enter your email below and we'll send you a new login link`,
          };
          break;
        case StatusTypes.SigningIn:
          this.titles = {
            title: 'Hang tight.',
            subtitle: 'We are signing you in.',
          };
          break;
        case StatusTypes.FailedToSignIn:
          this.titles = {
            title: "Oops, we couldn't log you in!",
            subtitle: `Please try again or feel free to contact us at ${this.portalSvc.config.contactEmail}`,
          };
          break;
        case StatusTypes.EmailSent:
          this.titles = {
            title: 'Thanks!',
            subtitle: "We're sending your email now.",
          };
      }
    }
  }
}
