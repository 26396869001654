import { Component, Input } from '@angular/core';
import { Warranty } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-warranty-details-card',
  templateUrl: './warranty-details-card.component.html',
})
export class WarrantyDetailsCardComponent {
  @Input() warranty?: Warranty.Model;
}
