import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationQueryRequest } from '@vsolv/dev-kit/ngx';
import { Payment } from '@vsolv/packages/payments/domain';
import { ThemeColorEnum } from '@vsolv/vectors-ui/theming';
import { BadgeColumn, TableColumn, TextColumn, TextSubtitleColumn } from '@vsolv/vectors/table';
import { PaymentsCustomerPortalService } from '@wsphere/customer-portal/web';
import moment from 'moment';
import { UcFirstPipe } from 'ngx-pipes';
import { BehaviorSubject, combineLatest, map, ReplaySubject, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'wsphere-transaction',
  templateUrl: './transaction.page.html',
})
export class TransactionPage {
  constructor(
    private paymentsSvc: PaymentsCustomerPortalService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  private ucFirst = new UcFirstPipe();

  private _refresh$ = new ReplaySubject<Payment.Model>(1);

  protected payment$ = this.route.data.pipe(
    map(data => data['payment'] as Payment.Model),
    switchMap(payment => this._refresh$.pipe(startWith(payment)))
  );

  protected paymentStatus$ = this.payment$.pipe(
    map(payment => ({
      label: new UcFirstPipe().transform(payment.status.replace(/_/g, ' ').toLowerCase()),
      theme: {
        [Payment.Status.DRAFT]: ThemeColorEnum.default,

        [Payment.Status.VOIDED]: ThemeColorEnum.default,
        [Payment.Status.PENDING]: ThemeColorEnum.warn,

        [Payment.Status.PAID]: ThemeColorEnum.success,
        [Payment.Status.FAILED]: ThemeColorEnum.danger,
        [Payment.Status.PROCESSING]: ThemeColorEnum.info,

        [Payment.Status.REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PARTIALLY_REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PROCESSING_REFUND]: ThemeColorEnum.info,
      }[payment.status],
    }))
  );

  paginationQueryRequest$ = new BehaviorSubject<PaginationQueryRequest>({
    page: 1,
    limit: 10,
  });

  pagination = {
    totalItems: 0,
    itemsPerPage: 10,
    currentPage: 1,
    pageSizes: [10, 20, 50],
  };

  relatedPayments$ = combineLatest([this.paginationQueryRequest$, this.payment$]).pipe(
    switchMap(async ([request, payment]) => {
      if (!payment || !payment.referenceId) return [];
      const response = await this.paymentsSvc.getListRelated({
        ...request,
        referenceId: payment.referenceId,
        excludeId: payment.id,
        ascendingOrder: true,
      });

      let firstNonPending = response.items.findIndex(payment => payment.status !== Payment.Status.PENDING);
      if (firstNonPending === -1) firstNonPending = response.items.length;
      if (payment.status !== Payment.Status.PENDING) firstNonPending -= 1;

      this.pagination = {
        ...this.pagination,
        totalItems: response.meta.totalItems ?? 0,
        itemsPerPage: response.meta.itemsPerPage,
        currentPage: response.meta.currentPage,
      };

      if (firstNonPending > 0) {
        const firstNonPendingTransaction = response?.items.splice(firstNonPending, 1);
        return (response.items = [...new Set([...firstNonPendingTransaction, ...response.items])]);
      }
      return response.items;
    })
  );

  columns: TableColumn<unknown>[] = [
    new TextSubtitleColumn<Payment.Model>({ header: 'Warranty' }, payment => ({
      title: payment.priceBreakdown ? payment.priceBreakdown[0]?.title : 'Warranty',
      titleClasses: 'truncate max-w-[15vw]',

      subtitle: payment.referenceId,
      subtitleClasses: 'truncate max-w-[15vw]',
    })),

    new TextColumn<Payment.Model>({ header: 'Transaction' }, payment => ({
      text: payment.description ?? '',
      classes: 'truncate max-w-[15vw]',
    })),

    new BadgeColumn<Payment.Model>({ header: 'Status', fitContent: true, align: 'center' }, payment => ({
      text: this.ucFirst.transform(payment.status.replace(/_/g, ' ').toLowerCase()),
      displayStatusIcon: true,
      theme: {
        [Payment.Status.DRAFT]: ThemeColorEnum.default,

        [Payment.Status.VOIDED]: ThemeColorEnum.default,
        [Payment.Status.PENDING]: ThemeColorEnum.warn,

        [Payment.Status.PAID]: ThemeColorEnum.success,
        [Payment.Status.FAILED]: ThemeColorEnum.danger,
        [Payment.Status.PROCESSING]: ThemeColorEnum.info,

        [Payment.Status.REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PARTIALLY_REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PROCESSING_REFUND]: ThemeColorEnum.info,
      }[payment.status],
    })),

    new TextColumn<Payment.Model>({ header: 'Total', fitContent: true, align: 'right' }, payment => ({
      text: payment.amount ? '$' + (payment.amount / 100).toFixed(2) : '-',
      classes: 'truncate max-w-[15vw]',
    })),

    new TextSubtitleColumn<Payment.Model>({ header: 'Payment Method' }, payment => ({
      title: payment.paymentMethod?.creditCard
        ? `${payment.paymentMethod.creditCard.cardType} (••••${payment.paymentMethod.creditCard.cardNumber.match(
            /[\d]{4}$/
          )})`
        : '',
      titleClasses: 'truncate max-w-[15vw]',

      subtitle: payment.paymentMethod?.creditCard ? `Exp. ${payment.paymentMethod.creditCard.expirationDate}` : '',
      subtitleClasses: 'truncate max-w-[15vw]',
    })),

    new TextColumn<Payment.Model>({ header: 'Transaction Date', fitContent: true, align: 'center' }, payment => ({
      text: payment.paymentDate ? moment(payment.paymentDate).fromNow() : '-',
      classes: 'truncate max-w-[15vw]',
    })),
  ];

  protected async rowClicked(payment: Payment.Model) {
    await this.router.navigate(['..', payment.id], { relativeTo: this.route });
  }

  protected async refresh(paymentId: string) {
    const payment = await this.paymentsSvc.getOne(paymentId);
    if (payment) this._refresh$.next(payment);
  }
}
