/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@wsphere/customers/domain';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CustomersCustomerPortalService {
  constructor(private http: HttpClient) {}

  async retrieveSelf() {
    return await firstValueFrom(this.http.get<Customer.Model>(`/api/customers/self`));
  }

  async checkIfCustomerExists(email: string) {
    return (await firstValueFrom(this.http.get<{ exists: boolean }>(`/api/customers/${email}`))).exists;
  }
}
