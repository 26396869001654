import { Component, inject, OnInit } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';
@Component({ templateUrl: './not-found.page.html' })
export class NotFoundPage implements OnInit {
  portalSvc = inject(PortalService);

  async ngOnInit(): Promise<void> {
    this.loadConfig();
  }

  loadConfig() {
    const localConfig = localStorage.getItem('config');
    if (localConfig) {
      this.portalSvc.setTheming(JSON.parse(localConfig));
    }
  }
}
