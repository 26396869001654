import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimService, UploadClaimDocumentDialog, ViewClaimDocumentDialog } from '@wsphere/warranties/web';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-claim-attachment-carousel',
  templateUrl: './claim-attachment-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimAttachmentCarouselComponent {
  constructor(private claimSvc: ClaimService, private breakpointObserver: BreakpointObserver) {}

  @ViewChild('viewDialog') viewDialog?: ViewClaimDocumentDialog;
  @ViewChild('uploadDialog') uploadDialog?: UploadClaimDocumentDialog;

  @PropertyListener('claim') claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim?: Claim.Model | null = null;

  index = 0;
  open = Claim.Status.OPEN;

  refresh$ = new BehaviorSubject(null);

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  attachments$ = combineLatest([this.claim$, this.refresh$]).pipe(
    switchMap(async ([claim]) => {
      if (!claim) return [];

      const response = await this.claimSvc.listDocuments(claim.id);
      return response.items;
    })
  );

  openViewDialog(document: StorageItem.Model) {
    if (!this.claim) return;
    this.viewDialog?.open({ claim: this.claim, document });
  }

  openUploadDialog() {
    if (!this.claim) return;
    this.uploadDialog?.open(this.claim.id);
  }

  refresh() {
    this.refresh$.next(null);
  }
}
