import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { DwollaService } from '@vsolv/packages/reimbursement/web';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimService, UploadClaimDocumentDialog, ViewClaimDocumentDialog } from '@wsphere/warranties/web';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-claim-reimbursement-carousel',
  templateUrl: './claim-reimbursement-carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimReimbursementCarouselComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private dwollaSvc: DwollaService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild('viewDialog') viewDialog?: ViewClaimDocumentDialog;
  @ViewChild('uploadDialog') uploadDialog?: UploadClaimDocumentDialog;

  @PropertyListener('claim') claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim?: Claim.Model | null = null;

  index = 0;
  expired = Claim.Status.EXPIRED;

  refresh$ = new BehaviorSubject(null);

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  payments$ = combineLatest([this.claim$, this.refresh$]).pipe(
    switchMap(async ([claim]) => {
      if (!claim) return [];

      const response = await this.claimSvc.listPayments(claim.id);
      return response.items.filter(
        payment => payment.destination === 'PENDING' || payment.status !== Claim.ClaimPayment.Status.DRAFT
      );
    })
  );

  destinations$ = combineLatest([this.claim$, this.refresh$]).pipe(
    switchMap(async ([claim]) => {
      if (!claim || !claim.warranty?.customer?.email) return [];

      return await this.dwollaSvc.listFundingSource(claim.warranty.customer.email);
    })
  );

  openViewDialog(document: StorageItem.Model) {
    if (!this.claim) return;
    this.viewDialog?.open({ claim: this.claim, document });
  }

  openUploadDialog() {
    if (!this.claim) return;
    this.uploadDialog?.open(this.claim.id);
  }

  refresh() {
    this.refresh$.next(null);
  }

  navigateTo() {
    this.router.navigate([`manage-reimbursement-methods`], { relativeTo: this.route });
  }
}
