import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vs-generic-modal',
  templateUrl: './generic-modal.component.html',
})
export class GenericModalComponent {
  @Input() showCloseButton = false;

  @Input() config?: { hideHeader?: boolean; title?: string; subtitle?: string; size?: 'sm' | 'md' | 'lg' | 'xl' };

  @Output() closed = new EventEmitter<boolean>();

  _open = false;

  open() {
    this._open = true;
  }

  close() {
    this._open = false;
    this.closed.emit();
  }

  toggle() {
    this._open = !this._open;
  }
}
