import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import {
  CustomersCustomerPortalService,
  StatusTypes,
  WarrantyCustomerPortalService,
} from '@wsphere/customer-portal/web';
import { Customer } from '@wsphere/customers/domain';
import { firstValueFrom, map } from 'rxjs';
enum InitializeStep {
  GET_CUSTOMER = 'Getting Customer',
  GET_WARRANTY = 'Getting Warranty',
  CHECKOUT = 'Redirecting to checkout',
  SIGNOUT = 'Signing out of firebase',
  LOGIN = 'Redirecting to login',
}
@Component({ templateUrl: './welcome.page.html' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class WelcomePage implements OnInit {
  constructor(
    private firebaseSvc: FirebaseService,
    private router: Router,
    private warrantySvc: WarrantyCustomerPortalService,
    private route: ActivatedRoute,
    private customerSvc: CustomersCustomerPortalService,
    private http: HttpClient
  ) {}
  readonly statusTypes = StatusTypes;
  private attempts = 0;
  private lastStep = InitializeStep.GET_CUSTOMER;

  status = StatusTypes.SigningIn;

  async ngOnInit() {
    this.attempts = 0;
    await this.initialize();
  }

  private async initialize() {
    try {
      this.lastStep = InitializeStep.GET_CUSTOMER;
      const customer: Customer.Model | undefined =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (await firstValueFrom(this.route.parent!.data.pipe(map(data => data['customer'])))) ||
        (await this.customerSvc.retrieveSelf());

      if (customer) {
        this.lastStep = InitializeStep.GET_WARRANTY;
        const warranties = (await this.warrantySvc.list({ page: 1, limit: 1 }))?.items;

        if (warranties?.length) {
          await this.router.navigateByUrl(`/warranty/${warranties[0].contractNumber}`);
        } else {
          this.lastStep = InitializeStep.CHECKOUT;
          await this.router.navigateByUrl('/checkout');
        }
      } else {
        this.lastStep = InitializeStep.SIGNOUT;
        await this.firebaseSvc.signOut();

        this.lastStep = InitializeStep.LOGIN;
        await this.router.navigateByUrl(`/login`);
      }
    } catch (err) {
      console.error(err);
      this.attempts += 1;
      if (this.attempts < 5) {
        await new Promise(() => setTimeout(async () => await this.initialize(), 5 ** this.attempts));
      } else {
        //post error
        this.status = StatusTypes.FailedToSignIn;

        let customer = undefined;
        try {
          customer = await this.customerSvc.retrieveSelf();
        } catch {
          //noop
        }
        await firstValueFrom(
          this.http.post('/api/internal_log', {
            message: `Failed to redirect ${
              customer ? 'customer ' + customer.email : 'unknown customer'
            } from welcome page at step: ${this.lastStep}`,
            error: err ? JSON.stringify(err) : undefined,
          })
        );
      }
    }
  }
}
