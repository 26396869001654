<div *ngIf="warranty$ | async as warranty" class="flex flex-col gap-6">
  <div>
    <div class="mb-3">
      <h1 class="mr-2 inline-block text-3xl text-base-dark font-bold">Hi {{ warranty.customer?.name || '' }},</h1>
      <h3 class="inline-block text-xl italic text-gray-600 font-bold">how can we help you today?</h3>
    </div>
    <ws-warranty-picker
      [customerId]="warranty.customerId"
      [contractNumber]="warranty.contractNumber"
    ></ws-warranty-picker>
  </div>
  <ws-warranty-details-card [warranty]="warranty || undefined"></ws-warranty-details-card>
  <ws-warranty-claims-card [warranty]="warranty || undefined"></ws-warranty-claims-card>
  <ws-warranty-documents-card [warranty]="warranty || undefined"></ws-warranty-documents-card>
</div>
