import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { GenericModalComponent } from '@vsolv/ui-kit/modal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'vs-send-email-modal',
  templateUrl: './send-email-modal.component.html',
})
export class SendEmailModalComponent {
  @ViewChild('modal') modal?: GenericModalComponent;

  @Output() submitEvent = new EventEmitter<string>();
  @Output() closed = new EventEmitter<boolean>();

  open() {
    this.modal?.open();
  }

  close() {
    this.modal?.close();
  }

  toggle() {
    this.modal?.toggle();
  }
}
