import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from '@vectorsolv/vectors-ui/file-input';
import { FirebaseWebModule } from '@vsolv/packages/firebase/web';
import { PropertiesWebModule } from '@vsolv/packages/properties/web';
import { DwollaConfig, DWOLLA_CONFIG, ReimbursementWebModule } from '@vsolv/packages/reimbursement/web';
import { ContentCardComponent, ContentCardModule } from '@vsolv/ui-kit/card';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { TimelineModule } from '@vsolv/ui-kit/timeline';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DatePickerModule } from '@vsolv/vectors-ui/date-picker';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewModule } from '@vsolv/vectors-ui/image-preview';
import { InfoCardModule } from '@vsolv/vectors-ui/info-card';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { RichTextEditorModule } from '@vsolv/vectors-ui/rich-text-editor';
import { StackGroupModule } from '@vsolv/vectors-ui/stack-group';
import { StepperModule } from '@vsolv/vectors-ui/stepper';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { PhoneFormatPipeModule } from '@vsolv/vectors-ui/utils';
import { ClaimModule, WarrantyManagementModule } from '@wsphere/warranties/web';
import { NgxCurrencyModule } from 'ngx-currency';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperModule } from 'swiper/angular';
import {
  ClaimAttachmentCarouselComponent,
  ClaimCoverageCardCarouselComponent,
  ClaimCoverageCardComponent,
  ClaimNotesViewerComponent,
  ClaimReimbursementCardComponent,
  ClaimReimbursementCarouselComponent,
  CoveragePickerComponent,
  EnterEmailCardComponent,
  PortalHeaderComponent,
  PortalInfoCardComponent,
  WarrantyClaimsCardComponent,
  WarrantyDetailsCardComponent,
  WarrantyPickerComponent,
  WarrantyStatusDropDownComponent,
} from './components';
import { ActivateWarrantyGuard, ClaimGuard, OpenClaimGuard, WarrantyGuard, WarrantyResolver } from './guards';
import { AddBankingDetailsModal } from './modals';
import { ActivateWarrantyPage, ClaimOverviewPage, ClaimPage, NewClaimPage } from './pages';
import { ClaimResolver } from './resolver';
import {
  CustomersCustomerPortalService,
  PaymentsCustomerPortalService,
  WarrantyCustomerPortalService,
} from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TimelineModule,
    UtilsModule,
    ContentCardModule,
    ModalModule,
    NgPipesModule,
    OverlayModule,
    SwiperModule,
    ReactiveFormsModule,
    ReimbursementWebModule,
    MatTooltipModule,
    IconsModule,
    BreadcrumbsModule,
    ButtonModule,
    ThemingModule,
    InputModule,
    StackGroupModule,
    ImagePreviewModule,
    PhoneFormatPipeModule,
    DatePickerModule,
    MenuModule,
    NgxCurrencyModule,
    ClaimModule,
    FirebaseWebModule,
    AppPageModule,
    MomentModule,
    BadgeModule,
    AppNavModule,
    CardModule,
    DividerModule,
    InfoCardModule,
    UserAvatarModule,
    DialogModule,
    StepperModule,
    PropertiesWebModule,
    TooltipModule,
    FileUploadModule,
    RichTextEditorModule,
    WarrantyManagementModule,
  ],
  exports: [
    PortalInfoCardComponent,
    PortalHeaderComponent,
    WarrantyDetailsCardComponent,
    WarrantyStatusDropDownComponent,
    WarrantyPickerComponent,
    EnterEmailCardComponent,
    ContentCardComponent,
    WarrantyClaimsCardComponent,
  ],
  declarations: [
    CoveragePickerComponent,
    PortalInfoCardComponent,
    PortalHeaderComponent,
    WarrantyDetailsCardComponent,
    WarrantyStatusDropDownComponent,
    WarrantyPickerComponent,
    AddBankingDetailsModal,
    ActivateWarrantyPage,
    EnterEmailCardComponent,

    // Claims
    ClaimPage,
    ClaimOverviewPage,
    ClaimCoverageCardComponent,
    ClaimCoverageCardCarouselComponent,
    ClaimAttachmentCarouselComponent,
    ClaimReimbursementCarouselComponent,
    ClaimReimbursementCardComponent,
    ClaimNotesViewerComponent,
    WarrantyClaimsCardComponent,
    NewClaimPage,
  ],
  providers: [
    CustomersCustomerPortalService,
    WarrantyCustomerPortalService,
    PaymentsCustomerPortalService,
    WarrantyGuard,
    ActivateWarrantyGuard,
    WarrantyResolver,
    ClaimGuard,
    ClaimResolver,
    OpenClaimGuard,
  ],
})
export class CustomerPortalModule {
  static forRoot(config: DwollaConfig): ModuleWithProviders<CustomerPortalModule> {
    return {
      ngModule: CustomerPortalModule,
      providers: [{ provide: DWOLLA_CONFIG, useValue: config }],
    };
  }
}
