<ng-container *ngIf="warranty$ | async as warranty">
  <div class="flex min-h-full flex-col">
    <vs-breadcrumbs
      slot="breadcrumbs"
      [config]="{
      items: [
        { label: warranty.contractNumber, routerLink: '../..' },
        { label: 'Open A New Claim'}
      ]
    }"
    ></vs-breadcrumbs>

    <ng-container [ngSwitch]="step">
      <div class="flex items-center flex-grow" *ngSwitchDefault>
        <div class="flex flex-col w-full justify-center items-center h-full px-8 gap-6">
          <vs-icon name="file-shield-02" class="text-primary-500" size="129"></vs-icon>
          <div class="text-3xl font-semibold text-gray-900">Let's jump right into it!</div>
          <div class="text-xl text-center text-gray-600 max-w-3xl">
            We'll use your answers to the following questions to help expedite your claim processing. Please answer as
            accurately as possible to take full advantage of your
            <span class="font-semibold">{{warranty.policy?.title}}</span>.
          </div>

          <button vs-button theme="primary" (click)="nextPage()">Let's Begin</button>
        </div>
      </div>

      <form
        [formGroup]="claimFormGroup"
        class="flex flex-grow items-center px-8"
        [class.hidden]="step === 0 || step === lastStep"
      >
        <ng-container *ngSwitchCase="1">
          <div class="flex flex-col">
            <div class="flex gap-6">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{step}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>

              <div class="flex flex-col gap-3">
                <div class="text-3xl font-medium">Describe your issue. *</div>

                <div class="text-2xl">
                  To assist us better handle your claim, please identify the problem you are experiencing.
                </div>

                <input
                  type="text"
                  vsInput
                  name="complaint"
                  formControlName="complaint"
                  placeholder="Describe your issue"
                />

                <div class="flex gap-2">
                  <button
                    [disabled]="!claimFormGroup.controls.complaint.valid"
                    vs-button
                    theme="primary"
                    (click)="nextPage()"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>

                  <button vs-button theme="default" appearance="outline" (click)="previousPage()">Back</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="2">
          <div class="flex flex-col">
            <div class="flex gap-6">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{ step }}
                <vs-icon name="arrow-right"></vs-icon>
              </div>

              <div class="flex flex-col gap-3">
                <div class="text-3xl font-medium">Which product is having the issue? *</div>

                <div class="text-2xl">
                  We're sorry you're experiencing issues. Please select all items related to your complaint.
                </div>

                <div class="flex flex-col gap-2">
                  <ws-coverage-picker
                    #covpicker
                    formControlName="coverages"
                    [warranty]="warranty"
                    [coverageId]="coverageId$ | async"
                    [hasOther]="!!claimFormGroup.value.description?.length"
                  ></ws-coverage-picker>

                  <textarea
                    *ngIf="covpicker.onOther"
                    vsInput
                    min="3"
                    max="6"
                    formControlName="description"
                    placeholder="Describe the issue in greater detail to help identify the coverage"
                  ></textarea>
                </div>

                <div class="flex gap-2">
                  <button
                    vs-button
                    theme="primary"
                    (click)="nextPage()"
                    [disabled]="!claimFormGroup.value.coverages?.length || (checkForOther() && !claimFormGroup.value.description)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button vs-button theme="default" appearance="outline" (click)="previousPage()">Back</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="3">
          <div class="flex flex-col">
            <div class="flex gap-6">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{ step }}
                <vs-icon name="arrow-right"></vs-icon>
              </div>

              <div class="flex flex-col gap-3">
                <div class="text-3xl font-medium">Provide photo evidence for your claim.</div>

                <div class="text-2xl">
                  You may upload and attach up to 5 photos to your claim. The evidence is used to accelerate the
                  approval process and make the remediation seamless for you.
                  <br />
                  <br />
                  If you would not like to upload any evidence, you can skip this step.
                </div>

                <vs-file-input
                  class="max-w-[592px]"
                  formControlName="evidence"
                  [multiple]="true"
                  [accept]="'.SVG, .PNG, .JPG, .JPEG, .GIF'"
                  [moreInfo]="'max. 800x400px'"
                ></vs-file-input>

                <div class="flex gap-2">
                  <button vs-button theme="primary" (click)="nextPage()">
                    {{ claimFormGroup.value.evidence?.length ? 'Next' : 'Skip' }}

                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button vs-button theme="default" appearance="outline" (click)="previousPage()">Back</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="4">
          <div class="flex flex-col">
            <div class="flex gap-6">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{ step }}
                <vs-icon name="arrow-right"></vs-icon>
              </div>

              <div class="flex flex-col gap-3">
                <div class="text-3xl font-medium">Let's review your claim! *</div>
                <div class="flex flex-col gap-1 text-xl text-gray-600">
                  <span>Complaint: {{ claimFormGroup.value.complaint }}</span>
                  <div class="flex gap-1">
                    <span>Claim Items:</span>

                    <div class="flex gap-1">
                      <span *ngFor="let coverage of claimFormGroup.value.coverages; index as index">
                        {{ (coverage ? coverage.title : 'Other') + (index < (claimFormGroup.value.coverages?.length ||
                        0) - 1 ? ',' : '') }}
                      </span>
                    </div>
                  </div>

                  <span>Photo Evidence Count: {{claimFormGroup.value.evidence?.length}}</span>
                </div>
                <div class="text-xl font-semibold max-w-[648px]">
                  You understand that to willfully withhold information or deliberately give wrong information
                  constitutes fraud and is a crime punishable by law. You agree that the information provided in this
                  claim is true.
                </div>

                <div class="flex gap-2">
                  <button vs-button theme="primary" (click)="nextPage(warranty.id)">
                    I agree
                    <vs-icon name="check"></vs-icon>
                  </button>

                  <button vs-button theme="default" appearance="outline" (click)="previousPage()">Back</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>

      <ng-container *ngSwitchCase="5">
        <div class="flex flex-col flex-grow w-full justify-center items-center h-full px-8 gap-6">
          <vs-icon name="shield-tick" class="text-primary-500" size="129"></vs-icon>
          <div class="text-3xl font-medium">You have this coverage!</div>
          <div class="text-2xl text-center">
            Good thing you have a
            <span class="font-semibold">{{warranty.policy?.title}}</span>!<br /><br />
            Your claim has been submitted for verification. We're sorry you experienced an issue.<br /><br />
            Please wait while we verify your evidence. You will be redirected automatically.
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
