/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Warranty } from '@wsphere/warranties/domain';
import { catchError, firstValueFrom, of } from 'rxjs';

const api = `/api/warranties`;
@Injectable()
export class WarrantyCustomerPortalService {
  constructor(private http: HttpClient) {}

  getOneByCustomerEmail(email: string) {
    return firstValueFrom(
      this.http.get<Warranty.Model>(`${api}/email`, { params: { email } }).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  getOneByContractNumber(contractNumber: string) {
    return firstValueFrom(
      this.http
        .get<Warranty.Model>(`${api}/${contractNumber}`, {
          params: {
            idField: 'contractNumber',
          },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  list(params: Pick<Warranty.ListRequest, 'page' | 'limit'>): Promise<Warranty.ListResponse> {
    return firstValueFrom(
      this.http.get<Warranty.ListResponse>(`${api}/list`, {
        params: {
          ...(params.page ? { page: params.page.toString() } : {}),
          ...(params.limit ? { limit: params.limit.toString() } : {}),
        },
      })
    );
  }

  async activateWarranty(warrantyId: string, body: Warranty.Activate) {
    return await firstValueFrom(this.http.post<Warranty.Activate>(`${api}/${warrantyId}/activate`, body)).catch(
      () => undefined
    );
  }
}
