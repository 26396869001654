<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="pointer-events-none"
  [cdkConnectedOverlayOpen]="_open"
  [cdkConnectedOverlayOrigin]="{ x: 0, y: 0 }"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="close()"
>
  <div class="p-4 w-screen h-screen flex items-center justify-center pointer-events-none">
    <vs-content-card
      class="w-full relative pointer-events-auto"
      [header]="{
        title: config?.title,
        hide: config?.hideHeader,
        subtitle: config?.subtitle
      }"
      [ngClass]="{
        'max-w-md': config?.size === 'sm',
        'max-w-lg': !config?.size || config?.size === 'md',
        'max-w-4xl': config?.size === 'lg',
        'max-w-7xl': config?.size === 'xl'
      }"
    >
      <ng-container headerStart>
        <ng-content select="[headerStart]"></ng-content>
      </ng-container>

      <ng-container headerAfter>
        <button
          *ngIf="showCloseButton"
          (click)="close()"
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-base"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>

          <span class="sr-only">Close modal</span>
        </button>
      </ng-container>

      <ng-content></ng-content>

      <ng-container headerEnd>
        <ng-content select="[headerEnd]"></ng-content>
      </ng-container>
    </vs-content-card>
  </div>
</ng-template>
