<vs-card *ngIf="item" class="h-full">
  <vs-card-content class="flex flex-col gap-2">
    <div class="flex gap-2 lg:flex-nowrap flex-wrap">
      <p class="font-semibold text-gray-600 truncate">{{ item.coverage?.title || 'Other' }}</p>
      <vs-badge class="w-fit h-fit self-center" [theme]="getBadgeTheme()">
        {{ item.status.replace('DRAFT', 'REVIEWING') | titlecase }}
      </vs-badge>
    </div>

    <div class="flex flex-col gap-2 min-h-[88px] max-h-[88px]">
      <p class="text-sm font-normal text-gray-600 max-h-[64px] line-clamp-3 overflow-ellipsis">
        {{ item.description || item.coverage?.description }}
      </p>

      <p class="text-sm font-normal text-gray-600 flex cursor-pointer" (click)="openLearnMore()">
        Learn More <vs-icon size="18" name="chevron-right" class="self-center"></vs-icon>
      </p>
    </div>

    <vs-divider></vs-divider>

    <ng-container *ngIf="item.status !== rejected">
      <p class="flex gap-1 text-sm font-medium text-gray-600 max-w-[80%] truncate">
        Cure:
        <span *ngIf="item.cure?.description" class="font-normal"> {{ item.cure?.description }} </span>
        <i *ngIf="!item.cure?.description" class="font-normal"> We will determine a cure shortly </i>

        <vs-icon
          *ngIf="item.cure?.title"
          name="info-circle"
          class="self-center"
          tooltipStyle="light"
          [vsTooltip]="{ title: item.cure?.title || '', subtitle: item.cure?.description || '' }"
        ></vs-icon>
      </p>

      <p class="flex gap-1 text-sm font-medium text-gray-600 max-w-[80%] truncate">
        Resolution:
        <span *ngIf="item.resolution?.title" class="font-normal"> {{ item.resolution?.title }} </span>
        <i *ngIf="!item.resolution?.title" class="font-normal"> We will resolve this item shortly </i>

        <vs-icon
          *ngIf="item.resolution?.title"
          name="info-circle"
          class="self-center"
          tooltipStyle="light"
          [vsTooltip]="{ title: item.resolution?.title || '', subtitle: item.resolution?.description || '' }"
        ></vs-icon>
      </p>
    </ng-container>

    <div *ngIf="item.status === rejected" class="flex gap-3 py-2 px-3 rounded-xl border bg-gray-50">
      <vs-icon size="20" name="slash-circle-01" class="self-center text-gray-400"></vs-icon>
      <p class="text-sm font-normal text-gray-400">
        This claim item was rejected, no cure or resolution will be documented for it.
      </p>
    </div>
  </vs-card-content>
</vs-card>

<ws-claim-coverage-dialog #learnMore [item]="item" [coverage]="item?.coverage"></ws-claim-coverage-dialog>
