import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { TimelineComponent, TimelineItemComponent } from './components';

@NgModule({
  imports: [CommonModule, UtilsModule],
  exports: [TimelineComponent, TimelineItemComponent],
  declarations: [TimelineComponent, TimelineItemComponent],
})
export class TimelineModule {}
