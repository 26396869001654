<ng-container *ngIf="!sent; else sentTemplate">
  <div class="vs-card divide-y rounded-lg" appearance="raised" *ngIf="!loading">
    <div class="vs-card-content">
      <h2 class="text-lg font-medium">
        Please enter your email address{{ oneTimeToken || emailLinkSignInTenantId ? ' to verify your identity' : '' }}.
      </h2>

      <a class="align-middle text-sm cursor-pointer text-[#185669]" (click)="modal.toggle()" style="font-style: italic">
        Why do we need your email?
        <svg
          class="inline w-4 h-4 -mt-[2px]"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
      </a>
      <vs-send-email-modal #modal (submitEvent)="modal.close()"></vs-send-email-modal>
    </div>

    <div class="p-5">
      <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Email </label>

      <form [formGroup]="formGroup">
        <div class="relative">
          <div class="flex flex-row flex-wrap gap-2 items-center">
            <input
              #input
              type="text"
              id="input-group-1"
              class="flex-[3] text-sm rounded-lg block p-2.5"
              [ngClass]="{
                'bg-red-50 border border-red-500 text-red-900 focus:ring-red-500 focus:border-red-500 placeholder-red-700 hover:border-red-500':
                  !inputIsValid() && input.value,
                'bg-gray-50 border border-gray-300 text-gray-900 focus:ring-primary-500 focus:border-primary-500 hover:border-primary-500':
                  inputIsValid() || !input.value
              }"
              placeholder="example@email.com"
              formControlName="email"
              required
            />

            <button
              [disabled]="!inputIsValid()"
              type="button"
              class="flex-1 whitespace-nowrap vs-btn"
              appearance="fill"
              size="small"
              color="primary"
              (click)="submit()"
            >
              <ng-container *ngIf="!(oneTimeToken || emailLinkSignInTenantId); else verifyButton">
                <span>Sign In</span>
                <svg
                  class="w-5 h-5 scale-[-1]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  ></path>
                </svg>
              </ng-container>
              <ng-template #verifyButton>
                <span>Verify</span>
              </ng-template>
            </button>
          </div>

          <p *ngIf="!inputIsValid() && input.value" class="text-xs text-base-dark/60 ml-2 mt-1">
            Example: example@email.com
          </p>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<ng-template #sentTemplate>
  <div class="vs-card divide-y rounded-lg" appearance="raised">
    <div class="vs-card-content">
      <div class="flex gap-4">
        <div>
          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
            <path
              d="M26.4995 29.5001L36.9995 19.0001M26.6271 29.8281L29.2552 36.5861C29.4867 37.1815 29.6025 37.4791 29.7693 37.566C29.9139 37.6414 30.0862 37.6415 30.2308 37.5663C30.3977 37.4796 30.5139 37.1821 30.7461 36.587L37.3364 19.6992C37.5461 19.1621 37.6509 18.8935 37.5935 18.7219C37.5437 18.5728 37.4268 18.4558 37.2777 18.406C37.1061 18.3487 36.8375 18.4535 36.3003 18.6632L19.4126 25.2535C18.8175 25.4857 18.52 25.6018 18.4333 25.7687C18.3581 25.9134 18.3582 26.0857 18.4335 26.2303C18.5204 26.3971 18.8181 26.5128 19.4135 26.7444L26.1715 29.3725C26.2923 29.4195 26.3527 29.443 26.4036 29.4793C26.4487 29.5114 26.4881 29.5509 26.5203 29.596C26.5566 29.6468 26.5801 29.7073 26.6271 29.8281Z"
              stroke="#039855"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
          </svg>
        </div>
        <div>
          <p class="text-sm text-black/60 ml-2 mt-1">The login link has been sent to</p>
          <h2 class="text-sm font-medium ml-2 mt-1">{{ formGroup.get('email')?.value }}</h2>
          <div class="p-1"></div>
        </div>
      </div>
    </div>
    <div class="p-1 text-center">
      <p class="text-sm text-black/60 ml-2 mt-1">
        Nothing in your inbox?
        <button
          type="button"
          class="ml-4 flex-1 whitespace-nowrap vs-btn"
          size="small"
          (click)="submit(true)"
          [disabled]="this.canSendTimeOut > 0"
          color="primary"
        >
          <span *ngIf="this.canSendTimeOut <= 0; else timeoutTimer">Resend Email.</span>
          <ng-template #timeoutTimer>
            <span>Resend available in {{ this.canSendTimeOut }}</span>
          </ng-template>
        </button>
      </p>
    </div>
  </div>
  <div class="w-100 text-center mt-4">
    <button
      type="button"
      class="flex-1 whitespace-nowrap vs-btn"
      size="small"
      (click)="sent = false; signInError.emit('')"
    >
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.8334 6.99996H1.16675M1.16675 6.99996L7.00008 12.8333M1.16675 6.99996L7.00008 1.16663"
          stroke="#475467"
          stroke-width="1.66667"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      Back
    </button>
  </div>
</ng-template>
