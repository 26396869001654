<vs-app-page
  *ngIf="payment$ | async as payment"
  [config]="{
    title: payment.title,
    description: 'This is a preview of all details related to this transaction.'
  }"
>
  <vs-breadcrumbs
    slot="breadcrumbs"
    [config]="{
      items: [
        { label: 'Finances', routerLink: '..' },
        { label: payment.title }
      ]
    }"
  ></vs-breadcrumbs>

  <vs-badge
    *ngIf="paymentStatus$ | async as status"
    [config]="{ displayStatusIcon: true }"
    [theme]="status.theme"
    slot="title-end"
  >
    {{ status.label }}
  </vs-badge>

  <vs-divider class="mb-8"></vs-divider>

  <vs-card class="my-6 overflow-hidden">
    <vs-card-content class="!p-6">
      <ws-payment-invoice
        *ngIf="payment.user && payment.taxes"
        [payment]="$any(payment)"
        [hideResendReminderButton]="true"
        [showPaymentMethod]="true"
        (refresh)="refresh(payment.id)"
      ></ws-payment-invoice>
    </vs-card-content>
  </vs-card>

  <ng-container *ngIf="relatedPayments$ | async as relatedPayments">
    <vs-card *ngIf="relatedPayments.length" class="my-6 overflow-hidden">
      <vs-card-header class="flex items-end justify-between" [config]="{ title: 'Related Transactions' }">
      </vs-card-header>

      <vs-table [columns]="columns" [data]="relatedPayments" (rowClicked)="rowClicked($event)"></vs-table>
      <vs-pagination
        [config]="pagination"
        (pagination)="paginationQueryRequest$.next({ page: $event.currentPage, limit: $event.itemsPerPage })"
        class="flex items-end sm: flex-col md:flex-row lg:flex-row"
      ></vs-pagination>
    </vs-card>
  </ng-container>
</vs-app-page>
