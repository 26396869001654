<ng-container *ngIf="warranty$ | async as warranty">
  <div class="flex min-h-full flex-col gap-28">
    <ng-container [ngSwitch]="step">
      <div class="flex items-center flex-grow" *ngSwitchDefault>
        <div class="flex flex-col w-full justify-center items-center h-full px-8 gap-6">
          <vs-icon name="cursor-click-01" class="text-primary-500" size="129"></vs-icon>
          <div class="text-3xl font-semibold text-gray-900">Welcome to your portal!</div>
          <div class="text-xl text-center text-gray-600">
            Let's activate your new guarantee.
            <br />
            <br />
            To active your guarantee please answer the questions provided in this form. We already have some details,
            but we'd rather make sure everything is good.
          </div>
          <button vs-button theme="primary" (click)="nextPage(undefined, warranty)">Let's Begin</button>
        </div>
      </div>
      <form
        [formGroup]="activateWarrantyFormGroup"
        class="flex flex-grow items-center px-8"
        [class.hidden]="step === 0 || step === lastStep"
      >
        <ng-container *ngSwitchCase="1">
          <div class="flex flex-col">
            <div class="flex gap-6">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{step}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>
              <div class="flex flex-col gap-3">
                <div class="text-3xl font-semibold text-gray-900">Do we have the correct name on file? *</div>
                <div class="text-xl text-gray-600">{{warranty.customer?.name}}</div>
                <label
                  vsRadioLabel
                  for="accept-evaluate-customer-name"
                  class="flex bg-base rounded-xl items-center p-4 gap-2 group max-w-xs"
                  [class.bg-primary-50]="activateWarrantyFormGroup.controls.evaluateCustomerName.value === true"
                  [class.border]="activateWarrantyFormGroup.controls.evaluateCustomerName.value === true"
                  [class.border-primary-500]="activateWarrantyFormGroup.controls.evaluateCustomerName.value === true"
                >
                  <input
                    vsInput
                    class="!rounded-full"
                    type="radio"
                    id="accept-evaluate-customer-name"
                    name="evaluateCustomerName"
                    [value]="true"
                    formControlName="evaluateCustomerName"
                  />
                  <span>Yes</span>
                </label>
                <label
                  vsRadioLabel
                  for="reject-evaluate-customer-name"
                  class="flex bg-base rounded-xl items-center p-4 gap-2 group max-w-xs"
                  [class.bg-primary-50]="activateWarrantyFormGroup.controls.evaluateCustomerName.value === false"
                  [class.border]="activateWarrantyFormGroup.controls.evaluateCustomerName.value === false"
                  [class.border-primary-500]="activateWarrantyFormGroup.controls.evaluateCustomerName.value === false"
                >
                  <input
                    vsInput
                    type="radio"
                    class="!rounded-full"
                    id="reject-evaluate-customer-name"
                    [name]="'evaluateCustomerName'"
                    [value]="false"
                    formControlName="evaluateCustomerName"
                  />
                  <span>No</span>
                </label>
                <div class="flex gap-2">
                  <button
                    [disabled]="!activateWarrantyFormGroup.controls.evaluateCustomerName.valid"
                    vs-button
                    theme="primary"
                    (click)="nextPage(!activateWarrantyFormGroup.controls.evaluateCustomerName.value, warranty)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button vs-button theme="default" appearance="outline" (click)="previousPage()">Back</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="1.5">
          <div class="flex flex-col flex-grow">
            <div class="flex gap-6 flex-grow">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{step}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>
              <div class="flex flex-col gap-3 flex-grow">
                <div class="text-3xl font-semibold text-gray-900">What is your full name? *</div>
                <input
                  type="text"
                  vsInput
                  name="customerName"
                  formControlName="customerName"
                  placeholder="Enter your name"
                />
                <div class="flex gap-2">
                  <button
                    [disabled]="!activateWarrantyFormGroup.controls.customerName.valid"
                    vs-button
                    theme="primary"
                    (click)="nextPage(true, warranty)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button vs-button theme="default" appearance="outline" (click)="previousPage(true)">Back</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <div class="flex flex-col">
            <div class="flex gap-6">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{step}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>
              <div class="flex flex-col gap-3">
                <div class="text-3xl font-semibold text-gray-900">Do we have the correct email on file? *</div>
                <div class="text-xl text-gray-600">{{warranty.customer?.email}}</div>
                <label
                  vsRadioLabel
                  for="accept-evaluate-customer-email"
                  class="flex bg-base rounded-xl items-center p-4 gap-2 group max-w-xs"
                  [class.bg-primary-50]="activateWarrantyFormGroup.controls.evaluateCustomerEmail.value === true"
                  [class.border]="activateWarrantyFormGroup.controls.evaluateCustomerEmail.value === true"
                  [class.border-primary-500]="activateWarrantyFormGroup.controls.evaluateCustomerEmail.value === true"
                >
                  <input
                    vsInput
                    type="radio"
                    class="!rounded-full"
                    id="accept-evaluate-customer-email"
                    [name]="'evaluateCustomerEmail'"
                    [value]="true"
                    formControlName="evaluateCustomerEmail"
                  />
                  <span>Yes</span>
                </label>
                <label
                  vsRadioLabel
                  for="reject-evaluate-customer-email"
                  class="flex bg-base rounded-xl items-center p-4 gap-2 group max-w-xs"
                  [class.bg-primary-50]="activateWarrantyFormGroup.controls.evaluateCustomerEmail.value === false"
                  [class.border]="activateWarrantyFormGroup.controls.evaluateCustomerEmail.value === false"
                  [class.border-primary-500]="activateWarrantyFormGroup.controls.evaluateCustomerEmail.value === false"
                >
                  <input
                    vsInput
                    type="radio"
                    class="!rounded-full"
                    id="reject-evaluate-customer-email"
                    [name]="'evaluateCustomerEmail'"
                    [value]="false"
                    formControlName="evaluateCustomerEmail"
                  />
                  <span>No</span>
                </label>
                <div class="flex gap-2">
                  <button
                    [disabled]="!activateWarrantyFormGroup.controls.evaluateCustomerEmail.valid"
                    vs-button
                    theme="primary"
                    (click)="nextPage(!activateWarrantyFormGroup.controls.evaluateCustomerEmail.value, warranty)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button
                    vs-button
                    theme="default"
                    appearance="outline"
                    (click)="previousPage(!activateWarrantyFormGroup.controls.evaluateCustomerName.value)"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="2.5">
          <div class="flex flex-col flex-grow">
            <div class="flex gap-6 flex-grow">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{step}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>
              <div class="flex flex-col gap-3 flex-grow">
                <div class="text-3xl font-semibold text-gray-900">What is your email address? *</div>
                <vs-input-container
                  class="group"
                  [theme]="activateWarrantyFormGroup.controls.customerEmail.touched && !activateWarrantyFormGroup.controls.customerEmail.valid ? 'danger' : 'primary'"
                >
                  <vs-icon name="mail-01" slot="start" size="16"></vs-icon>
                  <input
                    type="text"
                    name="customerEmail"
                    formControlName="customerEmail"
                    placeholder="name@email.com"
                  />
                  <vs-icon
                    class="text-danger group-invalid-within:inline-block"
                    name="info-circle"
                    slot="end"
                    *ngIf="activateWarrantyFormGroup.controls.customerEmail.touched && !activateWarrantyFormGroup.controls.customerEmail.valid"
                  ></vs-icon>
                </vs-input-container>
                <span
                  class="text-danger"
                  *ngIf="activateWarrantyFormGroup.controls.customerEmail.touched && activateWarrantyFormGroup.controls.customerEmail.hasError('email')"
                >
                  Please enter a valid email
                </span>
                <span class="flex items-center gap-2 text-danger">
                  <vs-icon class="text-danger" name="info-circle"></vs-icon>
                  <span>You will have to sign-in again if the email is updated</span>
                </span>
                <div class="flex gap-2">
                  <button
                    [disabled]="!activateWarrantyFormGroup.controls.customerEmail.valid"
                    vs-button
                    theme="primary"
                    (click)="nextPage(true, warranty)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button vs-button theme="default" appearance="outline" (click)="previousPage(true)">Back</button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <ng-container
            *ngIf="!warranty.customer?.phone; else withPhoneNumber"
            [ngTemplateOutlet]="missingPhoneNumber"
            [ngTemplateOutletContext]="{missingValue: false}"
          ></ng-container>
          <ng-template #withPhoneNumber>
            <div class="flex flex-col">
              <div class="flex gap-6">
                <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                  {{step}}
                  <vs-icon name="arrow-right"></vs-icon>
                </div>
                <div class="flex flex-col gap-3">
                  <div class="text-3xl font-semibold text-gray-900">Do we have the correct phone number on file? *</div>
                  <div class="text-xl text-gray-600">{{(warranty.customer?.phone ?? '') | phone}}</div>
                  <label
                    vsRadioLabel
                    for="accept-evaluate-customer-phone-number"
                    class="flex bg-base rounded-xl items-center p-4 gap-2 group max-w-xs"
                    [class.bg-primary-50]="activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value === true"
                    [class.border]="activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value === true"
                    [class.border-primary-500]="activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value === true"
                  >
                    <input
                      vsInput
                      type="radio"
                      class="!rounded-full"
                      id="accept-evaluate-customer-phone-number"
                      [name]="'evaluateCustomerPhoneNumber'"
                      [value]="true"
                      formControlName="evaluateCustomerPhoneNumber"
                    />
                    <span>Yes</span>
                  </label>
                  <label
                    vsRadioLabel
                    for="reject-evaluate-customer-phone-number"
                    class="flex bg-base rounded-xl items-center p-4 gap-2 group max-w-xs"
                    [class.bg-primary-50]="activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value === false"
                    [class.border]="activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value === false"
                    [class.border-primary-500]="activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value === false"
                  >
                    <input
                      vsInput
                      type="radio"
                      class="!rounded-full"
                      id="reject-evaluate-customer-phone-number"
                      [name]="'evaluateCustomerPhoneNumber'"
                      [value]="false"
                      formControlName="evaluateCustomerPhoneNumber"
                    />
                    <span>No</span>
                  </label>
                  <div class="flex gap-2">
                    <button
                      [disabled]="!activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.valid"
                      vs-button
                      theme="primary"
                      (click)="nextPage(!activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value, warranty)"
                    >
                      Next
                      <vs-icon name="arrow-right"></vs-icon>
                    </button>
                    <button
                      vs-button
                      theme="default"
                      appearance="outline"
                      (click)="previousPage(!activateWarrantyFormGroup.controls.evaluateCustomerEmail.value)"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container
          *ngSwitchCase="3.5"
          [ngTemplateOutlet]="missingPhoneNumber"
          [ngTemplateOutletContext]="{missingValue: true}"
        >
        </ng-container>
        <ng-template #missingPhoneNumber let-missingValue="missingValue">
          <div class="flex flex-col flex-grow">
            <div class="flex gap-6 flex-grow">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{step}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>
              <div class="flex flex-col gap-3 flex-grow">
                <div class="text-3xl font-semibold text-gray-900">What is your phone number? *</div>
                <vs-input-container
                  class="group"
                  [theme]="activateWarrantyFormGroup.controls.customerPhoneNumber.touched && !activateWarrantyFormGroup.controls.customerPhoneNumber.valid ? 'danger' : 'primary'"
                >
                  <input
                    type="text"
                    name="customerPhoneNumber"
                    formControlName="customerPhoneNumber"
                    (keyup)="phoneNumberKeyUp($event); validateNumber()"
                    (blur)="formatPhoneNumber(); validateNumber()"
                    placeholder="(___) ___-____"
                  />
                  <vs-icon
                    class="text-danger group-invalid-within:inline-block"
                    name="info-circle"
                    slot="end"
                    *ngIf="activateWarrantyFormGroup.controls.customerPhoneNumber.touched && !activateWarrantyFormGroup.controls.customerPhoneNumber.valid"
                  ></vs-icon>
                </vs-input-container>
                <span
                  class="text-danger"
                  *ngIf="activateWarrantyFormGroup.controls.customerPhoneNumber.touched && !validNumber"
                >
                  Please enter a valid phone number
                </span>
                <div class="flex gap-2">
                  <button
                    [disabled]="!activateWarrantyFormGroup.controls.customerPhoneNumber.valid || !validNumber"
                    vs-button
                    theme="primary"
                    (click)="nextPage(missingValue, warranty)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button vs-button theme="default" appearance="outline" (click)="previousPage(missingValue)">
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-container *ngSwitchCase="4">
          <div class="flex flex-col flex-grow">
            <div class="flex gap-6 flex-grow">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{step}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>
              <div class="flex flex-col gap-3 flex-grow">
                <div class="text-3xl font-semibold text-gray-900">When did you take possession of your new home? *</div>
                <div class="text-xl text-gray-600">
                  This may also be referred to as the <span class="font-semibold">'Closing Date'</span> of your
                  transaction.
                </div>
                <vs-input-container>
                  <input
                    #dateInput
                    formControlName="closingDate"
                    class="picker-indicator-hidden"
                    type="date"
                    placeholder="Select a closing date"
                  />
                  <button
                    vs-icon-button
                    size="xs"
                    appearance="clear"
                    slot="end"
                    [vsMenuTriggerFor]="dateMenu"
                    #trigger="vsMenuTriggerFor"
                  >
                    <vs-icon vsTooltip="Open date picker" name="calendar" size="16"></vs-icon>
                  </button>
                </vs-input-container>
                <ng-template #dateMenu>
                  <vs-menu>
                    <vs-date-picker
                      vs-menu-item
                      (selectionChanged)="populateDate($any($event), trigger);"
                    ></vs-date-picker>
                  </vs-menu>
                </ng-template>
                <div class="flex gap-2">
                  <button
                    [disabled]="!activateWarrantyFormGroup.controls.closingDate.valid"
                    vs-button
                    theme="primary"
                    (click)="nextPage(undefined, warranty)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                  <button
                    vs-button
                    *vsLet="(warranty.customer && warranty.customer.phone) as hasPhoneNumber"
                    theme="default"
                    appearance="outline"
                    (click)="previousPage((!activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value && $any(hasPhoneNumber)))"
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
      <ng-container *ngSwitchCase="5">
        <div class="flex flex-grow flex-col w-full justify-center items-center h-full px-8 gap-6">
          <vs-icon name="check-circle-broken" class="text-primary-500" size="129"></vs-icon>
          <div class="text-3xl font-semibold text-gray-900">
            Thank you for activating your {{warranty.policy?.title || 'warranty'}}!
          </div>
          <div class="text-xl text-center text-gray-600">
            You can now review your warranty details.
            <br /><br />
            <span class="text-xl text-base-black font-bold">You will be redirected to the portal shortly...</span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
