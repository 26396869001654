import { Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Asset } from '@wsphere/assets/domain';
import { AssetService } from '@wsphere/assets/web';
import { Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { BehaviorSubject, map, Observable, ReplaySubject, switchMap } from 'rxjs';

@Component({
  selector: 'ws-warranty-status-drop-down',
  templateUrl: './warranty-status-drop-down.component.html',
})
export class WarrantyStatusDropDownComponent {
  constructor(private assetSvc: AssetService) {}

  @Input() warrantyStatus?: Warranty.Status;

  @Input() warrantyTermDuration?: number;

  @Input() warrantyValidity?: string;

  @Input() warrantyStartDate?: Date;

  @Input() transactionCloseDate?: Date;

  @Input() propertySetId?: string;

  @PropertyListener('expiryDate') expiryDate$: Observable<Date> = new ReplaySubject();
  @Input() expiryDate?: Date;

  @PropertyListener('asset') asset$ = new BehaviorSubject<Asset.Model | undefined>(undefined);
  @Input() asset?: Asset.Model;

  offset = 0;
  isOpen = false;

  //number of days from current date that the warranty expires
  daysToExpiry$ = this.expiryDate$.pipe(
    map(expiryDate => {
      const oneDayInMs = 86400000;
      return Math.floor(moment(expiryDate).diff(new Date()) / oneDayInMs);
    })
  );

  propertyValues$ = this.asset$.pipe(
    switchMap(async asset => {
      if (!asset) return null;

      return (await this.assetSvc.getAssetValues(asset.id))?.value ?? null;
    })
  );

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }
}
