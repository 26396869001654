import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Config } from '@vsolv/packages/portal-config/domain';

@Injectable()
export class ConfigResolver implements Resolve<Config.PortalConfig | undefined> {
  config?: Config.PortalConfig;

  resolve() {
    return this.config;
  }
}
