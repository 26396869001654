import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AddressModule } from '@vsolv/core/address/web';
import { MultiTenancyWebModule } from '@vsolv/core/multi-tenant/web';
import { UsersWebModule } from '@vsolv/core/users/web';
import { ApiInterceptorModule } from '@vsolv/dev-kit/ngx';
import { FirebaseWebModule } from '@vsolv/packages/firebase/web';
import { PaymentMethodModule, PaymentModule } from '@vsolv/packages/payments/web';
import { ReimbursementWebModule } from '@vsolv/packages/reimbursement/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import { CustomerPortalModule } from '@wsphere/customer-portal/web';
import { LinksWebModule } from '@wsphere/links/web';
import { SalesStaffPagesModule } from '@wsphere/sales/web';
import {
  ClaimModule,
  ProvisioningWebModule,
  SessionAddonGuard,
  SessionAssetGuard,
  SessionCheckoutGuard,
  SessionGuard,
  SessionPlanGuard,
  SessionPolicyGuard,
  SessionShippingAddressGuard,
  WarrantyModule,
} from '@wsphere/warranties/web';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgStringPipesModule } from 'ngx-pipes';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { ConfigGuard, ConfigResolver, SignInGuard, TenantIdGuard } from './guards';
import { PaymentGuard } from './guards/payment.guard';
import { CustomerPortalLayout } from './layout/layout.component';
import {
  CustomerFinancesPage,
  IntercomPage,
  LoginPage,
  NotFoundPage,
  PrivacyPolicyPage,
  ProvisionPage,
  TermsOfServicePage,
  TransactionPage,
  WarrantyPage,
  WelcomePage,
} from './pages';
import { CustomerResolver, PaymentResolver } from './resolvers';

import { DowntimeWebModule } from '@vsolv/core/downtime/web';
import {
  AddressPropertyInputComponent,
  HomeInspectionPropertyInputComponent,
  PropertiesWebModule,
  VehiclePropertyInputComponent,
} from '@vsolv/packages/properties/web';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { StepperModule } from '@vsolv/vectors-ui/stepper';
@NgModule({
  bootstrap: [AppComponent],
  providers: [
    ConfigGuard,
    ConfigResolver,
    TenantIdGuard,
    Title,
    SignInGuard,
    CustomerResolver,
    CurrencyPipe,
    PaymentGuard,
    PaymentResolver,
    SessionGuard,
    SessionPolicyGuard,
    SessionAssetGuard,
    SessionShippingAddressGuard,
    SessionPlanGuard,
    SessionAddonGuard,
    SessionCheckoutGuard,
    provideEnvironmentNgxMask(),
  ],
  declarations: [
    AppComponent,
    NotFoundPage,
    CustomerPortalLayout,
    WarrantyPage,
    PrivacyPolicyPage,
    TermsOfServicePage,
    WelcomePage,
    LoginPage,
    CustomerFinancesPage,
    TransactionPage,
    ProvisionPage,
    IntercomPage,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ScrollingModule,
    UtilsModule,
    NgStringPipesModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,

    ApiInterceptorModule.forRoot(environment.apiBaseUrl, 'customer-portal'),
    DowntimeWebModule.forRoot(),
    MultiTenancyWebModule.forRoot(environment.apiMode),
    FirebaseWebModule.forRoot(environment.firebase),
    ReimbursementWebModule.forRoot({ mode: environment.dwollaMode }),

    CardModule,
    InputModule,
    ButtonModule,
    ThemingModule,
    FormsModule,
    IconsModule,
    DividerModule,
    TableModule,
    BadgeModule,
    TooltipModule,

    AppRoutingModule,
    CustomerPortalModule.forRoot({ mode: environment.dwollaMode }),
    WarrantyModule,
    ClaimModule,
    OverlayModule,
    UsersWebModule,
    ProvisioningWebModule,
    AppPageModule,
    BreadcrumbsModule,
    UserAvatarModule,

    SalesStaffPagesModule,
    PaymentModule,
    PaymentMethodModule,
    LinksWebModule,
    PropertiesWebModule.forRoot([
      {
        id: 'address',
        title: 'Address',
        component: AddressPropertyInputComponent,
      },
      {
        id: 'home_inspection',
        title: 'Home Inspection',
        component: HomeInspectionPropertyInputComponent,
      },
      {
        id: 'road_vehicle',
        title: 'Road Vehicle',
        component: VehiclePropertyInputComponent, // create new input component for cars and use it here
      },
    ]),

    AddressModule.forRoot({ apikey: environment.googleMapsKey }),
    StepperModule,
    DialogModule,
  ],
})
export class AppModule {}
