<vs-card *ngIf="claim">
  <ng-container *vsLet="noteAndLinks$ | async as groups">
    <vs-card-header [config]="{ title: 'Notes', subtitle: 'Review notes left for you by your administrator.' }">
    </vs-card-header>

    <vs-card-content *ngIf="groups?.length; else noNotes">
      <ng-container *ngFor="let group of groups; index as index">
        <div *ngIf="group.note as note" class="flex flex-col gap-4">
          <div class="flex justify-between">
            <div class="flex gap-4">
              <div class="flex gap-1">
                <vs-user-avatar
                  class="w-5 h-5 self-center"
                  [config]="{ photoUrl: note?.createdBy?.photoURL }"
                ></vs-user-avatar>

                <p class="text-sm font-medium text-gray-600 self-center">{{ note?.createdBy?.displayName }}</p>
              </div>

              <div class="flex gap-1">
                <vs-icon name="calendar-date" class="self-center text-gray-600"></vs-icon>
                <p class="text-sm font-medium text-gray-600 self-center whitespace-nowrap">
                  {{ note?.created | date }}
                </p>
              </div>

              <div class="flex gap-1">
                <vs-icon name="clock" class="self-center text-gray-600"></vs-icon>
                <p class="text-sm font-medium text-gray-600 self-center whitespace-nowrap">
                  {{ note?.modified | date : 'h:mm a' }}
                </p>
              </div>
            </div>
          </div>

          <p *ngIf="note.title" class="text-md font-semibold text-gray-900">{{ note.title }}</p>

          <vs-rich-text-editor
            class="-my-4"
            [disabled]="true"
            [value]="note.content"
            [config]="{ hideToolbar: true, hideBorder: true }"
          ></vs-rich-text-editor>

          <div class="flex flex-wrap gap-2">
            <ng-container *ngFor="let linkAndConfig of group.links">
              <ng-container *vsLet="linkAndConfig.link as link">
                <vs-info-card
                  *ngIf="link?.claimId_1 !== claim.id && link?.claimId_2 !== claim.id && !link?.activityId_1"
                  class="flex-grow max-w-[50%] rounded-lg transition-all"
                  [config]="linkAndConfig.config"
                  [class]="link?.document_1 ? 'hover:shadow cursor-pointer' : ''"
                  (click)="link?.document_1 ? openDocumentDialog(claim, $any(link?.document_1)) : ''"
                >
                  <vs-badge
                    *ngIf="link?.claimItem_1 || link?.claimItem_2"
                    slot="header-end"
                    [theme]="getItemTheme(link?.claimItem_1 || link?.claimItem_2 || undefined)"
                  >
                    {{ link?.claimItem_1?.status || link?.claimItem_2?.status | titlecase }}
                  </vs-badge>
                </vs-info-card>
              </ng-container>
            </ng-container>
          </div>

          <vs-divider *ngIf="index < (groups?.length || 0) - 1" class="my-4"></vs-divider>
        </div>
      </ng-container>
    </vs-card-content>

    <ng-template #noNotes>
      <vs-card-content class="!pt-0 overflow-hidden">
        <vs-icon-empty-state
          name="sticker-square"
          [config]="{
            title: 'No notes have been left for you.',
            subtitle: 'If we leave you any notes about your claim they will appear here.'
          }"
        >
        </vs-icon-empty-state>
      </vs-card-content>
    </ng-template>
  </ng-container>
</vs-card>

<ws-view-claim-document-dialog #documentDialog [isCustomerPortal]="true"></ws-view-claim-document-dialog>
