import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FundingSource } from '@vsolv/dwolla/domain';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Claim } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-claim-reimbursement-card',
  templateUrl: './claim-reimbursement-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimReimbursementCardComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  @Input() payment: Claim.ClaimPayment.Model | null = null;
  @Input() destinations: FundingSource[] | null = null;

  getBadgeTheme(): ThemeColor {
    if (!this.payment) return 'default';
    if (this.payment.destination === 'PENDING') return 'warn';

    switch (this.payment.status) {
      case Claim.ClaimPayment.Status.COMPLETED:
      case Claim.ClaimPayment.Status.ISSUED:
        return 'success';
      case Claim.ClaimPayment.Status.FAILED:
        return 'danger';
      case Claim.ClaimPayment.Status.VOIDED:
        return 'danger';
      default:
        return 'default';
    }
  }

  navigateTo() {
    this.router.navigate([`manage-reimbursement-methods`], { relativeTo: this.route });
  }
}
