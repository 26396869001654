import { Component, EventEmitter, Output } from '@angular/core';
import { distinctUntilChanged, map } from 'rxjs';
import { FirebaseService } from '../../services';

@Component({
  selector: 'vs-sign-out-button',
  templateUrl: './sign-out-button.component.html',
})
export class SignOutButtonComponent {
  @Output() signedOut = new EventEmitter<void>();

  readonly isSignedIn$ = this.firebaseSvc.getAuthId$().pipe(
    distinctUntilChanged(),
    map(id => !!id)
  );

  constructor(private firebaseSvc: FirebaseService) {}

  async signOut() {
    await this.firebaseSvc.signOut();
    this.signedOut.emit();
  }
}
