<div class="flex h-screen flex-col-reverse lg:flex-row relative overflow-hidden">
  <div
    class="lg:max-w-[420px] lg:min-w-[420px] xl:max-w-[480px] xl:min-w-[480px] 2xl:max-w-[512px] 2xl:min-w-[512px] p-4 lg:p-10 bg-gradient-to-br from-primary-500 to-primary-600 lg:h-auto lg:min-h-[691px] w-full"
    [class]="
      drawerOpen
        ? 'lg:relative absolute bottom-0 z-10 max-h-screen lg:overflow-visible overflow-auto'
        : 'relative h-6 lg:overflow-visible overflow-hidden'
    "
  >
    <div class="flex flex-col justify-between h-full">
      <div>
        <ws-portal-info-card [class.hidden]="!drawerOpen" class="lg:block"></ws-portal-info-card>

        <p class="mx-4 mt-4 text-sm font-medium text-base">
          {{ portalSvc.config.brandName }}'s claim processing is made possible by Obligo<sup>TM</sup>
          <a
            #link
            class="ml-3 cursor-pointer opacity-75 hover:opacity-100"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            (click)="open = !overlay.open"
          >
            <span>Learn more</span>
            <svg
              class="inline align-middle ml-1 w-4 h-4 -mt-[2px]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </a>

          <ng-template
            #overlay="cdkConnectedOverlay"
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="open"
            (overlayOutsideClick)="$event.target !== link ? (open = false) : ''"
          >
            <div
              class="mt-1 z-10 overflow-hidden max-w-[320px] bg-base rounded divide-y divide-gray-200 text-gray-900 shadow text-sm font-medium"
            >
              <a
                class="flex items-center py-2 px-4 w-full hover:bg-gray-100 hover:text-primary-600"
                routerLink="/privacy"
                target="_blank"
              >
                <svg
                  class="mr-2 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  ></path>
                </svg>
                <span>Privacy Policy</span>
              </a>
              <a
                class="flex items-center py-2 px-4 w-full hover:bg-gray-100 hover:text-primary-600"
                routerLink="/terms-of-service"
                target="_blank"
              >
                <svg
                  class="mr-2 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  ></path>
                </svg>
                <span>Terms of Use</span>
              </a>
            </div>
          </ng-template>
        </p>
        <div *ngIf="isSignedIn$ | async" class="grid my-5 gap-4 w-full">
          <ng-container *ngIf="(hasWarranties$ | async) || (hasPayments$ | async)">
            <vs-divider class="!bg-[#f8fafc] !opacity-50 z-50"></vs-divider>
            <button
              *ngIf="hasWarranties$ | async"
              routerLink="/warranty"
              vs-button
              appearance="clear"
              [class]="
                checkLink('warranty')
                  ? '!transition duration-700 !h-8 !w-full !text-base-white !bg-primary-400 !justify-start !font-semibold hover:!bg-base-white hover:!text-primary-400 z-50'
                  : '!transition duration-700 !h-8 !w-full !text-primary-200 !justify-start hover:!bg-base-white !font-semibold group z-50'
              "
            >
              <vs-icon class="ml-3" name="folder-shield"></vs-icon>
              <p class="group-hover:!text-base-black">My coverage</p>
            </button>
            <button
              *ngIf="hasPayments$ | async"
              routerLink="/finances"
              vs-button
              appearance="clear"
              [class]="
                checkLink('finances')
                  ? '!transition duration-700 !h-8 !w-full !text-base-white !bg-primary-400 !justify-start !font-semibold hover:!bg-base-white hover:!text-primary-400 z-50'
                  : '!transition duration-700 !h-8 !w-full !text-primary-200 !justify-start  !font-semibold hover:!bg-base-white group z-50'
              "
            >
              <vs-icon class="ml-3" name="credit-card-shield"></vs-icon>
              <p class="group-hover:!text-base-black">Billing history</p>
            </button>
          </ng-container>
          <button
            routerLink="/checkout"
            vs-button
            class="!w-full !h-[44px] !text-primary-700 !bg-primary-50 !border-primary-50 !font-semibold z-50"
            *ngIf="!checkLink('checkout') && (policiesAvailable$ | async)"
          >
            <vs-icon name="folder-plus"></vs-icon> New coverage
          </button>
          <vs-divider class="!bg-[#f8fafc] !opacity-50 z-50"></vs-divider>
        </div>
        <vs-sign-out-button (signedOut)="signedOut()"></vs-sign-out-button>
      </div>
      <!-- <div class="mx-4 mt-4 text-sm font-medium text-base flex justify-around">
        <a class="px-4 py-2 opacity-75 hover:opacity-100 focus:opacity-100" routerLink="/privacy">
          <span>Privacy Policy</span>
        </a>
        <a class="px-4 py-2 opacity-75 hover:opacity-100 focus:opacity-100" routerLink="/terms-of-service">
          <span>Terms of Service</span>
        </a>
      </div> -->
      <div
        class="hidden lg:block lg:translate-x-14 xl:translate-x-16 2xl:translate-x-20 2xl:scale-105 origin-right translate-y-16 2xl:translate-y-14"
      >
        <!-- <svg
          class="w-full h-full max-h-96"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="490.31761"
          height="471.49999"
          viewBox="0 0 490.31761 471.49999"
        >
          <g>
            <path
              d="M375.32036,471.49999l3.40228-63.28253s111.59497-23.13556,111.59497-89.82037-182.36255-74.85031-200.73486-44.22974c-.18372,.30621-.36633,.61237-.54779,.91843-22.69257,38.27042-9.88062,87.95544,28.73438,110.05649,13.20422,7.55737,28.94763,14.02435,47.34418,17.6315l-1.29584,67.2262"
              class="fill-gray-300"
            />
            <circle cx="271.81761" cy="63" r="63" class="fill-primary-400" />
            <path
              d="M178.87596,456.49895l-71.05322-4.5293-3.83154-319.50098c-.09277-7.75488,2.99902-15.34863,8.4834-20.83301L188.5952,35.51506c10.65576-10.65479,27.42725-11.4248,39.01318-1.78662l82.60742,68.71826c6.73926,5.60596,10.54541,13.84863,10.44189,22.61427l-2.83838,240.28076,35.90381,49.86621,.07617,.10547,6.0835,52.72363-181.00684-11.53809Z"
              class="fill-gray-200"
            />
            <path
              d="M251.45213,134.41953v-14.81543c0-3.91064,3.18164-7.09229,7.09229-7.09229h20.81592c3.91064,0,7.0918,3.18164,7.0918,7.09229v14.81543c0,3.91064-3.18115,7.09229-7.0918,7.09229h-20.81592c-3.91064,0-7.09229-3.18164-7.09229-7.09229Z"
              class="fill-primary-400"
            />
            <path
              d="M216.47411,353.21865c-3.72461-.13184-6.72803-3.1543-6.83838-6.87988l-1.55811-52.98145c-.06641-2.26172,.9126-4.36328,2.68604-5.76855,1.77344-1.4043,4.04443-1.87305,6.23096-1.29297l1.55811,.41602c3.09961,.82715,5.26465,3.64453,5.26465,6.85254v52.56641c0,1.94141-.76953,3.75391-2.1665,5.10254-1.33496,1.28906-3.07275,1.98926-4.91846,1.98926-.08594,0-.17188-.00098-.2583-.00391Z"
              class="fill-gray-800"
            />
            <path
              d="M151.81743,345.90813v-54.97461c0-3.88086,3.03955-6.99512,6.91943-7.09082l28.0083-.68262c3.88232-.05371,7.14893,2.99316,7.2627,6.88477l1.61328,55.65723c.05566,1.93262-.65527,3.76074-2.00293,5.14746-1.34668,1.38672-3.15332,2.15039-5.08643,2.15039h-29.62207c-3.91064,0-7.09229-3.18164-7.09229-7.0918Z"
              class="fill-gray-800"
            />
            <path
              d="M317.36516,383.47341l21.95245,39.02658,2.52002,43.89001,17.47998,1.10999-6-52-36-50h0c-3.10706,5.59271-3.08905,12.39722,.04755,17.97342Z"
              class="fill-gray-300"
            />
            <rect
              x="269.89046"
              y="454.31523"
              width="71.25525"
              height="1.00009"
              transform="translate(-6.18903 4.21911) rotate(-.78329)"
              class="fill-gray-800"
            />
            <rect
              x="269.26167"
              y="445.26647"
              width="67.55676"
              height="1.00003"
              transform="translate(-3.47316 2.38101) rotate(-.44761)"
              class="fill-gray-800"
            />
            <rect
              x="268.69155"
              y="436.6149"
              width="64.63184"
              height="1.00018"
              transform="translate(-8.47841 5.9613) rotate(-1.11892)"
              class="fill-gray-800"
            />
            <rect
              x="269.24518"
              y="428.04499"
              width="62.08985"
              height="1.00055"
              transform="translate(-14.05581 10.20111) rotate(-1.9017)"
              class="fill-gray-800"
            />
            <rect
              x="268.67795"
              y="420.0766"
              width="59.659"
              height="1.00061"
              transform="translate(-14.59251 10.74761) rotate(-2.01348)"
              class="fill-gray-800"
            />
            <rect x="266.2462" y="412.00033" width="58.07129" height="1" class="fill-gray-800" />
            <rect
              x="264.87917"
              y="405.3138"
              width="56.44"
              height="1.00006"
              transform="translate(-4.73515 3.46236) rotate(-.6714)"
              class="fill-gray-800"
            />
            <rect
              x="263.61627"
              y="398.89655"
              width="54.71588"
              height="1.00055"
              transform="translate(-13.09366 9.87591) rotate(-1.9017)"
              class="fill-gray-800"
            />
            <rect
              x="262.31288"
              y="391.50023"
              width="54.00928"
              height="1.00015"
              transform="translate(-6.84489 5.14543) rotate(-1.00705)"
              class="fill-gray-800"
            />
            <rect
              x="260.59824"
              y="384.90729"
              width="51.73132"
              height="1.00049"
              transform="translate(-11.89833 9.13568) rotate(-1.78991)"
              class="fill-gray-800"
            />
            <rect
              x="259.06626"
              y="378.25032"
              width="51.25244"
              height="1.00006"
              transform="translate(-3.68497 2.79812) rotate(-.55951)"
              class="fill-gray-800"
            />
            <path
              d="M255.31743,357.40813v-67.81543c0-3.91113,3.18164-7.09277,7.09229-7.09277h32.81592c3.91064,0,7.0918,3.18164,7.0918,7.09277v67.81543c0,3.91016-3.18115,7.0918-7.0918,7.0918h-32.81592c-3.91064,0-7.09229-3.18164-7.09229-7.0918Z"
              class="fill-primary-400"
            />
            <path
              d="M178.87596,456.49895l-58.09033-3.7002-.46826-.03027v-44.95703c0-5.68555,4.62549-10.31152,10.31104-10.31152h57.37793c5.68555,0,10.31104,4.62598,10.31104,10.31152v48.68848h-.5l-18.94141-.00098Z"
              class="fill-gray-300"
            />
            <path
              d="M259.31743,234.40812v-51.81592c0-3.91064,3.18164-7.09229,7.09229-7.09229h10.81592c3.91064,0,7.0918,3.18164,7.0918,7.09229v51.81592c0,3.91016-3.18115,7.0918-7.0918,7.0918h-10.81592c-3.91064,0-7.09229-3.18164-7.09229-7.0918Z"
              class="fill-gray-800"
            />
            <path
              d="M270.31761,461.49999l-3-44-9-42-2.5-11.5h0c-5.13733-3.49338-12.05536,.39255-11.74512,6.59735l.24512,4.90265,9,42,3,44"
              class="fill-gray-300"
            />
          </g>
          <g>
            <path
              d="M190.44666,110.38788v106c0,9.37402-7.62598,17-17,17H67.44666c-9.37402,0-17-7.62598-17-17V110.38788c0-9.37402,7.62598-17,17-17h106c9.37402,0,17,7.62598,17,17Zm-23.57587,121c11.91602,0,21.57587-9.65985,21.57587-21.57584v-46.86511c0-37.31183-30.24719-67.55905-67.55902-67.55905h-53.44095c-8.28427,0-15,6.71573-15,15v91.2106c0,16.45221,13.33716,29.78937,29.78937,29.78937h84.6348v.00003h-.00006Z"
              class="fill-gray-800"
            />
            <path
              d="M134.55713,136.68008h-34.22061c-.86273,0-1.5647-.702-1.5647-1.56473s.702-1.56439,1.5647-1.56439h34.22061c.86273,0,1.56439,.70166,1.56439,1.56439s-.70166,1.5647-1.56439,1.5647v.00003Z"
              class="fill-primary-400"
            />
            <path
              d="M134.55713,170.04373h-34.22061c-.86273,0-1.5647-.702-1.5647-1.56473s.702-1.56439,1.5647-1.56439h34.22061c.86273,0,1.56439,.70166,1.56439,1.56439s-.70166,1.5647-1.56439,1.5647v.00003Z"
              class="fill-primary-400"
            />
            <path
              d="M117.55713,216.04373h-34.22061c-.86273,0-1.5647-.702-1.5647-1.56473s.702-1.56439,1.5647-1.56439h34.22061c.86273,0,1.56439,.70166,1.56439,1.56439s-.70166,1.5647-1.56439,1.5647v.00003Z"
              class="fill-primary-400"
            />
            <path
              d="M152.92029,153.37283H81.97339c-.86273,0-1.5647-.702-1.5647-1.56473s.702-1.56439,1.5647-1.56439h70.9469c.86273,0,1.56439,.70166,1.56439,1.56439s-.70166,1.5647-1.56439,1.5647v.00003Z"
              class="fill-primary-400"
            />
          </g>
          <path
            d="M159.30957,338.49185C71.46582,338.49185,0,267.02603,0,179.18276S71.46582,19.87417,159.30957,19.87417s159.30859,71.46533,159.30859,159.30859-71.46582,159.30908-159.30859,159.30908Zm-.00001-316.61768C64.56828,223.29978,1.32341,109.477,1.99999,179.18278c.84189,86.73663,70.56837,157.30907,157.30958,157.30907s157.30859-70.56836,157.30859-157.30908S246.04982,21.87417,159.30956,21.87417Z"
            class="fill-gray-800"
          />
        </svg> -->
      </div>
    </div>

    <button
      (click)="drawerOpen = !drawerOpen"
      type="button"
      [class.top-6]="drawerOpen"
      [class.top-1]="!drawerOpen"
      [class.right-6]="drawerOpen"
      [class.right-2]="!drawerOpen"
      [class.text-base-dark]="drawerOpen"
      [class.text-base]="!drawerOpen"
      class="lg:hidden absolute hover:bg-primary-200 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm p-1 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
    >
      <svg
        *ngIf="drawerOpen; else notOpen"
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>

      <ng-template #notOpen>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </ng-template>
    </button>
  </div>

  <div cdkScrollable class="flex-grow px-4 py-3 lg:px-14 lg:py-10 bg-gray-50 overflow-auto scrollbar-thin">
    <router-outlet></router-outlet>
  </div>

  <div class="h-1 bg-gradient-to-r from-primary-400 to-primary-600"></div>
</div>
