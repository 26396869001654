<ng-container *ngIf="claim$ | async as claim">
  <vs-app-page
    *vsLet="isMobile$ | async as isMobile"
    class="!p-0"
    [config]="{
      icon: 'file-06',
      title: 'Claim #'+ claim.claimNumber,
      description: 'Opened: ' + (claim.created | date) + ' | Last updated: ' + (claim.modified | amTimeAgo: true) + ' ago'
    }"
  >
    <span class="inline-flex items-center justify-center h-full min-w-fit" slot="title-end">
      <vs-badge [theme]="$any(status[claim.status])">
        <vs-icon name="circle" type="solid" size="8"></vs-icon>
        {{ claim.status.replace('CANCELLED', 'CANCELED') | titlecase }}
      </vs-badge>
    </span>

    <vs-breadcrumbs slot="breadcrumbs">
      <vs-breadcrumbs-item [config]="{ label: 'Claims', routerLink: '..' }"></vs-breadcrumbs-item>
      <vs-breadcrumbs-item [config]="{ label: 'Claim #' + claim.claimNumber }"></vs-breadcrumbs-item>
    </vs-breadcrumbs>

    <vs-input-group slot="header-end" theme="danger">
      <button
        *ngIf="claim.status==='OPEN'"
        vs-button
        appearance="outline"
        class="flex-grow lg:flex-grow-0"
        (click)="openCancelDialog()"
      >
        <vs-icon name="file-x-02"></vs-icon> Cancel Claim
      </button>
    </vs-input-group>

    <vs-dialog #cancelClaimDialog [config]="{ dialogContainerClass: 'max-w-[600px]'}" (closed)="refreshClaim()">
      <vs-dialog-header
        [config]="{
          mode: 'simple',

          icon: 'file-x-02',
          iconTheme: 'warn',

          title: 'Cancel Claim',
          subtitle: 'Are you sure you want to cancel <strong>Claim #' + claim.claimNumber + '</strong>? This action cannot be undone.'
        }"
      ></vs-dialog-header>

      <div class="flex gap-2">
        <button vs-button appearance="outline" class="flex-1" (click)="cancelClaimDialog.close()">Back</button>
        <button vs-button theme="danger" class="flex-1" (click)="cancel()">Cancel Claim</button>
      </div>
    </vs-dialog>

    <div class="flex flex-col gap-4">
      <ws-claim-lifecycle
        *ngIf="claim.claimLifecycleSteps?.length"
        [claim]="claim"
        [isCustomerPortal]="true"
      ></ws-claim-lifecycle>

      <ws-claim-coverage-card-carousel class="w-full" [claim]="claim"></ws-claim-coverage-card-carousel>

      <ws-claim-attachment-carousel class="w-full" [claim]="claim"></ws-claim-attachment-carousel>

      <ws-claim-reimbursement-carousel class="w-full" [claim]="claim"></ws-claim-reimbursement-carousel>

      <ws-claim-notes-viewer [claim]="claim"></ws-claim-notes-viewer>
    </div>
  </vs-app-page>
</ng-container>

<router-outlet></router-outlet>
