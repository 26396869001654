<ng-container *ngIf="warrantyStatus === 'CANCELLED'; else showBtn">
  <div class="vs-badge items-center" appearance="outline" color="danger" size="large" type="button">
    <span>Canceled</span>
  </div>
</ng-container>

<ng-template #showBtn>
  <button
    #button
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="isOpen ? close() : open()"
    class="vs-badge flex flex-row gap-1.5 items-center clickable"
    appearance="outline"
    [attr.color]="warrantyStatus !== 'ACTIVATED' ? 'grey' : 'success'"
    size="large"
    type="button"
  >
    <span #span style="pointer-events: none">{{ warrantyStatus | titlecase }}</span>

    <vs-icon
      name="chevron-down"
      class="text-themed-500 transition-all"
      [class.rotate-180]="isOpen"
      [theme]="warrantyStatus === 'ACTIVATED' ? 'success' : 'default'"
    ></vs-icon>
  </button>

  <div *vsLet="propertyValues$ | async as propertyValues" #container>
    <ng-template
      #overlay="cdkConnectedOverlay"
      cdkConnectedOverlay
      [cdkConnectedOverlayOffsetX]="-container.offsetWidth"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayOrigin]="trigger"
      (overlayOutsideClick)="$event.target !== button ? close() : ''"
    >
      <div class="mt-2 z-10 max-w-[320px] bg-base rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
          <li>
            <div class="block py-2 px-4 w-fit" *ngIf="warrantyStatus !== 'CANCELLED'">
              <!-- dropdown item header -->
              <div class="flex flex-row items-center mb-2">
                <vs-icon type="solid" name="clock"></vs-icon>

                <span class="ml-1.5">TERM</span>
              </div>

              <!-- badge -->
              <div class="vs-badge mb-2 w-fit" color="info">
                <p *vsLet="daysToExpiry$ | async as daysToExpiry">
                  {{
                    warrantyStatus === 'EXPIRED'
                      ? 'Expired ' + (expiryDate | date)
                      : daysToExpiry
                      ? 'Expires in ' + daysToExpiry + ' day' + (daysToExpiry > 1 ? 's' : '')
                      : 'Expires today'
                  }}
                </p>
              </div>

              <!-- dropdown item body -->
              <div class="pl-1">
                <p>
                  {{ warrantyValidity }}
                </p>
              </div>
            </div>
          </li>
        </ul>

        <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
          <li>
            <div class="block py-2 px-4 w-fit">
              <!-- dropdown item header -->
              <div class="flex flex-row items-center mb-2">
                <vs-icon type="solid" name="marker-pin-01"></vs-icon>

                <span class="ml-1.5 truncate max-w-[256px]" [vsTooltip]="asset?.name" tooltipStyle="light">
                  {{ asset?.name }}
                </span>
              </div>

              <!-- badge -->
              <div *ngIf="propertySetId === 'home_inspection'" class="vs-badge mb-2 w-fit" color="info">
                <p>Closed {{ transactionCloseDate | date }}</p>
              </div>

              <!-- Address block -->
              <div class="max-w-[256px]" *ngIf="asset && propertyValues">
                <vs-property-set-values [propertyValues]="propertyValues" [propertySetId]="asset.propertySetId">
                </vs-property-set-values>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </ng-template>
  </div>
</ng-template>
