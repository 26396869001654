import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { MenuTriggerForDirective } from '@vsolv/vectors-ui/menu';
import { Warranty } from '@wsphere/warranties/domain';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { CustomersCustomerPortalService, WarrantyCustomerPortalService } from '../../../services';

@Component({
  selector: 'ws-activate-warranty-page',
  templateUrl: './activate-warranty.page.html',
})
export class ActivateWarrantyPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private warrantySvc: WarrantyCustomerPortalService,
    private customerSvc: CustomersCustomerPortalService,
    private toastSvc: ToastService
  ) {}

  contractId = this.route.snapshot.paramMap.get('contractNumber');

  warranty$ = this.warrantySvc.getOneByContractNumber(this.contractId ?? '');

  activateWarrantyFormGroup = this.formBuilder.group({
    evaluateCustomerName: [null as boolean | null, Validators.required],
    customerName: [null as string | null, Validators.required],
    evaluateCustomerEmail: [null as boolean | null, Validators.required],
    customerEmail: [null as string | null, [Validators.required, Validators.email]],
    evaluateCustomerPhoneNumber: [null as boolean | null, Validators.required],
    customerPhoneNumber: [null as string | null, Validators.required],
    closingDate: [null as string | null, Validators.required],
  });

  step = 0;

  lastStep = 5;

  validNumber = false;

  async nextPage(missingStep?: boolean, warranty?: Warranty.Model) {
    if (this.step >= this.lastStep) return;

    if (this.step === 2.5) {
      //email form field
      const newEmail = this.activateWarrantyFormGroup.controls.customerEmail.value;
      if (!newEmail) {
        this.toastSvc.show({
          type: 'error',
          title: 'Email is required',
          text: 'Please enter your email and try again.',
        });
        return;
      }
      if (newEmail !== warranty?.customer?.email) {
        const conflict = await this.customerSvc.checkIfCustomerExists(newEmail);
        if (conflict) {
          this.toastSvc.show({
            type: 'error',
            title: 'Sorry, this email is already in use',
            text: 'Please update your email and try again.',
          });
          return;
        }
      }
    }

    if (missingStep) this.step += 0.5;
    else if (this.step === 3 && warranty?.policy?.propertySetId !== 'home_inspection') {
      //skip home inspection closing date step
      this.step = 5;
    } else this.step++;

    if (this.step === this.lastStep && warranty) this.activateWarranty(warranty);

    if (this.step > this.lastStep) this.step = this.lastStep;
  }

  async previousPage(missingStep?: boolean) {
    if (this.step <= 0) {
      this.clear();
      return;
    }

    if (missingStep) this.step -= 0.5;
    else this.step--;

    if (this.step < 0) this.step = 0;
  }

  clear() {
    this.step = 0;
  }

  populateDate(event: Date, menu: MenuTriggerForDirective) {
    this.activateWarrantyFormGroup.patchValue({
      closingDate: event.toISOString().split('T')[0],
    });
    menu.close();
  }

  validateNumber() {
    if (!this.activateWarrantyFormGroup.controls.customerPhoneNumber.value) {
      this.validNumber = true;
      return;
    }
    try {
      const phone = new AsYouType('US').input(this.activateWarrantyFormGroup.controls.customerPhoneNumber.value);
      this.validNumber = isValidPhoneNumber(phone, 'US');
      return;
    } catch (e) {
      console.log(e);
    }
    this.validNumber = false;
  }

  phoneNumberKeyUp(phone: KeyboardEvent) {
    if (phone.key === 'Backspace') return;
    this.formatPhoneNumber();
  }

  formatPhoneNumber() {
    const phoneNumber = this.activateWarrantyFormGroup.controls.customerPhoneNumber.value;
    if (phoneNumber) {
      this.activateWarrantyFormGroup.patchValue({ customerPhoneNumber: new AsYouType('US').input(phoneNumber) });
    }
  }

  activateWarranty(warranty: Warranty.Model) {
    const body: Warranty.Activate = this.getBody(warranty);
    this.warrantySvc.activateWarranty(warranty.id, body).then(response => {
      if (!response) {
        this.router.navigate(['../../'], { relativeTo: this.route });
      }
      if (response && body.emailAddress !== warranty.customer?.email) {
        return setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      if (response) {
        setTimeout(() => {
          this.router.navigate(['../'], { relativeTo: this.route });
        }, 5000);
      }
      return null;
    });
  }

  getBody(warranty: Warranty.Model): Warranty.Activate {
    const isHomeInspection = warranty.policy?.propertySetId === 'home_inspection';

    const customerName =
      !this.activateWarrantyFormGroup.controls.evaluateCustomerName.value &&
      this.activateWarrantyFormGroup.controls.customerName.value !== warranty.customer?.name
        ? this.activateWarrantyFormGroup.controls.customerName.value
        : warranty.customer?.name;

    const emailAddress =
      !this.activateWarrantyFormGroup.controls.evaluateCustomerEmail.value &&
      this.activateWarrantyFormGroup.controls.customerEmail.value !== warranty.customer?.email
        ? this.activateWarrantyFormGroup.controls.customerEmail.value
        : warranty.customer?.email;

    const customerPhoneNumber =
      !this.activateWarrantyFormGroup.controls.evaluateCustomerPhoneNumber.value &&
      this.activateWarrantyFormGroup.controls.customerPhoneNumber.value !== warranty.customer?.phone
        ? this.activateWarrantyFormGroup.controls.customerPhoneNumber.value
        : warranty.customer?.phone;

    const body: Warranty.Activate = {
      customerName: customerName ?? '',
      customerPhoneNumber: customerPhoneNumber ?? '',
      emailAddress: emailAddress ?? '',
      transactionCloseDate: isHomeInspection
        ? new Date(this.activateWarrantyFormGroup.controls.closingDate.value ?? '')
        : undefined,
    };

    return body;
  }
}
