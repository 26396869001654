import { Component } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';

@Component({ templateUrl: './terms-of-service.page.html' })
export class TermsOfServicePage {
  constructor(private portalSvc: PortalService) {}

  contactEmail = this.portalSvc.config.contactEmail;
  contactPhone = this.portalSvc.config.contactPhone;
}
